import React, { useContext } from "react";
import "./Brandss.css";
import { Context } from "../../../utils/context";

function Brandss({ brand }) {

  const { IMG_URL} = useContext(Context);
  return (
    <>
      <section className="brandss_section">
        <div className="brandmiandiv">
          <div className="brandimgmain">
            <img className="brandimg" src={IMG_URL+ brand?.image} />
          </div>
          <p className="brandname text mb-0">{brand?.name}</p>
        </div>
      </section>
    </>
  );
}

export default Brandss;
