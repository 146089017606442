import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./FeaturedCategories.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { CategoriesCards } from "../../../utils/apis/master/Master";

const FeaturedCategories = () => {
  //   const Featured = [
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f1.png",
  //       title: "Women Clothing & Fashion",
  //       p1: "Hot Categories",
  //       p2: "Wedding & events",
  //       p3: "Bottom",
  //       p4: "Tops & sets",
  //       p5: "Accessories",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f2.png",
  //       title: "Women Clothing & Fashion",
  //       p1: "Hot Categories",
  //       p2: "Wedding & events",
  //       p3: "Bottom",
  //       p4: "Tops & sets",
  //       p5: "Accessories",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f3.png",
  //       title: "Women Clothing & Fashion",
  //       p1: "Hot Categories",
  //       p2: "Wedding & events",
  //       p3: "Bottom",
  //       p4: "Tops & sets",
  //       p5: "Accessories",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f4.png",
  //       title: "Women Clothing & Fashion",
  //       p1: "Hot Categories",
  //       p2: "Wedding & events",
  //       p3: "Bottom",
  //       p4: "Tops & sets",
  //       p5: "Accessories",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f5.png",
  //       title: "Women Clothing & Fashion",
  //       p1: "Hot Categories",
  //       p2: "Wedding & events",
  //       p3: "Bottom",
  //       p4: "Tops & sets",
  //       p5: "Accessories",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f6.png",
  //       title: "Women Clothing & Fashion",
  //       p1: "Hot Categories",
  //       p2: "Wedding & events",
  //       p3: "Bottom",
  //       p4: "Tops & sets",
  //       p5: "Accessories",
  //     },
  //   ];

  const { IMG_URL, sellerId } = useContext(Context);

  const [categoriesCards, setCategoriesCards] = useState([]);
  const [categoryOne, setCategoriesOne] = useState();
  const getCategoriesCards = async () => {
    const res = await CategoriesCards(sellerId);
    if (res?.success) {
      setCategoriesCards(res?.data);
    }
  };

  useEffect(() => {
    getCategoriesCards();
  }, []);
  return (
    <>
      <section className="FeaturedCategories">
        <Container>
          <div className="main">
            <h1 className="title">Featured Categories</h1>
            <Link to="/ecommerce2/all-categories">
              <p className="text">View All Categories</p>
            </Link>
          </div>

          <div className="categories">
            <Row>
              {categoriesCards?.map((item, index) => (
                <Col xxl={4} xl={4} lg={4} md={6} key={index} className="p-0">
                  <div className="categories-box">
                    <div className="categories-main">
                      <div className="image-categories">
                        <img src={IMG_URL + item?.image} className="feature" />
                      </div>
                    </div>

                    <div className="content">
                      <h1 className="text">{item?.name}</h1>
                      <p>{item?.s_category?.name}</p>
                      {/* {item?.s_category?.s_sub_categories.map((data, index) => (
                        <>
                          {data?.child_category_of_sellers
                            ?.slice(0, 5)
                            .map((item, index) => (
                              <Link
                                key={item.child_category_id}
                                to={`/ecommerce2/categoryfilter/${item.child_category_id}`}
                              >
                                <p className="sub-text">{item.name}</p>
                              </Link>
                            ))}
                        </>
                      ))} */}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FeaturedCategories;
