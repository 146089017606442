import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getFaqs = async () => {
  try {
    return await getData(`/without-login/all-faq-categories`);
  } catch (error) {
    console.log(error);
  }
};
export const Banners = async () => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.log(error);
  }
};

export const Brands = async (id, type) => {
  try {
    return await getData(`/without-login/masters/all-s-brand?seller_id=${id}&type=${type}`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/without-login/home/get-country`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/without-login/home/get-state/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/home/get-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (name) => {
  try {
    return await getData(`/without-login/masters/allpincode/${name}`);
  } catch (error) {
    console.log(error);
  }
};

export const allPincode = async () => {
  try {
    return await getData(`/without-login/masters/allpincode`);
  } catch (error) {
    console.log(error);
  }
};

export const pincodeWiseAll = async (id) => {
  try {
    return await getData(`/without-login/masters/all-pincode-details/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const SellerGet = async () => {
  try {
    return await getData(`/seller/show`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllWebsiteFaq = async (seller_id) => {
  try {
    return await getData(`/outer-website/faq/faq?seller_id=${seller_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllExclusiveCollection = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-exclusive-collection?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCrazyDeals = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-crazy-deals?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopTrends = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-shop-trends?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopByCategory = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-shop-by-category?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopByCategoryNew = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/all-shop-by-category-new?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllNewArrivals = async (s_supplyer_id) => {
  try {
    return await getData(
      `/outer-website/home/all-new-arrivals?s_supplyer_id=${s_supplyer_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllHomeBanner = async (seller_id) => {
  try {
    return await getData(`/outer-website/home/all-home-banner?seller_id=${seller_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllWishList = async (id) => {
  try {
    return await postData(`/outer-website/wishlist/add`, { product_id: id });
  } catch (error) {
    console.log(error);
  }
};

export const GetWebHeader = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/web-header?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAppSetup = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/app-setup?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};


export const GetSocialLink = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/home/social-link?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
}


export const GetTodayDeal = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/today-deal?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
}

export const GetHomeFlashSale = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/flash-sale/home/product?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
}


export const GetSingleFlashSale = async (seller_id, flashSaleId) => {
  try {
    return await getData(
      `/outer-website/flash-sale/${flashSaleId}?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
}


export const GetAllFlashSale = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/flash-sale/?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
}


export const GetBestSelling = async (seller_id) => {
  try {
    return await getData(
      `/outer-website/best-selling?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
}

export const allourstory = async (seller_id) => {
  try {
    return await getData(`/outer-website/about-us/banner?seller_id=${seller_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const GetSelling = async (seller_id) => {
  try {
    return await getData(`/outer-website/best-selling?seller_id=${seller_id}`);
  } catch (error) {
    console.log(error);
  }
};


export const CategoriesCards = async (id) => {
  try {
    return await getData(`/outer-website/home/category?seller_id=${id}`);
  } catch (error) {
    console.log(error);
  }
};


export const CouponsProduct = async (id) => {
  try {
    return await getData(`/outer-website/coupons-code?seller_id=${id}`);
  } catch (error) {
    console.log(error);
  }
};


export const GetTermsCondition = async (id) => {
  try {
    return await getData(`/outer-website/policy/term-condition?seller_id=${id}&type=outer`);
  } catch (error) {
    console.log(error);
  }
};


export const GetReturnPolicy = async (id) => {
  try {
    return await getData(`/outer-website/policy/return?seller_id=${id}&type=outer`);
  } catch (error) {
    console.log(error);
  }
};


export const GetPrivacyPolicy = async (id) => {
  try {
    return await getData(`/outer-website/policy/privacy?seller_id=${id}&type=outer`);
  } catch (error) {
    console.log(error);
  }
};
// export const CrazyDeals = async (seller_id) => {
//   try {
//       return await getData(`outer-website/home/all-crazy-deals?seller_id=${seller_id}`);
//   } catch (error) {
//       console.log(error);
//   }
// };


