import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postAddress = async (data) => {
  try {
    return await postData(`/outer-website/order-process/add`, data);
  } catch (error) {
    console.log(error);
  }
};

export const EditAddress = async (data, id) => {
  try {
    return await postData(`/outer-website/order-process/add/${id}`, data);
  } catch (error) {
    console.log(error);
  }
};

export const DeleteAddress = async (id) => {
  try {
    return await postData(`/outer-website/order-process/add-delete/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getAddress = async () => {
  try {
    return await getData(`/outer-website/order-process/show`);
  } catch (error) {
    console.log(error);
  }
};

export const Checkout = async (data) => {
  try {
    return await postData(`/outer-website/order-process/order`, data);
  } catch (error) {
    console.log(error);
  }
};

export const OrderCancel = async (data) => {
  try {
    return await postData(`/outer-website/order-process/order-cancel`, data);
  } catch (error) {
    console.log(error);
  }
};

export const Notifications = async (sellerId) => {
  try {
    return await getData(`/outer-website/notifications?seller_id=${sellerId}`);
  } catch (error) {
    console.log(error);
  }
};