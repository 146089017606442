import React from 'react'
import './UsedProducts.css'
import { Col, Container, Row } from 'react-bootstrap'
function UsedProducts() {
  const used=[
    {
      image: process.env.PUBLIC_URL + "/assests/images/Home/Used/camera.webp",
      name:"Canon EOS 1500D/Rebel T7 DSLR Camera with EF-S 18-55 mm f/3.5-",
      price:"$52.000",
      status:"used",
      mensname:"Paul K. Jensen",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/images/Home/Used/car.webp",
      name:"Canon EOS 1500D/Rebel T7 DSLR Camera with EF-S 18-55 mm f/3.5-",
      price:"$52.000",
      status:"New",
      mensname:"Paul K. Jensen",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/images/Home/Used/tv.webp",
      name:"Canon EOS 1500D/Rebel T7 DSLR Camera with EF-S 18-55 mm f/3.5-",
      price:"$52.000",
      status:"New",
      mensname:"Paul K. Jensen",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/images/Home/Used/mob.webp",
      name:"Canon EOS 1500D/Rebel T7 DSLR Camera with EF-S 18-55 mm f/3.5-",
      price:"$52.000",
      status:"used",
      mensname:"Paul K. Jensen",
    },
  ]
  return (
    <>
    <section className='used_section'>
        <Container>
       <div className='used_main'>
        <Row className='p-2'>
        {used.map((item, index) => (
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className='p-0'>
          <div className='usedcardd'>
            <Row>
              <Col xxl={5} xl={5} lg={5} md={5} sm={12}>
                <div className='usedimg_div'>
                  <img src={item.image} className='usedimgg'/>
                </div>
              </Col>
              <Col xxl={7} xl={7} lg={7} md={7} sm={12}>
              <div className='cardtxts_div'>
                <p className='txts1 text'>{item.name}</p>
                <p className='txts2 text'>{item.mensname}</p>
                <p className='txts3 text'>{item.price}</p>
                <div className={`usetextidv ${item.status === 'New' ? 'new' : 'used'}`}>
                  <p className='txts4 text mb-0'>{item.status}</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
          </Col>
              ))}
        </Row>
        
       </div>
        </Container>
    </section>
    </>
  )
}

export default UsedProducts