import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./GreatDeals.css";
import {faArrowsTurnRight, faCircleInfo, faFile, faHeadset} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
const GreatDeals = () => {
    return (
        <>
            <section className="GreatDeals">
                <Container>
                    <h1 className="midtext">Active eCommerce CMS | AN ONLINE SHOPPING PLATFORM WITH GREAT DEALS</h1>
                    <p className="sub-text">
                        Active eCommerce CMS Bangladesh is a leading online shopping site based in Bangladesh that
                        brings you great deals, with platforms existing across Asia including Singapore, Thailand,
                        Indonesia, Vietnam, Philippines, and Taiwan. We offer unbeatable deals featuring an endless
                        range of products priced at affordable rates. What is even better? We enhance your experience
                        with the eCommerce Guarantee, which means your payment is held in our system and only released
                        to the seller after you have received your purchase in good condition! All because we value you
                        as our customer. What are you waiting for? Come aboard and start browsing!
                    </p>
                    <p className="sub-text">
                        Active eCommerce CMS -- QUALITY PRODUCTS, LOW PRICES Active eCommerce CMS strives to provide you
                        with a great online shopping experience and we know a great experience often includes affordable
                        prices. That is why you should check out Active eCommerce CMS Bangladesh site/app’s daily online
                        Shocking Sale, where we offer you different products at a discounted price, ensuring you get a
                        bang for your buck. We even offer free shipping, Flat rate shipping, carrier-wise shipping on
                        products! For brand-loyal customers, head over to Active eCommerce CMS Mall for authentic
                        products at superb prices.
                    </p>
                    <p className="sub-text">
                        Want to look your best without burning a hole in your wallet? Check out Style by Active
                        eCommerce CMS for our top picks in fashion. Want to get the best prices for day-to-day items?
                        Browse through our Lowest Price Guaranteed page. If you find the same Lowest Price Guaranteed
                        item being sold at a lower price by a competitor, we will reward you with 120% of the price
                        difference in Active eCommerce CMS Coins! You can also find official online stores for some of
                        Bangladeshis biggest brands here, such as Nestle, Unilever, Mydin, Tefal, Calvin Klein, and more
                        right here on our platform.
                    </p>
                    <p className="sub-text">
                        Store FOR VARIETY WITH Active eCommerce CMS Everyone loves online shopping for convenience and
                        the low prices are a bonus! Active eCommerce CMS strives to help you get a bang for your buck
                        with multiple sales and promotions happening at any one time. Make sure your wallet is ready for
                        our big sales campaigns, such as 7.7 Orange Madness, 9.9 Super Shopping Day, 10.10 Brands
                        Festival, 11.11 Big Sale, and 12.12 Birthday Sale. We also have massive sales and offer great
                        savings to you during major festivals like Chinese New Year and Raya! At the same time, check in
                        often because we also have smaller sale periods that are category-specific, such as our Baby
                        Fair and Black Friday sales.
                    </p>
                    <p className="sub-text">
                        Let’s not forget about the frequently occurring Super Brand Day! These days, specific brands
                        offer amazing deals for their top-selling items, so be sure to watch if your favorite brand is
                        featured. Of course, there are always different kinds of smaller promotions happening every day,
                        so you really will benefit if you constantly check your website to ensure you are not missing
                        any mind-blowing offers!
                    </p>
                </Container>
                <div className="privacy-footer  mt-auto">
                    <Container className="px-xs-0">
                        <Row className="main-two  no-gutters border-left border-soft-light ">
                            {/* <Col xxl={2} className="main"></Col> */}
                            <Col xxl={3} xl={3} lg={3} md={3} xs={6} sm={6} className="p-0">
                            <Link to="ecommerce2/terms&conditions">
                                <div className="icon-main">
                                    <FontAwesomeIcon icon={faFile} className="icon" />
                                    <p className="sub-text">Terms & conditions</p>
                                </div>
                                </Link>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={3} xs={6} sm={6} className="p-0">
                                <Link to="ecommerce2/return-policy">
                                    <div className="icon-main">
                                        <FontAwesomeIcon icon={faArrowsTurnRight} className="icon" />
                                        <p className="sub-text">Return Policy</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={3} xs={6} sm={6} className="p-0">
                                <Link to="ecommerce2/support-policy">
                                    <div className="icon-main">
                                        <FontAwesomeIcon icon={faHeadset} className="icon" />
                                        <p className="sub-text">Support Policy</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={3} xs={6} sm={6} className="p-0">
                                <Link to="ecommerce2/privacy-policy">
                                    <div className="icon-main">
                                        <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                                        <p className="sub-text">Privacy Policy</p>
                                    </div>
                                </Link>
                            </Col>
                            {/* <Col xxl={2} className="main"></Col> */}
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default GreatDeals;
