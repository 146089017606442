import React, { useEffect, useRef, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "./ProductSlider.css";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
//import { Rating } from "react-simple-star-rating";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'js-cookie';
import {
  faCodeCompare,
  faHeart,
  faLock,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import IncreamentDecreament from "../../CommonComponent/IncreamentDecreament/IncreamentDecreament";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Addtocartmodal from "../../CommonComponent/PopUp/Addtocartmodal/Addtocartmodal";
import Cartsuccessfully from "../../CommonComponent/PopUp/Cartsuccessfully/Cartsuccessfully";
import { Context } from "../../../utils/context";
import {
  AddToCart,
  RemoveToCart,
  getCartCheck,
} from "../../../utils/apis/addToCart/addToCart";
import {
  getProductDetail,
  getReview,
  postReview,
  productReviewOnlyOnce,
} from "../../../utils/apis/Product/Product";
import Heart_Component from "../../CommonComponent/Heart_Component/Heart_Component";
import { Rating } from "react-simple-star-rating";
const Default = {
  initialZoom: 3,
  minZoom: 1.25,
  maxZoom: 4,
  zoomSpeed: 0.01,
};

class Zoomable {
  constructor(element, config) {
    this.element = element;
    this.config = this._mergeConfig(config);

    const { initialZoom, minZoom, maxZoom } = this.config;

    this.zoomed = false;
    this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
    this.zoom = this.initialZoom;

    this.img = element.querySelector(".zoomable__img");
    this.img.draggable = false;
    this.element.style.setProperty("--zoom", this.initialZoom);

    this._addEventListeners();
  }

  static get Default() {
    return Default;
  }

  _addEventListeners() {
    this.element.addEventListener("mouseenter", () => this._handleMouseover());
    this.element.addEventListener("mousemove", (evt) =>
      this._handleMousemove(evt)
    );
    this.element.addEventListener("mouseleave", () => this._handleMouseout());
    this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
  }

  _handleMouseover() {
    if (this.zoomed) {
      return;
    }

    this.element.classList.add("zoomable--zoomed");
    this.zoomed = true;
  }

  _handleMousemove(evt) {
    if (!this.zoomed) {
      return;
    }

    const elPos = this.element.getBoundingClientRect();
    const percentageX = `${((evt.clientX - elPos.left) * 100) / elPos.width}%`;
    const percentageY = `${((evt.clientY - elPos.top) * 100) / elPos.height}%`;

    this.element.style.setProperty("--zoom-pos-x", percentageX);
    this.element.style.setProperty("--zoom-pos-y", percentageY);
  }

  _handleMouseout() {
    if (!this.zoomed) {
      return;
    }

    this.element.style.setProperty("--zoom", this.initialZoom);
    this.element.classList.remove("zoomable--zoomed");
    this.zoomed = false;
  }

  _handleWheel(evt) {
    if (!this.zoomed) {
      return;
    }

    evt.preventDefault();

    const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
    const { minZoom, maxZoom } = this.config;

    this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
    this.element.style.setProperty("--zoom", this.zoom);
  }

  _mergeConfig(config) {
    return {
      ...this.constructor.Default,
      ...(typeof config === "object" ? config : {}),
    };
  }
}

const ProductSlider = ({ reviewdetail, IMG_URL, sellerId, id, average }) => {
  console.log(id, "reviewdetails");
  // const [modalShowone, setModalShowone] = React.useState(false);

  const [cartsuccess, setCartsuccess] = useState(false);

  const colorsd = ["#000", "#33FF57", "#3357FF", "#F333FF"];

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };



  const zoomableRef = useRef(null);

  useEffect(() => {
    const element = zoomableRef.current;
    if (!element) return;

    const zoomable = new Zoomable(element);

    return () => {
      // Clean up event listeners when component unmounts
      element.removeEventListener("mouseenter", zoomable._handleMouseover);
      element.removeEventListener("mousemove", zoomable._handleMousemove);
      element.removeEventListener("mouseleave", zoomable._handleMouseout);
      element.removeEventListener("wheel", zoomable._handleWheel);
    };
  }, []);

  const [number, setNumber] = useState(1);
  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const [showButtons, setShowButtons] = useState(false);
  const [show, setShow] = useState(false);

  const [heartactive, setheartActive] = useState(false);
  const handleClickes = ({ data }) => {
    setheartActive(!heartactive);
  };

  // Rating star
  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value: number, index: number) =>
    console.log(value, index);

  const [modalShow, setModalShow] = useState(false);

  //Start
  const [selectedImage, setSelectedImage] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [swiperInstancemobile, setSwiperInstancemobile] = useState(null);
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);
  const [showAddedToCart, setShowAddedToCart] = useState(false);

  const { signin, triggercart } = useContext(Context);
  const navigate = useNavigate();
  const handleAddToCart = async () => {
    const data = {
      product_id: id,
      seller_id: sellerId,
      s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
      s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
      s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
      name: getValues("product_title"),
      price: getValues("selling_price"),
      image: images?.[0]?.image

    };

    console.log(data, "dtatatatat");

    const res = await AddToCart(data);
    triggercart();
    setCartsuccess(true);
    getCartCheckProduct();

    setTimeout(() => {
      setCartsuccess(false);
    }, 3000);
  };

  const BuyNowClick = async () => {
    if (signin) {
      const data = {
        product_id: id,
        seller_id: sellerId,
        s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
        s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
        s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
      };

      console.log(data, "dataa");

      triggercart()

      if (!checkExits) {
        await AddToCart(data);
        setShowAddedToCart(true);
        getCartCheckProduct();
      }
      navigate("/ecommerce2/order-data");
    } else {
      navigate("/ecommerce2/login");
    }
  };

  const handleRemoveFromCart = async () => {
    const res = await RemoveToCart(checkExits);
    getCartCheckProduct();
    triggercart();
  };





  const [reviews, setReviews] = useState([]);



  const getReviews = async () => {
    try {
      const response = await getReview(id);
      if (response?.success) {
        setReviews(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [userreview, setUserReviews] = useState(false);
  const getUserReview = async () => {
    const res = await productReviewOnlyOnce(id, sellerId);

    setUserReviews(res?.data?.hasReviewed);
  };

  useEffect(() => {
    getReviews();
    getUserReview();
  }, []);

  // const breadcrumbItems = [
  //   { text: "Home", link: "/" },
  //   { text: "New Arrivals", link: `/our_products_page/${category} ` },
  //   { text: "Formal Shits", link: "", className: "active" },
  // ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState([]);

  const [singleVariant, setSingleVariant] = useState(false);
  const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
  const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

  const [data, setData] = useState({});
  const [checkExits, setCheckExits] = useState(false);

  const getProductData = async () => {
    {
      const res = await getProductDetail(id, sellerId);
      if (res?.data?.s_product_variant?.variant_type === "Single") {
        setData(res.data);
        setValue("product_title", res.data?.product_title);
        setValue(
          "customization",
          res.data?.s_product_customization?.s_customization?.name
        );
        setValue(
          "variant",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_attribute?.name
        );
        setValue(
          "unit",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_attribute?.defind
        );
        setValue(
          "variant_details",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details
        );

        await setValue(
          "activeVariant",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.id
        );

        setValue(
          "sku_id",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.sku_id
        );

        setValue(
          "s_p_v_a_d_id",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.id
        );

        setValue(
          "mrp",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]
            ?.s_p_v_a_d_price_a_stock?.mrp
        );
        setValue(
          "selling_price",
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]
            ?.s_p_v_a_d_price_a_stock?.selling_price
        );

        setSingleVariant(true);
        setImages(
          res.data?.s_product_variant?.s_product_variant_attribute
            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
        );
      } else if (res?.data?.s_product_variant?.variant_type === "Multiple") {
        if (
          res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]
            ?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
            ?.s_p_v_a_one_d_a_d_as.length == 0
        ) {
          setData(res.data);
          setValue("product_title", res.data?.product_title);
          setValue(
            "customization",
            res.data?.s_product_customization?.s_customization?.name
          );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          await setValue(
            "activeVariant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.id
          );

          await setValue(
            "activeSubVariant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.id
          );

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "s_p_v_a_one_d_a_d_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.id
          );
          setValue(
            "mrp",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );
          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d
              ?.selling_price
          );
          setMultipleVariantTwo(true);
          setImages(
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_a_d_images
          );
        } else {
          setData(res.data);
          setValue("product_title", res.data?.product_title);
          setValue(
            "customization",
            res.data?.s_product_customization?.s_customization?.name
          );
          setValue(
            "variant",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name
          );
          setValue(
            "variant_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds
          );

          setValue(
            "variant_one",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name
          );

          setValue(
            "variant_one_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds
          );

          setValue(
            "variant_two",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_attribute?.name
          );

          setValue(
            "variant_two_details",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_p_v_a_one_d_a_d_a_ds
          );

          setValue(
            "sku_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
          );
          setValue(
            "s_p_v_a_o_d_a_d_a_d_id",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
          );
          setValue(
            "mrp",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
          );

          setValue(
            "selling_price",
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d
              ?.selling_price
          );

          setImages(
            res.data?.s_product_variant?.s_p_v_a_ones?.[0]
              ?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
              ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]
              ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_a_d_images
          );
          setMultipleVariantThree(true);
        }
      }

    }
  };

  const getCartCheckProduct = async () => {
    const res = await getCartCheck(id);
    if (res?.success) {
      setCheckExits(res.data.id);
      console.log(res?.data.id, "lllll");
    } else {
      setCheckExits(res?.success);
    }
  };

  const onChangeVariant = async (data, type = "Single", level = "1") => {
    if (type === "Single" && level === "1") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
      setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
      setValue("s_p_v_a_d_id", data?.id);
    } else if (type === "Multiple" && level === "2") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
      setValue("s_p_v_a_one_d_a_d_id", data?.id);
    } else if (type === "Multiple" && level === "3") {
      setValue("sku_id", data?.sku_id);
      setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
      setValue(
        "selling_price",
        data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
      );
      setValue("s_p_v_a_o_d_a_d_a_d_id", data?.id);
    }
  };

  const swiperRef = useRef(null);

  const handleClickSlide = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    if (id) {
      getProductData(id);
      getCartCheckProduct();
    }
  }, [id]);

  const [shareModal, setShareModal] = useState(false);
  const shareHandle = async () => {
    setShareModal(true);

    setTimeout(() => {
      setShareModal(false);
    }, 2000);
  };
  const location = useLocation();

  return (
    <>
      <section className="ProductInner">
        <Container>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={12}>
              <div className="row camera-sec">
                <Zoom>
                  <div className="card-sec ">
                    <div className="product-detail text-center">
                      {selectedImage ? (
                        <div ref={zoomableRef}>
                          <img
                            src={selectedImage}
                            className="zoomable__img"
                            alt="Selected"
                          />
                        </div>
                      ) : (
                        <div className="specs" ref={zoomableRef}>
                          <img
                            src={IMG_URL + images?.[0]?.image}
                            className="zoomable__img"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Zoom>

                <div className="camera-slider p-3">
                  <div className="row p-0 m-0">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <Swiper
                        slidesPerView={"auto"}
                        modules={[Autoplay]}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                          0: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                          370: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                          },
                          485: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                          },
                          575: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },

                          768: {
                            slidesPerView: 6,
                            spaceBetween: 20,
                          },
                          992: {
                            slidesPerView: 6,
                            spaceBetween: 30,
                          },
                          1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },
                          1200: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },
                          1440: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                          },
                          2000: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                          },
                        }}
                        navigation
                        pagination={{ clickable: true }}
                        className="mySwiper"
                      >
                        {images?.map((slide, index) => (
                          <SwiperSlide key={index}>
                            <div className="Main-div">
                              <div
                                className="camera-imgs"
                                onClick={() =>
                                  handleClickSlide(IMG_URL + slide?.image)
                                }
                              >
                                <img
                                  src={IMG_URL + slide?.image}
                                  className="inner-img"
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={12}>
              <div className="content-main">
                <div className="upper-content">
                  <h1 className="midtext">
                    {getValues("product_title")}
                    <span className="subText">{getValues("sku_id")}</span>
                  </h1>
                  <div className="rating-main d-flex">
                    {/* {reviewdetail?.map((review, index) => (
                      <div className="specificReviews" key={index}>
                        <div className="ratingDatesec">
                          <Rating
                            initialValue={review.rating}
                            SVGclassName="star-svg"
                            fillColor="#1D44AD"
                            size={"25px"}
                            readonly={true}
                          />

                          <p>{new Date(review.createdAt).toLocaleDateString()}</p> 
                        </div>
                      </div>
                    ))} */}


                    <Rating
                      initialValue={average}
                      SVGclassName="star-svg"
                      fillColor="#ffc107"

                      size={"25px"}
                      readonly={true}
                    />
                    <p className="sub-text">{average.toFixed(2)}/5</p>
                  </div>
                  <Row>
                    <Col xxl={4} xl={6} lg={6} md={6} sm={8} xs={8}>
                      <div className="Main">
                        <div className="heart">
                          <div className="hearticn">
                            {/* <FontAwesomeIcon
                                                            icon={faHeart}
                                                            onClick={handleClickes}
                                                            className={heartactive ? "gobiheartss" : "gobiheart"}
                                                        /> */}

                            <Heart_Component productId={id} />
                          </div>
                          <p className="sub-text">Add to Wishlist</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="price-section">
                  <Row className="mt-2">
                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                      <p className="sub-text">Price</p>
                    </Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                      <p className="price">₹ {getValues("selling_price")}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    {singleVariant && (
                      <>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                          <p className="sub-text">{getValues("variant")}</p>
                        </Col>
                        <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                          <div className="d-flex">
                            {getValues("variant_details")?.map(
                              (item, index) => (
                                <div className="box">
                                  <div className="color">
                                    <Button
                                      variant="standard"
                                      className={
                                        getValues("activeVariant") === item?.id
                                          ? "active"
                                          : ""
                                      }
                                      key={item?.id ?? index}
                                      onClick={async () => {
                                        await setValue(
                                          "activeVariant",
                                          item?.id
                                        );
                                        await onChangeVariant(
                                          item,
                                          "Single",
                                          "1"
                                        );
                                        await setImages(item?.s_p_v_a_d_images);
                                      }}
                                    >
                                      {item?.s_sub_attribute?.name ?? "Unknown"}
                                      {getValues("unit")}
                                    </Button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </Col>
                      </>
                    )}

                    {MultipleVariantTwo && (
                      <>
                        <p className="sub-text product-id">Variations</p>
                        <p className="quantity">1. {getValues("variant")}</p>

                        <div className="standard-section">
                          {getValues("variant_details")?.map((item, index) => (
                            <Button
                              variant="standard"
                              className={
                                getValues("activeVariant") === item?.id
                                  ? "active"
                                  : ""
                              }
                              key={index}
                              onClick={async () => {
                                await setValue("activeVariant", item?.id);
                                await setValue(
                                  "activeSubVariant",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]?.id
                                );
                                await setValue(
                                  "variant_one",
                                  item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                    ?.name ?? ""
                                );

                                await setValue(
                                  "variant_one_details",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds ?? []
                                );

                                await setImages(
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          ))}
                        </div>

                        <p className="quantity">
                          2. {getValues("variant_one")}
                        </p>

                        <div className="standard-section">
                          {getValues("variant_one_details")?.map(
                            (item, index) => (
                              <Button
                                variant="standard"
                                className={
                                  getValues("activeSubVariant") === item?.id
                                    ? "active"
                                    : ""
                                }
                                key={item?.id ?? index}
                                onClick={async () => {
                                  await setValue("activeSubVariant", item?.id);
                                  onChangeVariant(item, "Multiple", "2");
                                  setImages(
                                    item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                  );
                                }}
                              >
                                {item?.s_sub_attribute?.name ?? "Unknown"}
                              </Button>
                            )
                          )}
                        </div>
                      </>
                    )}

                    {MultipleVariantThree && (
                      <>
                        <p className="product-id">Variations</p>
                        <p className="quantity">1. {getValues("variant")}</p>

                        <div className="standard-section">
                          {getValues("variant_details")?.map((item, index) => (
                            <Button
                              variant="standard"
                              className={
                                getValues("activeVariant") === item?.id
                                  ? "active"
                                  : ""
                              }
                              key={item?.id ?? index}
                              onClick={async () => {
                                await setValue("activeVariant", item?.id);

                                await setValue(
                                  "activeSubVariant",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]?.id
                                );

                                await setValue(
                                  "activeSubChildVariant",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                                );

                                await setValue(
                                  "variant_one",
                                  item?.s_p_v_a_one_d_as?.[0]?.s_attribute
                                    ?.name ?? ""
                                );

                                await setValue(
                                  "variant_one_details",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds ?? []
                                );

                                setValue(
                                  "variant_two",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute
                                    ?.name
                                );

                                setValue(
                                  "variant_two_details",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds
                                );

                                setValue(
                                  "sku_id",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                                );
                                setValue(
                                  "mrp",
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                );

                                setImages(
                                  item?.s_p_v_a_one_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_as?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                    ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                );
                              }}
                            >
                              {item?.s_sub_attribute?.name ?? "Unknown"}
                            </Button>
                          ))}
                        </div>

                        <p className="quantity">
                          2. {getValues("variant_one")}
                        </p>

                        <div className="standard-section">
                          {getValues("variant_one_details")?.map(
                            (item, index) => (
                              <Button
                                variant="standard"
                                className={
                                  getValues("activeSubVariant") === item?.id
                                    ? "active"
                                    : ""
                                }
                                key={item?.id ?? index}
                                onClick={async () => {
                                  await setValue("activeSubVariant", item?.id);
                                  await setValue(
                                    "activeSubChildVariant",
                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                      ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                                  );
                                  setValue(
                                    "variant_two",
                                    item?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute
                                      ?.name
                                  );

                                  setValue(
                                    "variant_two_details",
                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                      ?.s_p_v_a_one_d_a_d_a_ds
                                  );

                                  setValue(
                                    "sku_id",
                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                      ?.s_p_v_a_one_d_a_d_a_ds?.[0]?.sku_id
                                  );
                                  setValue(
                                    "mrp",
                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                      ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                      ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                  );

                                  setImages(
                                    item?.s_p_v_a_one_d_a_d_as?.[0]
                                      ?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                      ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                  );
                                }}
                              >
                                {item?.s_sub_attribute?.name ?? "Unknown"}
                              </Button>
                            )
                          )}
                        </div>

                        <p className="quantity">
                          3. {getValues("variant_two")}
                        </p>

                        <div className="standard-section">
                          {getValues("variant_two_details")?.map(
                            (item, index) => (
                              <Button
                                variant="standard"
                                className={
                                  getValues("activeSubChildVariant") ===
                                    item?.id
                                    ? "active"
                                    : ""
                                }
                                key={item?.id ?? index}
                                onClick={async () => {
                                  await setValue(
                                    "activeSubChildVariant",
                                    item?.id
                                  );
                                  onChangeVariant(item, "Multiple", "3");
                                  setImages(
                                    item?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                  );
                                }}
                              >
                                {item?.s_sub_attribute?.name ?? "Unknown"}
                              </Button>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </Row>

                  <Row className="mt-2">
                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                      <p className="sub-text">Total Price</p>
                    </Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                      <p className="midtext mb-4">
                        ₹ {getValues("selling_price")}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-">
                    <div className="buttonsectionmain">
                      {checkExits ? (
                        <Button
                          variant="primary"
                          className="addtocartbtn"
                          onClick={handleRemoveFromCart}
                        >
                          Remove Cart
                        </Button>
                      ) : (
                        <>
                          <Button
                            className="addtocartbtn"
                            onClick={() => handleAddToCart(id)}
                          >
                            Add to Cart
                          </Button>

                          <Button className="buynowbtn" onClick={BuyNowClick}>
                            <FontAwesomeIcon icon={faLock} /> Buy Now
                          </Button>
                        </>
                      )}
                    </div>
                  </Row>

                  <Row className="mt-2">
                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                      <p className="sub-text">Refund</p>
                    </Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                      <p className="sub-text">View Policy</p>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                      <p className="sub-text">Share</p>
                    </Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                      <div className="sharing">
                        <div className="whatsapp">
                          <FontAwesomeIcon icon={faWhatsapp} />
                        </div>
                        <div className="Facebook">
                          <FontAwesomeIcon icon={faFacebook} />
                        </div>
                        <div className="Twitter">
                          <FontAwesomeIcon icon={faTwitter} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>


            </Col>
          </Row>
        </Container>
        {/* <InqFormModal
          show={modalShowone}
          onHide={() => setModalShowone(false)}
        /> */}
      </section>
      <Cartsuccessfully
        show={cartsuccess}
        onHide={() => setCartsuccess(false)}
        productId={id}
      />
    </>
  );
};

export default ProductSlider;
