// import React, { useState, useContext, useEffect } from "react";
// import "./FlashSale.css";
// import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import "moment/locale/tr";
// import Clock from "react-live-clock";
// import "moment-timezone";
// import "react-moment";
// import { Col, Container, Row } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
// import "swiper/css";
// import "swiper/css/grid";
// import "swiper/css/pagination";

// // import required modules
// import { Grid, Pagination } from "swiper/modules";
// import { Link } from "react-router-dom";
// import { GetHomeFlashSale } from "../../../utils/apis/master/Master";
// import { Context } from "../../../utils/context";

// const minuteSeconds = 60;
// const hourSeconds = minuteSeconds * 60;
// const daySeconds = hourSeconds * 24;

// const FlashSale = () => {
//     const { sellerId, IMG_URL } = useContext(Context);
//     const timerProps = {
//         isPlaying: true,
//         size: 90,
//         strokeWidth: 6,
//     };

//     const renderTime = (time) => (
//         <div className="time-wrapper">
//             <div className="title time">{time}</div>
//         </div>
//     );

//     const getTimeSeconds = (time) => Math.ceil(time % minuteSeconds);
//     const getTimeMinutes = (time) => Math.floor((time % hourSeconds) / minuteSeconds);
//     const getTimeHours = (time) => Math.floor((time % daySeconds) / hourSeconds);
//     const getTimeDays = (time) => Math.floor(time / daySeconds);

//     const [flashhome, setFlashHome] = useState([]);
//     const [remainingTime, setRemainingTime] = useState(0);

//     const getFlashHome = async () => {
//         const res = await GetHomeFlashSale(sellerId);
//         if (res?.success) {
//             const endTime = new Date(res.data.flash_sale.end_time).getTime() / 1000;
//             const currentTime = Date.now() / 1000;
//             const timeLeft = endTime - currentTime;
//             setRemainingTime(timeLeft > 0 ? timeLeft : 0);
//             setFlashHome(res?.data);
//         } else {
//             setRemainingTime(0);
//         }
//     };

//     useEffect(() => {
//         getFlashHome();
//     }, []);

//     const [swiperInstance, setSwiperInstance] = useState(null);
//     const handleMainSliderClick = (direction) => {
//         if (swiperInstance) {
//             if (direction === "prev") {
//                 swiperInstance.slidePrev();
//             } else if (direction === "next") {
//                 swiperInstance.slideNext();
//             }
//         }
//     };
//     const daysDuration = Math.ceil(remainingTime / daySeconds) * daySeconds;
//     return (
//         <>
//             <section className="countdown">
//                 <Container>
//                     <div className="main-countdown">
//                         <div className="title-content">
//                             <h1 className="title">Flash</h1>
//                             <FontAwesomeIcon icon={faBoltLightning} className="icon" />
//                         </div>
//                         <div className="sale">
//                             {/* <Link to="/ecommerce2/flash-sale">
//                                 <p className="sub-text">View All Flash Sale</p>
//                             </Link> */}
//                             <div className="border-main"></div>
//                             <Link to={`/ecommerce2/product-sale/${flashhome?.flash_sale?.id}`}>
//                                 <p className="sub-text">View All Products from This Flash Sale</p>
//                             </Link>
//                         </div>
//                     </div>
//                     <Row>
//                         <Col xxl={4} xl={4} lg={4}>
//                             <div className="Sale-Banner">
//                                 <div className="Flash-sale">
//                                     <img
//                                         src={IMG_URL + flashhome.flash_sale?.image}
//                                         className="sale"
//                                     />
//                                 </div>
//                                 <div className="Sale-Timer">
//                                     <div className="all-times-bar">
//                                         {remainingTime > 0 ? (
//                                             <>
//                                                 <div className="timebox">
//                                                     <div className="day main-day mr-12">
//                                                         <CountdownCircleTimer
//                                                             {...timerProps}
//                                                             colors={[["#d42d2a"], ["#0066FF"]]}
//                                                             isLinearGradient={true}
//                                                             duration={daysDuration}
//                                                             initialRemainingTime={remainingTime}
//                                                             trailColor="#e7e5e5"
//                                                         >
//                                                             {({ elapsedTime }) =>
//                                                                 renderTime(getTimeDays(daysDuration - elapsedTime))
//                                                             }
//                                                         </CountdownCircleTimer>
//                                                         <h3 className="title">Days</h3>
//                                                     </div>
//                                                 </div>
//                                                 <div className="timebox">
//                                                     <div className="hours main-day mr-12">
//                                                         <CountdownCircleTimer
//                                                             {...timerProps}
//                                                             colors={[["#d42d2a"], ["#0066FF", 1]]}
//                                                             isLinearGradient={true}
//                                                             duration={daySeconds}
//                                                             initialRemainingTime={remainingTime % daySeconds}
//                                                             onComplete={(totalElapsedTime) => [
//                                                                 remainingTime - totalElapsedTime > hourSeconds,
//                                                             ]}
//                                                             trailColor="#e7e5e5"
//                                                         >
//                                                             {({ elapsedTime }) =>
//                                                                 renderTime(getTimeHours(daySeconds - elapsedTime))
//                                                             }
//                                                         </CountdownCircleTimer>
//                                                         <h3 className="title">HRS</h3>
//                                                     </div>
//                                                 </div>
//                                                 <div className="timebox">
//                                                     <div className="minutes main-day mr-12">
//                                                         <CountdownCircleTimer
//                                                             {...timerProps}
//                                                             colors={[["#d42d2a"], ["#0066FF", 1]]}
//                                                             isLinearGradient={true}
//                                                             duration={hourSeconds}
//                                                             initialRemainingTime={remainingTime % hourSeconds}
//                                                             onComplete={(totalElapsedTime) => [
//                                                                 remainingTime - totalElapsedTime > minuteSeconds,
//                                                             ]}
//                                                             trailColor="#e7e5e5"
//                                                         >
//                                                             {({ elapsedTime }) =>
//                                                                 renderTime(getTimeMinutes(hourSeconds - elapsedTime))
//                                                             }
//                                                         </CountdownCircleTimer>
//                                                         <h3 className="title">MIN</h3>
//                                                     </div>
//                                                 </div>
//                                                 <div className="timebox">
//                                                     <div className="seconds main-day mr-12">
//                                                         <CountdownCircleTimer
//                                                             {...timerProps}
//                                                             colors={[["#d42d2a"], ["#0066FF", 1]]}
//                                                             isLinearGradient={true}
//                                                             duration={minuteSeconds}
//                                                             initialRemainingTime={remainingTime % minuteSeconds}
//                                                             onComplete={(totalElapsedTime) => [
//                                                                 remainingTime - totalElapsedTime > 0,
//                                                             ]}
//                                                             trailColor="#e7e5e5"
//                                                         >
//                                                             {({ elapsedTime }) =>
//                                                                 renderTime(getTimeSeconds(elapsedTime))
//                                                             }
//                                                         </CountdownCircleTimer>
//                                                         <h3 className="title">SEC</h3>
//                                                     </div>
//                                                 </div>
//                                             </>
//                                         ) : (
//                                             <div className="no-flash-sale">No ongoing flash sale</div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                         <Col xxl={8} xl={8} lg={8}>
//                             {remainingTime > 0 ? (
//                                 <Swiper
//                                     slidesPerView={5}
//                                     grid={{
//                                         rows: 2,
//                                     }}
//                                     spaceBetween={0}
//                                     pagination={{
//                                         clickable: true,
//                                     }}
//                                     modules={[Grid, Pagination]}
//                                     className="mySwiper"
//                                     onSwiper={(swiper) => setSwiperInstance(swiper)}
//                                     breakpoints={{
//                                         320: {
//                                             slidesPerView: 1,
//                                             grid: {
//                                                 rows: 1,
//                                             },
//                                         },
//                                         480: {
//                                             slidesPerView: 2,
//                                             grid: {
//                                                 rows: 1,
//                                             },
//                                         },
//                                         640: {
//                                             slidesPerView: 2,
//                                             grid: {
//                                                 rows: 2,
//                                             },
//                                         },
//                                         768: {
//                                             slidesPerView: 4,
//                                             grid: {
//                                                 rows: 2,
//                                             },
//                                         },
//                                         1024: {
//                                             slidesPerView: 5,
//                                             grid: {
//                                                 rows: 2,
//                                             },
//                                         },
//                                     }}
//                                 >
//                                     {flashhome.product?.map((item, index) => (
//                                         <SwiperSlide key={item.id}>
//                                             <div className="Sale-Products">
//                                                 <div className="sale-product-main">
//                                                     <div className="image-sale">
//                                                         <img src={IMG_URL + item.thumbnail} className="sale" alt={item.name} />
//                                                     </div>
//                                                     <div className="text-main">
//                                                         <p className="text">{item?.selling_price}</p>
//                                                         <p className="text main1">{item?.mrp}</p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </SwiperSlide>
//                                     ))}
//                                 </Swiper>
//                             ) : (
//                                 <div className="no-products">No products available for this flash sale</div>
//                             )}
//                             <div className="silder-btn">
//                                 <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
//                                     <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
//                                 </div>
//                                 <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
//                                     <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Container>
//             </section>
//         </>
//     );
// };

// export default FlashSale;



import React, { useState, useContext, useEffect } from "react";
import "./FlashSale.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "moment/locale/tr";
import Clock from "react-live-clock";
import "moment-timezone";
import "react-moment";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { GetHomeFlashSale } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import AddToCard from "../../CommonComponent/AddToCard/AddToCard";

const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;
const daySeconds = hourSeconds * 24;

const FlashSale = () => {
    const { sellerId, IMG_URL } = useContext(Context);
    const timerProps = {
        isPlaying: true,
        size: 90,
        strokeWidth: 6,
    };

    const renderTime = (time) => (
        <div className="time-wrapper">
            <div className="title time">{time}</div>
        </div>
    );

    const getTimeSeconds = (time) => Math.ceil(time % minuteSeconds);
    const getTimeMinutes = (time) => Math.floor((time % hourSeconds) / minuteSeconds);
    const getTimeHours = (time) => Math.floor((time % daySeconds) / hourSeconds);
    const getTimeDays = (time) => Math.floor(time / daySeconds);

    const [flashhome, setFlashHome] = useState([]);
    const [remainingTime, setRemainingTime] = useState(0);
    const [remainingTimeToStart, setRemainingTimeToStart] = useState(0);
    const [isSaleActive, setIsSaleActive] = useState(false);

    const getFlashHome = async () => {
        const res = await GetHomeFlashSale(sellerId);
        if (res?.success) {
            const startTime = new Date(res.data.flash_sale.start_time).getTime() / 1000;
            const endTime = new Date(res.data.flash_sale.end_time).getTime() / 1000;
            const currentTime = Date.now() / 1000;

            if (currentTime < startTime) {
                setIsSaleActive(false);
                setRemainingTimeToStart(startTime - currentTime);
            } else {
                setIsSaleActive(true);
                const timeLeft = endTime - currentTime;
                setRemainingTime(timeLeft > 0 ? timeLeft : 0);
            }
            setFlashHome(res?.data);
        } else {
            setRemainingTime(0);
            setRemainingTimeToStart(0);
        }
    };

    useEffect(() => {
        getFlashHome();
    }, []);

    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const daysDuration = Math.ceil(remainingTime / daySeconds) * daySeconds;
    const daysDurationToStart = Math.ceil(remainingTimeToStart / daySeconds) * daySeconds;

    return (
        <>
            <section className="countdown">
                <Container>
                    <div className="main-countdown">
                        <div className="title-content">
                            <h1 className="title">Flash</h1>
                            <FontAwesomeIcon icon={faBoltLightning} className="icon" />
                        </div>
                        <div className="sale">
                            <div className="border-main"></div>
                            <Link to={`/ecommerce2/product-sale/${flashhome?.flash_sale?.id}`}>
                                <p className="sub-text">View All Products from This Flash Sale</p>
                            </Link>
                        </div>
                    </div>
                    <Row>
                        <Col xxl={4} xl={4} lg={4}>
                            <div className="Sale-Banner">
                                <div className="Flash-sale">
                                    <img
                                        src={IMG_URL + flashhome.flash_sale?.image}
                                        className="sale"
                                    />
                                </div>
                                <div className="Sale-Timer">
                                    <div className="all-times-bar">
                                        {remainingTimeToStart > 0 ? (
                                            <>
                                                <div className="timebox">
                                                    <div className="day main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF"]]}
                                                            isLinearGradient={true}
                                                            duration={daysDurationToStart}
                                                            initialRemainingTime={remainingTimeToStart}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeDays(daysDurationToStart - elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">Days</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="hours main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={daySeconds}
                                                            initialRemainingTime={remainingTimeToStart % daySeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTimeToStart - totalElapsedTime > hourSeconds,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeHours(daySeconds - elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">HRS</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="minutes main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={hourSeconds}
                                                            initialRemainingTime={remainingTimeToStart % hourSeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTimeToStart - totalElapsedTime > minuteSeconds,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeMinutes(hourSeconds - elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">MIN</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="seconds main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={minuteSeconds}
                                                            initialRemainingTime={remainingTimeToStart % minuteSeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTimeToStart - totalElapsedTime > 0,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeSeconds(elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">SEC</h3>
                                                    </div>
                                                </div>
                                            </>
                                        ) : remainingTime > 0 ? (
                                            <>
                                                <div className="timebox">
                                                    <div className="day main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF"]]}
                                                            isLinearGradient={true}
                                                            duration={daysDuration}
                                                            initialRemainingTime={remainingTime}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeDays(daysDuration - elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">Days</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="hours main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={daySeconds}
                                                            initialRemainingTime={remainingTime % daySeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTime - totalElapsedTime > hourSeconds,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeHours(daySeconds - elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">HRS</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="minutes main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={hourSeconds}
                                                            initialRemainingTime={remainingTime % hourSeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTime - totalElapsedTime > minuteSeconds,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeMinutes(hourSeconds - elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">MIN</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="seconds main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={minuteSeconds}
                                                            initialRemainingTime={remainingTime % minuteSeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTime - totalElapsedTime > 0,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) =>
                                                                renderTime(getTimeSeconds(elapsedTime))
                                                            }
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">SEC</h3>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="no-flash-sale">No ongoing flash sale</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={8} xl={8} lg={8}>
                            {isSaleActive && remainingTime > 0 ? (
                                // <Swiper
                                //     slidesPerView={5}
                                //     grid={{
                                //         rows: 2,
                                //     }}
                                //     spaceBetween={0}
                                //     pagination={{
                                //         clickable: true,
                                //     }}
                                //     modules={[Grid, Pagination]}
                                //     className="mySwiper"
                                //     onSwiper={(swiper) => setSwiperInstance(swiper)}
                                //     breakpoints={{
                                //         320: {
                                //             slidesPerView: 1,
                                //             grid: {
                                //                 rows: 1,
                                //             },
                                //         },
                                //         480: {
                                //             slidesPerView: 2,
                                //             grid: {
                                //                 rows: 1,
                                //             },
                                //         },
                                //         640: {
                                //             slidesPerView: 2,
                                //             grid: {
                                //                 rows: 2,
                                //             },
                                //         },
                                //         768: {
                                //             slidesPerView: 4,
                                //             grid: {
                                //                 rows: 2,
                                //             },
                                //         },
                                //         1024: {
                                //             slidesPerView: 5,
                                //             grid: {
                                //                 rows: 2,
                                //             },
                                //         },
                                //     }}
                                // >
                                //     {flashhome.product?.map((item, index) => (
                                //         <SwiperSlide key={item.id}>
                                //             {/* <div className="Sale-Products">
                                //                 <div className="sale-product-main">
                                //                     <div className="image-sale">
                                //                         <img src={IMG_URL + item.thumbnail} className="sale" alt={item.name} />
                                //                     </div>
                                //                     <div className="text-main">
                                //                         <p className="text">₹{item?.selling_price}</p>
                                //                         <p className="text main1">₹{item?.mrp}</p>
                                //                     </div>
                                //                 </div>
                                //             </div> */}

                                //             <AddToCard title={item?.product_title} image={item.thumbnail} price1={item?.selling_price} price2={item?.mrp}/>
                                //         </SwiperSlide>
                                //     ))}
                                // </Swiper>
                                <div className="row">
                                    {flashhome.product?.map((item, index) => (
                                        <div className="col-md-3 col-sm-6 col-6 mb-4 " key={item.id}>
                                            <AddToCard title={item?.product_title} image={item.thumbnail} price1={item?.selling_price} price2={item?.mrp} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="no-products">No products available for this flash sale</div>
                            )}
                            <div className="silder-btn">
                                <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                    <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                                </div>
                                <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                    <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default FlashSale;
