import React, { useState, useEffect, useContext } from "react";
import "./ContactUs.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { faFacebookF, faLinkedinIn, faPinterestP, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { postContactUs } from "../../utils/apis/myAccount/myAccount";
import Success_Modalss from "../CommonComponent/PopUp/Success_Modal/Success_Modalss";


function ContactUs() {

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();


    const { IMG_URL, sellerId } = useContext(Context);

    const navigate = useNavigate();

    const [emailerror, setemailErrors] = useState("");
    const [modalcontact, setModalcontact] = useState(false);


    const onSubmit = async (d) => {
        d.seller_id = sellerId
        const res = await postContactUs(d);
        if (res?.success) {
            setModalcontact(true);
            setTimeout(() => {
                setModalcontact(false);
                window.location = "/"
            }, 2000)

        } else {
            setemailErrors(res?.message);
        }
    }

    return (
        <>
            <section className="contactus-section">
                <Container>
                    <div className="contactusmain">
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                <h4 className="heading-contact">Do you have some <br />questions?</h4>
                                <div className="mt-5">
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="contacts">
                                                <div className="contact-icnss">
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-phone"
                                                        className="iconss"
                                                    />
                                                </div>
                                                <div className="contacttxts">
                                                    <p>01123968786</p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="contacts">
                                                <div className="contact-icnss">
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-location-dot"
                                                        className="iconss"
                                                    />
                                                </div>
                                                <div className="contacttxts">
                                                    <p>
                                                        516/4, Gali Rajan Kalan, Kashmere Gate Delhi,
                                                        Delhi, 110006
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="contacts">
                                                <div className="contact-icnss">
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-envelope"
                                                        className="iconss"
                                                    />
                                                </div>
                                                <div className="contacttxts">
                                                    <p>inquiry@netpurti.com</p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="contacts">
                                                <div className="contact-icnss">
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-clock"
                                                        className="iconss"
                                                    />
                                                </div>
                                                <div className="contacttxts">
                                                    <p>Monday - Friday 9am to 5pm</p>
                                                    <p>Sunday - Closed</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="websites-div">
                                    <Link to={"https://www.facebook.com/"} target="blank"><div className="websites face">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </div></Link>
                                    <Link to={"https://in.linkedin.com/"} target="blank"> <div className="websites linked">
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </div></Link>
                                    <Link to={"https://in.pinterest.com/"} target="blank"> <div className="websites pina">
                                        <FontAwesomeIcon icon={faPinterestP} />
                                    </div></Link>
                                    <Link to={"https://twitter.com/"} target="blank"> <div className="websites tiwt">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </div></Link>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div>
                                    <div className="contact-headss">
                                        <h5 className="cat-title">Contact Us</h5>
                                    </div>
                                    <div className="blogform">
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Your name"{...register("name", {
                                                        required: "Name is required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.name,
                                                    })}
                                                    onKeyDown={(event) => {
                                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    {...register("email", {
                                                        required: "Email Id required",
                                                        pattern: {
                                                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                            message: "Email Id Invalid",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                    })}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Subject"
                                                    {...register("subject", {
                                                        required: "Subject is required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.subject,
                                                    })}
                                                    onKeyDown={(event) => {
                                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Your message"
                                                    {...register("message", {
                                                        required: "Message is required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.message,
                                                    })}
                                                    onKeyDown={(event) => {
                                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <button className="sentbtn" type="submit">Send Message</button>
                                            </Form.Group>
                                        </Form>
                                    </div>

                                </div>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mt-5">
                                <div className="gmaps">
                                    <iframe
                                        className="mapss"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1877885133745!2d73.76940577579694!3d18.52041416917794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a439e4855%3A0xa27b14514af0a4b3!2sProfcyma%20Career%20Solutions%20LLP%7COnline%20MBA%20Programs%7COnline%20Degree%20Programs%7CDistance%20MBA%7CBest%20Online%20Education%20Service%20Provider%7C!5e0!3m2!1sen!2sin!4v1711185839365!5m2!1sen!2sin"
                                        height="450"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Success_Modalss
                show={modalcontact}
                onHide={() => setModalcontact(false)}
                texts={"You will be contacted soon!!"}
            />
        </>
    );
}

export default ContactUs;
