import React, { useState } from 'react'
import './CommonSwipers.css'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import Product from '../../Product/Product';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function CommonSwipers() {
  const childCategory = [
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
  ]


  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick2 = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <section className='commonswiper_section my-4'>
        <Container>
          <div className='commonswiper_main'>
            <div className='titless my-3'>
              <h3 className='title'>Featured Products</h3>
              <div class="silder_btn">
                <div
                  className="swipebtn me-2"
                  onClick={() =>
                    handleMainSliderClick2("prev")
                  }
                >

                  <FontAwesomeIcon icon={faArrowLeft} /> {" "}
                </div>
                <div
                  className="swipebtn"
                  onClick={() =>
                    handleMainSliderClick2("next")
                  }
                >
                  <FontAwesomeIcon icon={faArrowRight} /> {" "}
                </div>
              </div>
            </div>
            <div className='commnswiperdiv'>
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  380: {
                    slidesPerView: 2,
                  },
                  575: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  992: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                  1400: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                  },
                }}
                navigation={false}
                className="mySwiper"
                onSwiper={(swiper) =>
                  setSwiperInstance(swiper)
                }
              >
                {childCategory?.map((value, index) => (
                  <SwiperSlide>
                    <Product />

                  </SwiperSlide>
                ))}
              </Swiper>

            </div>

          </div>
        </Container>
      </section>
    </>
  )
}

export default CommonSwipers