import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./SaleBanner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { GetAllCrazyDeals } from "../../../utils/apis/master/Master";

const SaleBanner = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const Deal = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/SaleBanner/sb1.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/SaleBanner/sb2.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/SaleBanner/sb3.jpg",
        },
    ];

    const [banners, setBanner] = useState([]);

    const { sellerId, IMG_URL } = useContext(Context);

    const getBanner = async () => {
        const res = await GetAllCrazyDeals(sellerId);
        if (res?.success) {
            setBanner(res?.data);
            console.log(res?.data, "crazydeals");
        }
    };
    useEffect(() => {
        getBanner();
    }, []);
    return (
        <>
            <section className="SaleBanner">
                <Container>
                    <Swiper
                        className="mySwiper"
                        pagination={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            380: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            485: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            575: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },

                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            992: {
                                slidesPerView: 2.5,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 2.5,
                                spaceBetween: 30,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1440: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            2000: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {banners.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Link to={`/ecommerce2/flash-sale`}>
                                    <div className="Sale">
                                        <img src={IMG_URL + item?.s_sub_category?.subcategory_of_seller?.image} className="sale-banner" />
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="silder-btn">
                        <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                            <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                        </div>
                        <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                            <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default SaleBanner;
