import React, { useEffect, useState } from "react";
import ProductSlider from "./ProductSlider/ProductSlider";
import ReviewsRating from "./ReviewsRating/ReviewsRating";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import {
  getReview,
  postReview,
  productReviewOnlyOnce,
} from "../../utils/apis/Product/Product";

const MAX_IDS = 10;

const ProductDetails = () => {
  const { id } = useParams();
  const { sellerId, IMG_URL } = useContext(Context);

  useEffect(() => {
    // Get existing array from the cookie
    const existingIdsJSON = Cookies.get("ProductIds");
    const existingIdsArray = existingIdsJSON ? JSON.parse(existingIdsJSON) : [];

    if (!existingIdsArray.includes(id)) {
      const updatedIdsArray = [id, ...existingIdsArray.slice(0, MAX_IDS - 1)];
      Cookies.set("ProductIds", JSON.stringify(updatedIdsArray));
    }
  }, [id]);

  console.log(Cookies.get("ProductIds"), "iddiiddd");

  const [reviewdetail, setReviewdetail] = useState([]);

  const getReviews = async () => {
    try {
      const response = await getReview(id);
      if (response?.success) {
        setReviewdetail(response.data.data);
        console.log(response.data.data, "gauata");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const [userreviewed, setUserReviewed] = useState([]);

  const getUserReviewee = async () => {
    const res = await productReviewOnlyOnce(id, sellerId);

    const ratings = res?.data?.reviews?.map((review) => review?.rating);
    setUserReviewed(ratings);
    console.log(ratings, "gauarav");
  };

  useEffect(() => {
    getUserReviewee();
  }, []);


  const [avrage, setaverage] = useState(0);
  var average = 0;
  useEffect(() => {
    // Calculate sum and count inside this useEffect, which runs after reviewdetail is updated
    const array = userreviewed?.map(Number); // Convert each element to a number
    const sum = array?.reduce((acc, curr) => acc + curr, 0); // Sum of array elements
    const count = array?.length;
    setaverage(sum / count);

  }, [userreviewed]);

  return (
    <>
      <ProductSlider
        average={avrage}
        id={id}
        reviewdetail={reviewdetail}
        sellerId={sellerId}
        IMG_URL={IMG_URL}
      />
      <ReviewsRating
        id={id}
        sellerId={sellerId}
        IMG_URL={IMG_URL}
        getReviewed={getReviews}
      />
    </>
  );
};

export default ProductDetails;
