import "./banner/Banner.css";
import Banner from "./banner/Banner";
import FeaturedCategories from "./FeaturedCategories/FeaturedCategories";
import Deal from "./Deal/Deal";
import FlashSale from "./FlashSale/FlashSale";
import SaleBanner from "./SaleBanner/SaleBanner";
import SwiperMain from "./SwiperMain/SwiperMain";
import UsedProducts from "./UsedProducts/UsedProducts";
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import KidsBanner from "./KidsBanner/KidsBanner";
import BestSelling from "./BestSelling/BestSelling";
import NewProducts from "./NewProducts/NewProducts";
import Auction from "./Auction/Auction";
import SaleSlide from "./SaleSlide/SaleSlide";
import BuySale from "./BuySale/BuySale";
import BuyProduct from "./BuyProduct/BuyProduct";
import GreatDeals from "../CommonComponent/GraetDeals/GreatDeals";
import AllBrands from "../AllBrands/AllBrands";
import HomeAllBrands from "./HomeAllBrands/HomeAllBrands";
import HomeAllSellers from "./HomeAllSellers/HomeAllSellers";
import LatestViews from "../CommonComponent/LatestViews/LatestViews";

function Home() {
    return (
        <>
            <Banner />
            <FlashSale />
            <Deal />
            <FeaturedCategories />
            <SaleBanner />
            {/* <UsedProducts/> */}
            <FeaturedProducts />
            <KidsBanner />
            <BestSelling />
            <NewProducts />
            <SaleBanner />
            {/* <Auction /> */}
            {/* <SaleSlide /> */}
            {/* <SaleSlide/>
             <SaleSlide/> */}
            {/* <BuySale/> */}
            {/* <BuyProduct/> */}
            {/* <GreatDeals/>  */}
            {/* <SwiperMain/> */}
            <HomeAllBrands />

            {/* <HomeAllSellers/> */}
        </>
    );
}

export default Home;
