import React, {useContext, useEffect, useState} from "react";
import "./InnerBlogs.css";
import {Col, Container, Form, Offcanvas, Row} from "react-bootstrap";
import {faArrowRight, faEnvelope, faFilter, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Recent_post from "../../CommonComponent/Recent_Post/Recent_post";
import {faFacebookF, faLinkedinIn, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Context} from "../../../utils/context";
import {blogdetails} from "../../../utils/apis/common/Common";
import {useParams} from "react-router-dom";
import parse from "html-react-parser";
function InnerBlogs() {
    const recentpost = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
            title: "T-Shirts Every Man Needs in His Wardrobe",
            time: "10 jan 2024",
            place: "Life hack",
        },

        {
            image: process.env.PUBLIC_URL + "/assests/images/blogs/recent2.webp",
            title: "T-Shirts Every Man Needs in His Wardrobe",
            time: "10 jan 2024",
            place: "Life hack",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/blogs/recent3.webp",
            title: "T-Shirts Every Man Needs in His Wardrobe",
            time: "10 jan 2024",
            place: "Life hack",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/blogs/recent4.webp",
            title: "T-Shirts Every Man Needs in His Wardrobe",
            time: "10 jan 2024",
            place: "Life hack",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
            title: "T-Shirts Every Man Needs in His Wardrobe",
            time: "10 jan 2024",
            place: "Life hack",
        },
    ];

    const {id} = useParams();

    const {IMG_URL} = useContext(Context);
    const [blogdet, setBlogdet] = useState([]);
    const [date, setDate] = useState(new Date());
    const getBlogdetails = async () => {
        const res = await blogdetails(id);
        if (res?.success) {
            setBlogdet(res?.data);
            console.log(res?.data);
        } else {
        }
    };

    useEffect(() => {
        if (id) {
            getBlogdetails();
        }
    }, [id]);

    return (
        <>
            <section className="innerblogmain_section mb-4">
                <Container>
                    <div className="innrblogmain_div">
                        <Row>
                            <Col xxl={12} xl={12} lg={12}>
                                <div className=" innerblog_div">
                                    <div className=" mb-3">
                                        {/* <h4 className="title mb-3">T-Shirts Every Man Needs in His Wardrobe</h4>  */}

                                        <div className="textcontt">
                                            <div className="">
                                                <p className="text mb-1">{date.toDateString()}</p>
                                                {/* <p className='text manfashtext'>Man Fashion</p> */}
                                            </div>
                                            <div className="icnnmain">
                                                <div className="innerblgicn_div innerblgicn_div1 me-2">
                                                    <FontAwesomeIcon icon={faEnvelope} className="innnrblgicn" />
                                                </div>
                                                <div className="innerblgicn_div innerblgicn_div2 me-2">
                                                    <FontAwesomeIcon icon={faTwitter} className="innnrblgicn" />
                                                </div>
                                                <div className="innerblgicn_div innerblgicn_div3 me-2">
                                                    <FontAwesomeIcon icon={faFacebookF} className="innnrblgicn" />
                                                </div>
                                                <div className="innerblgicn_div innerblgicn_div4 me-2">
                                                    <FontAwesomeIcon icon={faLinkedinIn} className="innnrblgicn" />
                                                </div>
                                                <div className="innerblgicn_div innerblgicn_div5">
                                                    <FontAwesomeIcon icon={faWhatsapp} className="innnrblgicn" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blogsss_div">
                                        <div className="innerblgimgg_div mb-4">
                                            <img src={IMG_URL + blogdet?.image} className="innerimgblg" />
                                        </div>
                                        <p className="titleblg mb-2">{blogdet?.name}</p>
                                        <p className="text">
                                            {blogdet && (
                                                <span dangerouslySetInnerHTML={{__html: blogdet.description}}></span>
                                            )}
                                        </p>
                                      
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={12} xl={12} lg={12}>
                                {/* <div className="checktablet">
                                    <div className="recentblogs_divsss">
                                        <h6 className=" text mb-3">Recent Blogs</h6>
                                        <Row>
                                            {blogdet.map((item, index) => (
                                                <Col xxl={12} className="mb-3">
                                                    <div key={index} className="recent_mainss">
                                                        <div>
                                                            <div className="recentpost_img_div">
                                                                <img
                                                                    src={IMG_URL + item?.image}
                                                                    alt="logo"
                                                                    className="recent_img"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="recent_text">
                                                            <p className="titleblg mb-2">{item.name}</p>
                                                            <p className="timeblg mb-0">{parse(item?.description)}</p>
                                                            <p className="placeblg mb-0">{item.place}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </div> */}
                            </Col>
                        </Row>

                        <div className=" recentblogs_divsss tabletview  mt-4">
                            <h6 className="text mb-3">Recent Blogs</h6>
                            {/* <Row>
                                {recentpost.map((item, index) => (
                                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} className=" mb-3">
                                        <Recent_post item={item} />
                                    </Col>
                                ))}
                            </Row> */}
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default InnerBlogs;
