import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import LatestViews from "../CommonComponent/LatestViews/LatestViews";
import "./AboutUs.css";
import {Context} from "../../utils/context";
// import {parse} from "@fortawesome/fontawesome-svg-core";
import {allourstory} from "../../utils/apis/master/Master";
import parse from "html-react-parser";
const AboutUs = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "About us", link: "#"},
        ,
    ]);

  

    const [ourstory, setOurstory] = useState(null);
    const {IMG_URL, sellerId} = useContext(Context);

    const getOurstory = async () => {
        try {
            const res = await allourstory(sellerId);
            if (res?.success) {
                setOurstory(res?.data);
                console.log(res?.data);
            } else {
            }
        } catch (error) {
            console.error("Error fetching our story:", error);
        }
    };

    useEffect(() => {
        getOurstory();
    }, []);
    return (
        <>
            <section className="AboutUs">
                <Container>
                    <div className="main-block">
                        <h1 className="title">About Us</h1>

                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                    </div>
                    <h1 className="title">Welcome To Website</h1>
                    <p className="sub-text">{ourstory && ourstory.description && parse(ourstory.description)}</p>
                </Container>
                {/* <LatestViews/> */}
            </section>
        </>
    );
};

export default AboutUs;
