import React, { useState } from "react";
import "./IncreamentDecreament.css";
import { CartQuantity, RemoveToCart } from "../../../utils/apis/addToCart/addToCart";

function IncreamentDecreament({ quantity, id, cartGet, removeFromCart }) {
    const [counter, setCounter] = useState(quantity);
    const [buttonStatus, setButtonStatus] = useState("true");

    async function increment(counter) {
        counter++;
        setButtonStatus("");
        await CartQuantity(id, "add");
        setCounter(counter);
        cartGet();
    }

    async function decrement(counter) {
        counter--;
        if (counter === 0) {
            await removeFromCart();
        } else {
            await CartQuantity(id, "minus");
        }
        setCounter(counter);
        cartGet();
        if (counter < 1) {
            setButtonStatus("true");
        }
    }

    return (
        <div className="Incre_Decre_btn scale-up-hor-right">
            <div>
                <button
                    className="counter_btn minus"
                    disabled={buttonStatus}
                    onClick={() => decrement(counter)}
                >
                    -
                </button>
            </div>
            <div>
                <span className="subText">{counter}</span>
            </div>
            <div>
                <button
                    className="counter_btn plus"
                    onClick={() => increment(counter)}
                >
                    +
                </button>
            </div>
        </div>
    );
}

export default IncreamentDecreament;
