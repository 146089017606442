import React from "react";
import "./InnerCoupons.css";
import { Container } from "react-bootstrap";
// import FollowSeller from "../../AllSellers/SellerInner/FollowSeller/FollowSeller";
import CommonSwipers from "../../CommonComponent/CommonSwipers/CommonSwipers";
import CommonBannerSwiper from "../../CommonComponent/CommonBannerSwiper/CommonBannerSwiper";
function InnerCoupons() {
  return (
    <>
      <section className="">
        <Container>
          <div className="innercouponmain">
            {/* <FollowSeller /> */}
            <CommonSwipers />
            <CommonBannerSwiper />
            <CommonSwipers />
          </div>
        </Container>
      </section>
    </>
  );
}

export default InnerCoupons;
