import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Heart_Component.css";
import { Context } from "../../../utils/context";
import { addWishlist } from "../../../utils/apis/Wishlist/Wishlist";

function Heart_Component({  updateWishlist, productId }) {
    const [heartactive, setHeartActive] = useState(false);
    const { sellerId, wishlistData, wishlistContext, signin, triggercart } = useContext(Context);
    const navigate = useNavigate();

    // Use effect to set initial state of the heart based on wishlistData
    useEffect(() => {
        if (wishlistData) {
            setHeartActive(wishlistData.some((item) => item === productId));
        }
    }, [wishlistData, productId]);

    const handleClickes = async () => {
        if (signin) {
            await addWishlist(productId, sellerId);
            triggercart();
            await wishlistContext();
            updateWishlist();
          
        } else {
            navigate("/ecommerce2/login");
        }
    };

    return (
        <div className="wishlist">
            <FontAwesomeIcon
                icon={faHeart}
                onClick={handleClickes}
                className={heartactive ? "gobiheartss" : "gobiheart"}
            />
        </div>
    );
}

export default Heart_Component;
