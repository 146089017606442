import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import "./ReviewsRating.css";
import { GetBestSelling } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import {
  postReview,
  productReviewOnlyOnce,
} from "../../../utils/apis/Product/Product";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Success_Modalss from "../../CommonComponent/PopUp/Success_Modal/Success_Modalss";
// import Success_Modalss from "../../Common_Component/Success_Modal/Success_Modalss";

const ReviewsRating = ({ id , getReviewed}) => {
  const { IMG_URL, signin, sellerId } = useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const [userreview, setUserReviews] = useState(false);

  const [reviews, setReviews] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  // const topselling = [
  //     {
  //         image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f1.png",
  //         text: "Disney Men's Mickey and Friends Button Down Shirt",
  //         saleprice: "400",
  //         currentprice: "6000",
  //     },
  //     {
  //         image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f1.png",
  //         text: "Disney Men's Mickey and Friends Button Down Shirt",
  //         saleprice: "400",
  //         currentprice: "6000",
  //     },
  //     {
  //         image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f1.png",
  //         text: "Disney Men's Mickey and Friends Button Down Shirt",
  //         saleprice: "400",
  //         currentprice: "6000",
  //     },
  //     {
  //         image: process.env.PUBLIC_URL + "/assests/images/Home/FeaturedC/f1.png",
  //         text: "Disney Men's Mickey and Friends Button Down Shirt",
  //         saleprice: "400",
  //         currentprice: "6000",
  //     },
  // ];

  const onSubmit = async (data) => {
    try {
      data.product_id = id;
      data.seller_id = sellerId;
      data.rating = rating;

      const res = await postReview(data);
      if (res?.success) {
        reset();

        setRating(0);
        setModalShow(true);
        setTimeout(() => {
        //   getReviews();
          getReviewed();
          getUserReview();
          setModalShow(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

//   const getReviews = async () => {
//     try {
//       const response = await getReview(id);
//       if (response?.success) {
//         setReviews(response.data.data);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

  const getUserReview = async () => {
    const res = await productReviewOnlyOnce(id, sellerId);

    setUserReviews(res?.data?.hasReviewed);
  };

  // Rating star
  const [rating, setRating] = useState(0);

  // Catch Rating value
  // const handleRating = (rate: number) => {
  //     setRating(rate);

  // };
  // Optinal callback functions
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) => console.log(value, index);

  // Top selling api

  const [topselling, setTopSelling] = useState([]);
  const gettopselling = async () => {
    const res = await GetBestSelling(sellerId);
    if (res?.success) {
      setTopSelling(res?.data);
    }
  };

  useEffect(() => {
    gettopselling();
  }, []);

  // Reviews rating

  const [review, setReview] = useState(null);

  const getReview = async (rating) => {
    try {
      const res = await postReview(sellerId);
      if (res?.success) {
        setReview(res?.data);
        getReviewed();
        console.log(res?.data, "post Reviews");
      } else {
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  


  return (
    <>
      <section className="ReviewsRating">
        <Container>
          <Row>
            <Col xxl={3}>
              <div className="Selling-Product">
                <h1 className="text">Top Selling Products</h1>
                <div className="Top-Selling">
                  {topselling.map((item, index) => (
                    <div className="Product mt-2">
                      <div className="main-class">
                        <div className="pc-image">
                          <img
                            src={IMG_URL + item.thumbnail}
                            className="product-img"
                          />
                        </div>
                        <div className="content">
                          <p className="sub-text">{item?.product_title}</p>
                          <div className="price">
                            <p className="price">₹{item.mrp} </p>
                            <p className="sub-text">₹{item.selling_price}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xxl={9}>
              <div className="reviews">
                <h1 className="title">Reviews & Ratings</h1>
                <div className="rating">
                  <Row>
                    <Col xxl={6}>
                      <div className="star-rates">
                        <h1 className="title">
                          {review ? review.averageRating : "0"}
                        </h1>
                        <Form
                          className="col-lg-5"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          {!userreview && signin && (
                            <>
                              <div className="ratingStarsec">
                                <Rating
                                  onClick={(rate) => setRating(rate)}
                                  ratingValue={rating}
                                  size={"25px"}
                                />
                              </div>

                              <div className="ratingbtnsec">
                                <button
                                  onClick={async () => {
                                    await setRating(1);
                                    setValue("name", "");
                                  }}
                                  className="cancelbtn"
                                >
                                  Cancel
                                </button>

                                <button className="postbtn" type="submit">
                                  Post
                                </button>
                              </div>
                            </>
                          )}
                        </Form>
                        <div className="rates">
                          {/* <p className="sub-text">out of 5.0</p> */}
                          {/* <Rating
                                                        onClick={handleRating}
                                                        onPointerEnter={onPointerEnter}
                                                        onPointerLeave={onPointerLeave}
                                                        onPointerMove={onPointerMove}

                                                    /> */}
                          <p className="sub-text">
                            ({review ? review.totalReviews : "0"} reviews)
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={6}></Col>
                  </Row>
                </div>
              </div>
              <div className="Description mt-3">
                <h1 className="title">Description</h1>
                <ul className="mt-4">
                  <li className="sub-text">
                    A functional tote bag that pays attention to subtle details
                    with its slightly crackled vegan leather composition. Sherpa
                    detailing along body stitching, outer frame, and as well as
                    for the top handle/ shoulder strap. An interior spacious
                    enough to store your laptop, wallet, and other main
                    essentials for an eventful day. An elegant purse to match
                    with any outfit and serve as an everyday handbag.
                  </li>
                  <li className="sub-text">
                    [Dimensions] 16"W x 12.5"H x 6.5"D, 11" Top Handle, Hook
                    closure, Inner zipper pocket
                  </li>
                  <li className="sub-text">
                    [Quality] 100% Premium High Quality Vegan Leather + Sherpa
                  </li>
                  <li className="sub-text">
                    A divine gift idea for your family, wife, partner, friend,
                    important and loved ones at Mother's Day, Anniversary,
                    Birthday, Thanksgiving Day, Christmas, New Year or any
                    special occasion
                  </li>
                  <li className="sub-text">
                    Clean the outside fabric with a slightly wet cloth, Do not
                    bleach, Do not soak in water, Avoid moistening the leather
                    parts when cleaning, Hang in a shady place to dry, In case
                    of a deep stain, please seek professional care
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Success_Modalss
        texts=" Review added successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ReviewsRating;
