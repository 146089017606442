import React, {useContext, useState, useEffect} from "react";
import "./Banner.css";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";
import {GetAllHomeBanner} from "../../../utils/apis/master/Master";
import {Context} from "../../../utils/context";
import "swiper/css";

function Banner() {
    const Banner = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Banner/banner4.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Banner/banner3.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Banner/banner2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Banner/banner1.png",
        },
    ];

    const [banners, setBanner] = useState([]);

    const {sellerId, IMG_URL} = useContext(Context);

    const getBanner = async () => {
        const res = await GetAllHomeBanner(sellerId);
        if (res?.success) {
            setBanner(res?.data);
        }
    };
    useEffect(() => {
        getBanner();
    }, []);
    return (
        <section className="Home-Banner">
            <Container>
                <Swiper
                    className="mySwiper"
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {banners?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="banner">
                                <img src={IMG_URL + item?.image} className="banner-image" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </section>
    );
}

export default Banner;
