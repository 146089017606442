import React from "react";
import {Col, Row} from "react-bootstrap";
import "./ClassifiedProduct.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight, faAngleRight, faBars, faCrown, faDownload, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
const ClassifiedProduct = () => {
    return (
        <>
            <section className="ClassifiedProduct">
                <h1 className="title">Classified Products</h1>
                <Row>
                    <Col xxl={4}>
                        <div className="upload">
                            <FontAwesomeIcon icon={faDownload} className="download" />
                            <p className="title">Remaining Uploads</p>
                            <p className="title">576</p>
                        </div>
                    </Col>
                    <Col xxl={4}>
                        <div className="new-product">
                            <div className="add">
                                <FontAwesomeIcon icon={faPlus} className="plus" />
                            </div>
                            <p className="text">Add New product</p>
                        </div>
                    </Col>
                    <Col xxl={4}>
                        <div className="new-product-main">
                            <div className="upgrade">
                                <FontAwesomeIcon icon={faCrown} />
                            </div>
                            <p className="text">Current Package: Premium</p>
                            <div className="plan">
                                <p className="text">Upgrade Package </p>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="all-products">
                    <h1 className="title">All Products</h1>

                    <table class="table  table-responsive">
                        <thead>
                            <tr>
                                <th className="sub-text-text-text">#</th>
                                <th className="sub-text-text-text">product</th>
                                <th className="sub-text-text-text">Price</th>

                                <th className="sub-text-text-text">Available Status</th>
                                <th className="sub-text-text-text">Admin Status</th>
                                <th className="sub-text-text-text option">Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            
                            <tr>
                                <td className="sub-text-text">03</td>
                                <td className="date">
                                    <div className="classify-product">
                                        <div className="image">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/assests/images/Home/FlashSale/sale2.png"
                                                }
                                                className="classify"
                                            />
                                        </div>
                                        <p className="sub-text-text">
                                            Women's Two-Piece Dress with Embellished Jacket (Petite and Regular)
                                        </p>
                                    </div>
                                </td>
                                <td className="amount">$149.000</td>
                                <td>
                                    <Form>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                        />
                                    </Form>
                                </td>
                                <td>
                                    <ButtonCommon className="paid" text="Published" />
                                </td>
                                <td>
                                <div className="main">
                                   
                                    <div className="buttons">
                                        <div className="delete">
                                            <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                                        </div>
                                        {/* <div className="compare">
                                            <FontAwesomeIcon icon={faBars} className="compare-icon" />
                                        </div> */}
                                        <div className="download">
                                            <FontAwesomeIcon icon={faBars} className="download-icon" />
                                        </div>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="sub-text-text">04</td>
                                <td className="date">
                                    <div className="classify-product">
                                        <div className="image">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/assests/images/Home/FlashSale/sale2.png"
                                                }
                                                className="classify"
                                            />
                                        </div>
                                        <p className="sub-text-text">
                                            Women's Two-Piece Dress with Embellished Jacket (Petite and Regular)
                                        </p>
                                    </div>
                                </td>
                                <td className="amount">$149.000</td>
                                <td>
                                    <Form>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                        />
                                    </Form>
                                </td>
                                <td>
                                    <ButtonCommon className="paid" text="Published" />
                                </td>
                                <td>
                                <div className="main">
                                   
                                    <div className="buttons">
                                        <div className="delete">
                                            <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                                        </div>
                                        {/* <div className="compare">
                                            <FontAwesomeIcon icon={faBars} className="compare-icon" />
                                        </div> */}
                                        <div className="download">
                                            <FontAwesomeIcon icon={faBars} className="download-icon" />
                                        </div>
                                    </div>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};

export default ClassifiedProduct;
