import React, { useState, useContext, useEffect } from "react";
import "./AllBrands.css";
import { Col, Container, Row } from "react-bootstrap";
import Brandss from "../CommonComponent/Brandss/Brandss";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Brands } from "../../utils/apis/master/Master";
import { Context } from '../../utils/context';

function AllBrands() {

    const branddd = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd1.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd2.jpg",
            name: "toyota ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd3.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd4.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd5.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd6.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd1.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd2.jpg",
            name: "toyota ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd3.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd4.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd5.jpg",
            name: "mercedes ",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllBrands/brandd6.jpg",
            name: "mercedes ",
        },
    ];

    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "All Brands", link: "#" },
        ,
    ]);

    const { sellerId, type } = useContext(Context);
    const [brands, setBrand] = useState([]);
    const getBrand = async () => {
        const res = await Brands(sellerId, type);
        if (res?.success) {
            setBrand(res?.data);
            console.log(res?.data, "brasds");
        }
    };

    useEffect(() => {
        getBrand();
    }, []);

    return (
        <>
            <section className="allbrand_section mb-4  ">
                <Container>
                    <div className="allbrand_main">
                        <div className="main-block">
                            <h1 className="title">All Brands</h1>
                            <div>
                                <Breadcrumbs items={breadcrumbItems} />
                            </div>
                        </div>
                        <Row className="p-2">
                            {brands?.map((item, index) => (
                                <Col xxl={2} xl={3} lg={3} md={4} sm={4} xs={6} className="p-0 mx-auto">
                                    <div className="">
                                        <Brandss brand={item} />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default AllBrands;
