import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddToCard from "../../CommonComponent/AddToCard/AddToCard";
import {
    faCartShopping,
    faHeart,
    faArrowRightArrowLeft,
    faTrash,
    faHouse,
    faClipboard,
    faDownload,
    faCodeCompare,
    faDollarSign,
    faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./WishlistTab.css";
import { addWishlist, getWishlistProducts } from "../../../utils/apis/Wishlist/Wishlist";
import { Context } from "../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import Heart_Component from "../../CommonComponent/Heart_Component/Heart_Component";

const WishlistTab = () => {
    const { IMG_URL } = useContext(Context);
    const [products, setProducts] = useState([]);

    const getList = async () => {
        const res = await getWishlistProducts();
        if (res?.success) {
            setProducts(res?.data);
        }
    };

    useEffect(() => {
        getList();
    }, []);

    const updateWishlist = () => {
        getList(); // Trigger re-fetch of wishlist
    };

    const Whislist = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag,",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d5.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d7.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
    ];

    return (
        <>
            {products.length === 0 ? (
                <>
                    <div className="heading_holder mt-3">
                        <h5 className="title">Your wishlist is Empty</h5>
                        <p className="sub-text">You have no items in your shopping wishlist. Let's go add something</p>
                        <div className="main-btn-div">
                            <Link to={"/"}>
                                <div className="Add">
                                    <button className="add-btn" type="button">
                                        Add Now
                                    </button>
                                </div>
                            </Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <section className="WishlistTab">
                        <p className="title">Wishlist</p>
                        <Row className="">
                            {products?.map((item, index) => (
                                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={12} key={index}>

                                    <AddToCard
                                        image={IMG_URL + item.thumbnail}
                                        title={item?.product_title}
                                        price1={item?.mrp}
                                        price2={item?.selling_price}
                                        productId={item?.id}
                                        updateWishlist={updateWishlist}
                                    />

                                    {/* <div className="Main-Cart">
                                            <button class="btn draw-border">
                                                <div className="Card-product">
                                                    <div className="sec-image">
                                                        <div className="image-product">
                                                            <img src={IMG_URL + item.thumbnail} className="store" />
                                                        </div>
                                                        <div className="add-cart-btn">
                                                            <div className="cart">
                                                                <p className="sub-text cart-text">Add To Cart</p>
                                                                <p className="sub-text cart-icon">
                                                                    <FontAwesomeIcon
                                                                        icon={faCartShopping}
                                                                        className="icon"
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="buttons-card">
                                                       
                                                        <Heart_Component productId={item?.id} getList={getList} />
                                                      
                                                    </div>
                                                    <h1 className="sub-text">{item?.product_title}</h1>

                                                    <div className="price">
                                                        <p className="text main1">₹{item?.mrp}</p>
                                                        <p className="text main2">₹{item?.selling_price}</p>
                                                    </div>
                                                </div>
                                            </button>
                                        </div> */}

                                </Col>
                            ))}
                        </Row>
                    </section>
                </>
            )}
        </>
    );
};

export default WishlistTab;
