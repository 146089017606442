import React, { useState } from "react";
import "./ForgotPassword.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { faArrowLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import classNames from "classnames";
import EmailOtpModal from "../../CommonComponent/PopUp/Email_otp_modal/EmailOtpModal";
import {
    faApple,
    faFacebookF,
    faGoogle,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import { useForm } from "react-hook-form";
import { RegxExpression } from "../../../utils/common";
import { sendEmailOTP, verifyEmailOTP } from "../../../utils/apis/common/Common";
import SetNewPassword from "../SetNewPassword/SetNewPassword";
import CheckEmail from "../CheckEmail/CheckEmail";
import Login from "../Login/Login";
function ForgotPassword({ setShowForgetPassword }) {
    const [showSetNewPassword, setShowSetNewPassword] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [otpErr, setOtpErr] = useState("");
    const [emailVerify, setEmailVerify] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);
    const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
    const toggleInput = () => {
        setShowEmail(!showEmail);
    };

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const [otpLoader, setOtpLoader] = useState(false);
    const handleSendOtp = async () => {

        setOtpLoader(true);
        if (getValues("email")) {
            if (RegxExpression.email.test(getValues("email"))) {
                const res = await sendEmailOTP(getValues("email"));
                if (res?.success) {
                    setModalShow3(true);
                    setSeconds(90);
                    clearErrors();
                } else {
                    setError("email", {
                        message: res?.message?.email,
                    });
                }
            } else {
                setError("email", {
                    message: "Email Invalid",
                });
            }
        } else {
            setError("email", {
                message: "Email Required",
            });
        }
        setOtpLoader(false);
    };

    const verifyOTP = async (otp) => {
        const formValues = getValues();

        const res = await verifyEmailOTP(getValues("email"), otp);
        if (res?.success) {
            setEmailVerify(true);
            setModalShow3(false);
            clearErrors();
            setSeconds(0);
            setShowSetNewPassword(true);
        } else {
            setOtpErr(res?.message);
        }
    };

    console.log(errors);
    return (
        <>
            <section className="login_section">
                <Container fluid>
                    {!showSetNewPassword && !showLogin && !showCheckYourEmail && (
                        <div>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={12}>
                                    <div className="logincardiv">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assests/images/Login/forgotimg.webp"
                                            }
                                            className="logincartimgdiv"
                                        />
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className="my-auto">
                                    <div className="">
                                        <Row>
                                            <Col xxl={6} xl={9} lg={10} className="p-4 p-lg-4 ">
                                                <div>
                                                    <div className="baggimg">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assests/images/Login/bagge.svg"
                                                            }
                                                            className="bagimg"
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <h1 className="title">Welcome Back !</h1>
                                                        <h5 className="sub-text">
                                                            Enter your email address or phone number to recover
                                                            your password.
                                                        </h5>
                                                    </div>

                                                    <div>
                                                        <Form>
                                                            <div>
                                                                {showEmail ? (
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                        <Form.Label className="text">Email</Form.Label>
                                                                        <Form.Control type="text" placeholder="name@example.com"
                                                                            {...register("email", {
                                                                                required: "Email is required",
                                                                                pattern: {
                                                                                    value: RegxExpression.email,
                                                                                    message: "Invalid email address",
                                                                                },
                                                                            })}
                                                                            className={classNames("", {
                                                                                "is-invalid": errors?.email,
                                                                                "is-valid": getValues("email"),
                                                                            })} />
                                                                    </Form.Group>
                                                                ) : (
                                                                    <>
                                                                        <Form.Label className="text">Phone</Form.Label>
                                                                        <PhoneInput
                                                                            country={"in"}
                                                                            className={classNames("mb-3", {
                                                                                "is-invalid": errors?.email_or_contact,
                                                                            })}
                                                                        // value={this.state.phone}
                                                                        // onChange={phone => this.setState({ phone })}
                                                                        />
                                                                    </>
                                                                )}
                                                                <p onClick={toggleInput} style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }} className="text-end mb-0">
                                                                    using {showEmail ? 'phone number' : 'email'}
                                                                </p>
                                                            </div>

                                                            {!otpLoader && (
                                                                <ButtonCommon
                                                                    text="Send Password reset link"
                                                                    className="loginbtn"
                                                                    onClick={handleSendOtp}
                                                                />
                                                            )}


                                                            <EmailOtpModal
                                                                show={modalShow3}
                                                                seconds={seconds}
                                                                otpErr={otpErr}
                                                                setSeconds={setSeconds}
                                                                verifyOTP={verifyOTP}
                                                                handleSendOtp={() => handleSendOtp("email", "resend")}
                                                                onHide={() => {
                                                                    setModalShow3(false);
                                                                    setOtpErr("");
                                                                }}
                                                            />



                                                            <div className="mt-2">

                                                                <p className="midtext colrrr" onClick={() => { setShowLogin(true); setShowForgetPassword(false) }}>
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowLeft}
                                                                        className="me-2"
                                                                    />
                                                                    Back to Previous Page
                                                                </p>

                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    {showCheckYourEmail && <CheckEmail />}
                    {showSetNewPassword && <SetNewPassword email={getValues('email')} />}
                    {showLogin && <Login />}
                </Container>
            </section>
        </>
    );
}

export default ForgotPassword;
