import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./LatestViews.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import AddToCard from "../AddToCard/AddToCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { latestPost } from "../../../utils/apis/common/Common";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";


const LatestViews = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const { sellerId, IMG_URL } = useContext(Context);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const latest = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Women Clothing & Fashion",
            price1: "₹3450.00",
            price2: "₹3450.00",

        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Women Clothing & Fashion",
            price1: "₹3450.00",
            price2: "₹3450.00",

        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Women Clothing & Fashion",
            price1: "₹3450.00",
            price2: "₹3450.00",

        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Women Clothing & Fashion",
            price1: "₹3450.00",
            price2: "₹3450.00",

        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Women Clothing & Fashion",
            price1: "₹3450.00",
            price2: "₹3450.00",

        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Women Clothing & Fashion",
            price1: "₹3450.00",
            price2: "₹3450.00",

        },
    ];

    const cookArr = Cookies.get("ProductIds")


    const [latestData, setLatestdata] = useState([]);
    const getLatesview = async () => {
        const res = await latestPost(sellerId, cookArr);
        if (res?.success) {
            setLatestdata(res?.data);
        }
    }

    useEffect(() => {
        getLatesview();
    }, [])
    return (
        <>
            <section className="LatestViews">
                <Container>
                    <div className="main">
                        <h1 className="title">Latest Viewed Products</h1>
                        <div className="silder-btn">
                            <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                            </div>
                            <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                            </div>
                        </div>
                    </div>
                    <Swiper
                        className="mySwiper"
                        pagination={true}
                        // modules={[Autoplay]}
                        // autoplay={{
                        //     delay: 2000,
                        //     disableOnInteraction: false,
                        // }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            380: {
                                slidesPerView: 2,
                            },
                            485: {
                                slidesPerView: 2,
                            },
                            575: {
                                slidesPerView: 2,
                            },

                            768: {
                                slidesPerView: 3,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 4,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1440: {
                                slidesPerView: 5,
                            },
                            2000: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {latestData?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <AddToCard
                                    image={IMG_URL + item.thumbnail}
                                    title={item.product_title}
                                    price1={item.price1}
                                    price2={item.selling_price}
                                    productId={item?.id}
                                />

                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </section>
        </>
    );
};

export default LatestViews;
