import React from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleRight,
  faBars,
  faCrown,
  faDownload,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./MyWallet.css";
const MyWallet = () => {
  return (
    <>
    <Container>
      <section className="MyWallet">
        <Container>
          <h1 className="title">My Wallet</h1>
          <Row>
            <Col xxl={4}>
              <div className="upload">
                <FontAwesomeIcon icon={faDownload} className="download" />
                <p className="title">Remaining Uploads</p>
                <p className="title">576</p>
              </div>
            </Col>
            <Col xxl={4}>
              <div className="new-product">
                <div className="add">
                  <FontAwesomeIcon icon={faPlus} className="plus" />
                </div>
                <p className="text">Add New product</p>
              </div>
            </Col>
            <div className="Wallet Recharge">
            <h1 className="title">Wallet Recharge History</h1>
              <table class="table">
                <thead>
                  <tr>
                    <th className="sub-text">#</th>
                    <th className="sub-text">Date</th>
                    <th className="sub-text">Amount</th>

                    <th className="sub-text">Payment method </th>
                    <th className="sub-text">Status</th>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td className="date">30-05-2024</td>
                    <td className="amount">$4.500</td>
                    <td className="sub-text">Club Point Convert</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td className="date">30-05-2024</td>
                    <td className="amount">$4.500</td>
                    <td className="sub-text">Club Point Convert</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td className="date">30-05-2024</td>
                    <td className="amount">$4.500</td>
                    <td className="sub-text">Club Point Convert</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td className="date">30-05-2024</td>
                    <td className="amount">$4.500</td>
                    <td className="sub-text">Club Point Convert</td>
                    <td>N/A</td>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
              <Pagination>
                    {/* <Pagination.First /> */}
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                   
                   
                   <Pagination.Next />
                    {/* <Pagination.Last /> */}
                </Pagination>
            </div>
           
          </Row>
        </Container>
      </section>
      </Container>
    </>
  );
};

export default MyWallet;
