import React, { useContext, useEffect, useState } from "react";
import "./ManageProfile.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import AddressPopUp from "../../CommonComponent/PopUp/AddressPopUp/AddressPopUp";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserEdit, UserGet } from "../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../utils/context";
import { RegxExpression } from "../../../utils/common";
import classNames from "classnames";
import {
    DeleteAddress,
    EditAddress,
    getAddress,
} from "../../../utils/apis/orderProcess/orderProcess";

function ManageProfile() {
    const [show, setShow] = useState(false);
    const [editStatus, setEditStatus] = useState(true);
    const [showInnerPage, setShowInnerPage] = useState(false);
    const [address, setAddress] = useState([]);
    const [addressId, setAddressId] = useState("");
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const navigate = useNavigate();
    const { signin } = useContext(Context);
    const [userData, setUserData] = useState();

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        defaultValues: {
            address_type: "Home",
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async (data) => {
        const res = await UserEdit(data);
        if (res?.success) {
            setTimeout(() => {
                setEditStatus(true);
            }, 3000);
        } else {
            if (res?.message?.both) {
                setError("email", { message: res?.message?.both });
                setError("contact_no", { message: "" });
            }
            if (res?.message?.email) {
                setError("email", { message: res?.message?.email });
            }
            if (res?.message?.contact_no) {
                setError("contact_no", { message: res?.message?.contact_no });
            }
        }
    };



    const [values, setValues] = useState({});

    const handleEdit = (data) => {
        setValue("address_type", data.address_type);
        setValue("f_name", data.f_name);
        setValue("l_name", data.l_name);
        setValue("address", data.address);
        setValue("pincode", data.pincode.name);
        setValue("city", data.city.name);
        setValue("state", data.state.name);
        setValue("landmark", data.landmark);
        setCities(data.city.name);
        setStates(data.state.name);
        setCountries(data.country.name);
        setAddressId(data.id);
        setShowInnerPage(true);
        setValues(data);
        setShow(true);
    };

    const getUser = async () => {
        const res = await UserGet();
        if (res?.success) {
            setUserData(res.data);
            reset(res?.data);
        }
    };

    const AddressGet = async () => {
        const res = await getAddress();
        if (res?.success) {
            setAddress(res?.data);
            console.log(res?.data, "address");
        }
    };

    const handleDelete = async (id) => {
        const res = await DeleteAddress(id);
        if (res?.success) {
            AddressGet();
        }
    };
    const handleupdate = async (id, data) => {
        const res = await EditAddress(id, data);
        if (res?.success) {
            AddressGet();
        }
    };

    useEffect(() => {
        if (!signin) {
            navigate("/");
        }
    }, [signin]);

    useEffect(() => {
        getUser();
        AddressGet();
    }, []);




    return (
        <>
            <Container>
                <section className="manageprof_section">
                    <div className="manageprof_main">
                        <h4 className="title mb-4">Manage Profile</h4>
                        <div className="formouter_div mb-3">
                            <h5 className="midtext">Basic Info</h5>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Name
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Paul k Jeneson"
                                            disabled={editStatus}
                                            {...register("first_name", {
                                                required: "First name required",
                                            })}
                                            className={classNames({
                                                "is-invalid": errors?.first_name && !editStatus,
                                                "is-valid": getValues("first_name") && !editStatus,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Last Name
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            disabled={editStatus}
                                            placeholder="Enter Last Name"
                                            {...register("last_name", {
                                                required: "Last name required",
                                            })}
                                            className={classNames({
                                                "is-invalid": errors?.last_name && !editStatus,
                                                "is-valid": getValues("last_name") && !editStatus,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Phone
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            disabled={editStatus}
                                            placeholder="Enter Mobile Number"
                                            {...register("contact_no", {
                                                required: "Field is required",
                                                minLength: {
                                                    value: 10,
                                                    message: "Number should be at least 10 characters",
                                                },
                                            })}
                                            className={classNames({
                                                "is-invalid": errors?.contact_no && !editStatus,
                                                "is-valid": getValues("contact_no") && !editStatus,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.phone.test(event.key)) {
                                                    if (event.key !== "Backspace") {
                                                        event.preventDefault();
                                                    }
                                                }
                                            }}
                                            maxLength={10}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Email
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            disabled={editStatus}
                                            placeholder="Enter Email"
                                            {...register("email", {
                                                required: "Email is required",
                                                pattern: {
                                                    value: RegxExpression.email,
                                                    message: "Invalid email address",
                                                },
                                            })}
                                            className={classNames({
                                                "is-invalid": errors?.email && !editStatus,
                                                "is-valid": getValues("email") && !editStatus,
                                            })}
                                        />
                                    </Col>
                                </Form.Group>
                                <div className="text-end">
                                    <ButtonCommon
                                        buttontype="button"
                                        text="Edit"
                                        className="editbtn me-2"
                                        onClick={() => setEditStatus(false)}
                                    >
                                        Edit
                                    </ButtonCommon>
                                    <ButtonCommon text="Update Profile" className="updatebtn" />
                                </div>
                            </Form>
                        </div>
                        <div className="formouter_div mb-3">
                            <h5 className="midtext mb-4">Address</h5>
                            <div className="manageprof_section">
                                {address && address.length > 0 ? (
                                    <div className="Address-manage">
                                        {address.map((value) => (
                                            <div key={value.id} className="ro_divvv mb-3">
                                                <div className="addres_div">
                                                    <Row>
                                                        <Col xxl={2} xl={2} lg={3} md={4}>
                                                            <div className="lists">
                                                                <p className="text mb-1">Name:</p>
                                                                <p className="text mb-1">Address:</p>
                                                                <p className="text mb-1">Postal Code:</p>
                                                                <p className="text mb-1">City:</p>
                                                                <p className="text mb-1">State:</p>
                                                                <p className="text mb-1">LandMark:</p>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={10} xl={10} lg={9} md={8}>
                                                            <div className="lists">
                                                                <p className="addtext text mb-1">
                                                                    {value?.f_name} {value?.l_name}
                                                                </p>
                                                                <p className="addtext text mb-1">
                                                                    {value?.address}
                                                                </p>
                                                                <p className="addtext text mb-1">
                                                                    {value?.pincode?.name}
                                                                </p>
                                                                <p className="addtext text mb-1">
                                                                    {value?.city?.name}
                                                                </p>
                                                                <p className="addtext text mb-1">
                                                                    {value?.state?.name}
                                                                </p>
                                                                <p className="addtext text mb-1">
                                                                    {value?.landmark}
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div>
                                                    <ButtonCommon
                                                        buttontype="button"
                                                        text="Edit"
                                                        className="Edit"
                                                        onClick={() => {
                                                            handleEdit(value);
                                                            // handleupdate(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </ButtonCommon>
                                                    <ButtonCommon
                                                        buttontype="button"
                                                        text="Delete"
                                                        className="Delete"
                                                        onClick={() => handleDelete(value.id)}
                                                    >
                                                        Delete
                                                    </ButtonCommon>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>

                            <div className="addnewadd mt-4">
                                <div className="addbtn" onClick={handleShow}>
                                    <p className="title mb-0">+</p>
                                    <p className="midtext mb-0"> Add New Address</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            <AddressPopUp
                show={show}
                handleClose={handleClose}
                addressId={addressId}
                cities={cities}
                states={states}
                countries={countries}
                showInnerPage={showInnerPage}
                AddressGet={AddressGet}
                setShowInnerPage={setShowInnerPage}
                setAddressId={setAddressId}
                setStates={setStates}
                setCities={setCities}
                setCountries={setCountries}
                reset={reset}
                getValuesprop={getValues}
                values={values}
            />
        </>
    );
}

export default ManageProfile;