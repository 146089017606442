import React, { useContext, useEffect, useState } from "react";
import "./CategoryFilter.css";
import { Accordion, Col, Container, Form, Offcanvas, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Product from "../CommonComponent/Product/Product";
import { faChevronLeft, faFilter, faStar } from "@fortawesome/free-solid-svg-icons";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import AddToCard from "../CommonComponent/AddToCard/AddToCard";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { MaxPrice, Products } from "../../utils/apis/Product/Product";
import { Context } from "../../utils/context";
function CategoryFilter() {
    const { IMG_URL, sellerId, type } = useContext(Context);
    const { id } = useParams();
    console.log("iddd" + id);

    const [value, setValue] = useState({ min: 0, max: 10 });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "All Categories", link: "/ecommerce2/all-categories" },
        { text: "Computer & Accessories", link: "#" },
        ,
    ]);

    const bradd = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllSellers/branded1.webp",
            name: "Britons",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllSellers/branded2.webp",
            name: "Britons",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllSellers/branded3.webp",
            name: "Britons",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllSellers/branded4.webp",
            name: "Britons",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/AllSellers/branded3.webp",
            name: "Britons",
        },
    ];

    const sale = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag,",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag,",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d7.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag,",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d7.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
    ];


    const [products, setProduct] = useState([]);
    const getproducts = async () => {
        const res = await Products("", "", id, sellerId, value.min, value.max);
        if (res?.success) {
            setProduct(res?.data);
            console.log(res, 'jkhfgdiuhiu');
        }
    }

    const [price, setPrice] = useState([]);
    const getPrice = async () => {
        const res = await MaxPrice(sellerId);
        if (res?.success) {
            setPrice(res?.data);
            console.log(res?.data, "category");
        }
    };

    const [values, setValues] = useState([price?.minPrice || 0, price?.maxPrice || 100]);

    const handleChange = (newValues) => {
        setValues(newValues);
        setPrice({ minPrice: newValues[0], maxPrice: newValues[1] });
    };

    useEffect(() => {
        getproducts();

    }, [value])



    useEffect(() => {
        getPrice();
    }, []);
    return (
        <>
            <section className="categoryfiletr_section">
                <Container>
                    <div className="categoryfiletr_div">
                        <div className="my-4">
                            <Row>
                                <Col xxl={3} xl={3} md={12} sm={12}>
                                    <div className="accordian_section desktopview_accordian">
                                        <Accordion defaultActiveKey="0" className="mb-4">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Categories</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="">
                                                        {/* <Form>
                                                                        <Form.Check 
                                                                            type="checkbox"
                                                                            id="faasyn"
                                                                            label="Women Clothing & Fashion"
                                                                        />
                                                                    </Form> */}

                                                        <Link to={"/ecommerce2/all-categories"}><p className="text">
                                                            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />{" "}
                                                            All Categories
                                                        </p></Link>
                                                        {/* <p className="text">
                                                            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />{" "}
                                                            Computer & Accessories
                                                        </p>
                                                        <ul>
                                                            <li className="sub-text mb-1">Laptop & Accessories</li>
                                                            <li className="sub-text  mb-1">Gaming pc</li>
                                                            <li className="sub-text  mb-1">Official Equipment</li>
                                                            <li className="sub-text  mb-1">Components & Peripherals</li>
                                                        </ul> */}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <div className="pricerange_div mb-4">
                                            <p className="midtext mb-4">Price Range</p>
                                            <div>
                                                <InputRange
                                                    draggableTrack
                                                    maxValue={price?.maxPrice}
                                                    minValue={price?.minPrice}
                                                    defaultValue={values}
                                                    onInput={handleChange}
                                                    formatLabel={(value) => `${value} Rs`}
                                                    value={value}
                                                    onChange={setValue}
                                                    onChangeComplete={(value) => console.log(value)}
                                                />
                                            </div>
                                        </div>

                                        {/* <Accordion defaultActiveKey="1" className="mb-4">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="midtext">FIlter by Color</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="">
                                                        <Row>
                                                            {bradd.map((brand, index) => (
                                                                <Col xxl={3} xl={3} lg={4} md={6} className="p-1">
                                                                    <div className="color_main_div">
                                                                        <div className="color_main"></div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion> */}
                                    </div>
                                </Col>
                                <Col xxl={9} xl={9} md={12} sm={12}>
                                    <div>
                                        <Breadcrumbs items={breadcrumbItems} />
                                    </div>
                                    <div className=" allproductmain_divv mb-4">
                                        <h3 className="title">All Products</h3>
                                        {/* <Form className="d-flex align-items-center">
                                            <FontAwesomeIcon
                                                icon={faFilter}
                                                onClick={handleShow}
                                                className="fontfiltericn me-3"
                                            />
                                            <Form.Select aria-label="Default select example">
                                                <option>Sort by</option>
                                                <option value="1">Newest</option>
                                                <option value="2">Oldest</option>
                                                <option value="3">Price Low to High</option>
                                                <option value="3">Price High to Low</option>
                                            </Form.Select>
                                        </Form> */}
                                    </div>

                                    <Row>
                                        {products?.data?.map((item, index) => (
                                            <Col
                                                xxl={3}
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={6}
                                                xs={12}
                                                key={index}
                                                className="p-0"
                                            >

                                                <AddToCard
                                                    image={IMG_URL + item?.thumbnail}
                                                    productId={item?.id}
                                                    title={item?.product_title}
                                                    price1={item?.mrp}
                                                    price2={item?.selling_price}
                                                />

                                            </Col>
                                        ))}
                                    </Row>
                                    <div>
                                        <Row className="row-cols-xxl-4 row-cols-xl-3 row-cols-lg-4 row-cols-md-3 row-cols-2">
                                            <Col>
                                                <div className="sellingprods">{/* <AddToCard /> */}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>

            <div>
                <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filter</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="accordian_section  mobileview_accordian">
                            <Accordion defaultActiveKey="0" className="mb-4">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Categories</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="">
                                            {/* <Form>
                                                                        <Form.Check 
                                                                            type="checkbox"
                                                                            id="faasyn"
                                                                            label="Women Clothing & Fashion"
                                                                        />
                                                                    </Form> */}

                                            <Link to={"/ecommerce2/all-categories"}><p className="text">
                                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />{" "}
                                                All Categories
                                            </p></Link>
                                            {/* <p className="text">
                                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" /> Computer &
                                                Accessories
                                            </p>
                                            <ul>
                                                <li className="sub-text mb-1">Laptop & Accessories</li>
                                                <li className="sub-text  mb-1">Gaming pc</li>
                                                <li className="sub-text  mb-1">Official Equipment</li>
                                                <li className="sub-text  mb-1">Components & Peripherals</li>
                                            </ul> */}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className="pricerange_div mb-4">
                                <p className="midtext mb-4">Price Range</p>
                                <div>
                                    <InputRange
                                        draggableTrack
                                        maxValue={price?.maxPrice}
                                        minValue={price?.minPrice}

                                        defaultValue={values}
                                        onInput={handleChange}
                                        formatLabel={(value) => `${value} Rs`}
                                        value={value}
                                        onChange={setValue}
                                        onChangeComplete={(value) => console.log(value)}
                                    />
                                </div>
                            </div>

                            {/* <Accordion defaultActiveKey="1" className="mb-4">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="midtext">FIlter by Color</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="">
                                            <Row>
                                                {bradd.map((brand, index) => (
                                                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} className="p-1">
                                                        <div className="color_main_div">
                                                            <div className="color_main"></div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> */}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
}

export default CategoryFilter;
