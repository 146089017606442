import React, { useState } from "react";
import "./Compare.css";
import {Col, Container, Row} from "react-bootstrap";
import ButtonCommon from "../CommonComponent/ButtonCommon/ButtonCommon";
import Addtocartmodal from "../CommonComponent/PopUp/Addtocartmodal/Addtocartmodal";

const Compare = () => {
    const [modalShow, setModalShow] = useState(false);
    const compare =[
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/FlashSale/sale7.jpg",
            currentprice: "₹3450.00",
            saleprice: "₹5000.0",
            type:"Jessica Simpson Womens Cropped Crewneck Blouse",
            category:"Women Clothing & Fashion",
            brand:"Calvin Klein",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/FlashSale/sale4.png",
            currentprice: "₹3450.00",
            saleprice: "₹5000.0",
            type:"Jessica Simpson Womens Cropped Crewneck Blouse",
            category:"Women Clothing & Fashion",
            brand:"Calvin Klein",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/FlashSale/sale2.png",
            currentprice: "₹3450.00",
            saleprice: "₹5000.0",
            type:"Jessica Simpson Womens Cropped Crewneck Blouse",
            category:"Women Clothing & Fashion",
            brand:"Calvin Klein",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/FlashSale/sale6.png",
            currentprice: "₹3450.00",
            saleprice: "₹5000.0",
            type:"Jessica Simpson Womens Cropped Crewneck Blouse",
            category:"Women Clothing & Fashion",
            brand:"Calvin Klein",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            currentprice: "₹3450.00",
            saleprice: "₹5000.0",
            type:"Jessica Simpson Womens Cropped Crewneck Blouse",
            category:"Women Clothing & Fashion",
            brand:"Calvin Klein",
        },
    ];
    return (
        <>
            <section className="Compare">
                <Container>
                    <div className="Main">
                        <h1 className="title">Compare Products</h1>
                        <ButtonCommon text="Reset Compare List" className="reset" />
                    </div>
                    <Row>
                    {compare.map((item, index) => (
                        <Col xxl={3} xl={3} lg={4} md={6} key={index}>
                            <div className="compare-product">
                                <div className="Type-Product">
                                    <p className="sub-text">Name</p>
                                    <p className="sub-text type">{item.type}</p>
                                </div>
                                <div className="image-part">
                                    <p className="sub-text">Name</p>
                                    <div className="product">
                                        <img
                                            src={item.image}
                                            className="store"
                                        />
                                    </div>
                                </div>
                                <div className="price">
                                    <p className="sub-text">price</p>
                                    <div className="current-price">
                                        <p className="price sale">{item.saleprice}</p>
                                        <p className="price">{item.currentprice}</p>
                                    </div>
                                </div>
                                <div className="categories">
                                    <p className="sub-text">Category</p>
                                    <p className="sub-text type">{item.category}</p>
                                </div>
                                <div className="Brand">
                                    <p className="sub-text">Brand</p>
                                    <p className="sub-text type">{item.brand}</p>
                                </div>
                                <div className="cart" onClick={() => setModalShow(true)}>
                                    <ButtonCommon className="add-cart" text="Add To Cart" />
                                </div>
                            </div>
                        </Col>
                    ))}
                    </Row>
                </Container>
                <Addtocartmodal show={modalShow}
                onHide={() => setModalShow(false)} />
            </section>
        </>
    );
};

export default Compare;
