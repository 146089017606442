import React, { useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Autoplay } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddToCard from "../../CommonComponent/AddToCard/AddToCard";
import "./BestSelling.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { GetBestSelling } from "../../../utils/apis/master/Master";
const BestSelling = () => {
    const { IMG_URL, sellerId, type } = useContext(Context);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const FeaturedProducts = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag,",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d5.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d7.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
    ];

    const [bestselling, setBestSelling] = useState([]);
    const getbestselling = async () => {
        const res = await GetBestSelling(sellerId);
        if (res?.success) {
            setBestSelling(res?.data);
        }
    }


    useEffect(() => {
        getbestselling();
    }, []);
    return (
        <>
            <section className="FeaturedProducts">
                <Container>
                    <div className="main">
                        <h1 className="title">Best Selling</h1>
                        <div className="silder-btn">
                            <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                            </div>
                            <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                            </div>
                        </div>
                    </div>
                    <Swiper
                        className="mySwiper"
                        pagination={true}
                        modules={[Autoplay]}
                        // autoplay={{
                        //     delay: 2000,
                        //     disableOnInteraction: false,
                        // }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            380: {
                                slidesPerView: 2,
                            },
                            485: {
                                slidesPerView: 2,
                            },
                            575: {
                                slidesPerView: 2,
                            },

                            768: {
                                slidesPerView: 3,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 4,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1440: {
                                slidesPerView: 5,
                            },
                            2000: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {bestselling.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Link to={`/ecommerce2/product-details/${item?.id}`}>
                                    <AddToCard
                                        image={IMG_URL + item?.thumbnail}
                                        productId={item?.id}
                                        title={item?.product_title}
                                        price1={item?.mrp}
                                        price2={item?.selling_price}
                                    />
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </section>
        </>
    );
};

export default BestSelling;
