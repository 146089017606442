import React, { useContext } from 'react'
import "./Cartsuccessfully.css";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../../../../utils/context';
const Cartsuccessfully = (props) => {
    const { triggercart } = useContext(Context);
    const navigate = useNavigate();
    const procedddclick = () => {
        navigate("/ecommerce2/order-data");

    }

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="cartsuccesfully"
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className='cartSuccessMain'>
                        <div className='text-center'>
                            <FontAwesomeIcon icon={faCartShopping} className='cartIcon' />

                            <div className='successhead'>
                                <h3>Item added to your cart!</h3>
                            </div>
                            <div className="content-main">
                                <Row className="mt-">
                                    <div className="buttonsectionmain">
                                        <Link to="/">
                                            <Button type='button' className="shoppingbtn">Back to Shopping</Button>
                                        </Link>
                                        <Link to={"/ecommerce2/order-data"}>
                                            <Button type='button' className="checkoutbtn">Proceed to Checkout</Button>

                                        </Link>


                                    </div>
                                </Row>
                            </div>
                        </div>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Cartsuccessfully