import React, { useContext, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import "./DashBoardTab.css";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";

import {
    faAngleRight,
    faBox,
    faCartShopping,
    faCrown,
    faDollarSign,
    faHeart,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WalletPopUp from "../../CommonComponent/PopUp/WalletPopUp/WalletPopUp";
import AddressPopUp from "../../CommonComponent/PopUp/AddressPopUp/AddressPopUp";
import { Context } from "../../../utils/context";
const DashBoardTab = ({ orders }) => {

    console.log(orders, "orderdata");
    const { cartCount, wishlistCount } = useContext(Context);
    const [modalShow, setModalShow] = React.useState(false);
    const [orderamount, setOrderamount] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showmodal, setShowmodal] = useState(false);

    const handleClosemodal = () => setShowmodal(false);
    const handleShowmodal = () => setShowmodal(true);

    const Wishlist = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Pakistani Women's Readymade Dress",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Pakistani Women's Readymade Dress",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Pakistani Women's Readymade Dress",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Pakistani Women's Readymade Dress",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Wishlist/whislist.webp",
            title: "Pakistani Women's Readymade Dress",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
    ];
    return (
        <>
            <section className="DashBoardTab">
                <Container>
                    <Row>
                        <Col xxl={4}>
                            <div className="expenditure">
                                <div className="expenditure-text">
                                    <div className="rupee">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </div>
                                    <div className="content">
                                        <p className="sub-text">Total Expenditure</p>
                                        <h1 className="title">{orders[0]?.amount}</h1>
                                    </div>
                                </div>
                                <div className="history">
                                    <p className="sub-text">View Order History </p>
                                    <FontAwesomeIcon icon={faAngleRight} className="arrow" />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <div className="expenditure two">
                                <div className="expenditure-text">
                                    <div className="rupee">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </div>
                                    <div className="content">
                                        <p className="sub-text">Total Club Points</p>
                                        <h1 className="title">46475.55</h1>
                                    </div>
                                </div>
                                <div className="history">
                                    <p className="sub-text">Covert Club Points</p>
                                    <FontAwesomeIcon icon={faAngleRight} className="arrow" />
                                </div>
                            </div> */}

                    <Row className="mt-3">
                        <Col xxl={4} xl={4} lg={4} md={6}>
                            <div className="product-info">
                                <div className="cart">
                                    <div className="cart-image">
                                        <FontAwesomeIcon icon={faCartShopping} />
                                    </div>
                                    <div className="content">
                                        <h1 className="title">{cartCount}</h1>
                                        <p className="sub-text">Products in Cart</p>
                                    </div>
                                </div>
                                <div className="cart">
                                    <div className="cart-image wish">
                                        <FontAwesomeIcon icon={faHeart} />
                                    </div>
                                    <div className="content">
                                        <h1 className="title">{wishlistCount}</h1>
                                        <p className="sub-text">Products in Wishlist</p>
                                    </div>
                                </div>
                                <div className="cart ">
                                    <div className="cart-image order">
                                        <FontAwesomeIcon icon={faBox} />
                                    </div>
                                    <div className="content">
                                        <h1 className="title">
                                            
                                        </h1>
                                        <p className="sub-text">Total Products Ordered</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col xxl={4} xl={4} lg={4} md={6} >
                            <div className="Purchased-Package">
                                <h1 className="title">Purchased Package</h1>
                                <div className="package">
                                    <FontAwesomeIcon icon={faCrown} />
                                </div>
                                <p className="text">Current Package: Premium</p>
                                <div className="premium">
                                    <p className="sub-text">Product Upload</p>
                                    <p className="sub-text times">300 times</p>
                                </div>
                                <div className="premium">
                                    <p className="sub-text">Product Upload Remains</p>
                                    <p className="sub-text times">576 times</p>
                                </div>
                                <div className="upgrade">
                                    <ButtonCommon className="update" text="Upgrade Package" />
                                </div>
                            </div>
                        </Col> */}
                        {/* <Col xxl={4} xl={4} lg={4} md={6}>
                            <div className="Shipping-Address">
                                <p className="title">Default Shipping Address</p>
                                <p className="sub-text">Karve Nagar pune,</p>
                                <p className="sub-text">Karve Nagar pune,</p>
                                <p className="sub-text">Karve Nagar pune,</p>
                                <p className="sub-text">Karve Nagar pune,</p>
                                <div className="address-update" onClick={handleShowmodal}>
                                    <FontAwesomeIcon icon={faPlus} className="plus" />
                                    <ButtonCommon className="address" text="Add New Address" />
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                    <div className="main-whislist">
                        {/* <div className="heading">
                            <h1 className="title">Wishlist</h1>
                            
                            <p className="sub-text view" eventKey="fifth">View all</p>
                        </div> */}

                        {/* <Row className="row-cols-xxl-5 row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-2 gutters-16   p-0">
                            {Wishlist.map((item, index) => (
                                <div className="Wishlist p-0">
                                    <div className="Card-product">
                                        <div className="sec-image">
                                            <div className="image-product">
                                                <img src={item.image} className="store" />
                                            </div>
                                            <div className="add-cart-btn">
                                                <div className="cart">
                                                    <p className="sub-text cart-text">Add To Cart</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="buttons-card">
                                            <div className="wishlist">
                                                <FontAwesomeIcon icon={faTrash} className="icon" />
                                            </div>
                                        </div>
                                        <h1 className="sub-text">{item.title}</h1>

                                        <div className="price">
                                            <p className="text main1">{item.price1}</p>
                                            <p className="text main2">{item.price2}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Row> */}
                    </div>
                </Container>
            </section>
            <AddressPopUp show={showmodal} handleClose={handleClosemodal} />
        </>
    );
};

export default DashBoardTab;
