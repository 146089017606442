import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import "../Login/Login.css"
import PasswordChecklist from "react-password-checklist";
// import ResetPassWord from "../ResetPassWord/ResetPassWord";

import { resetPassWord } from "../../../utils/apis/common/Common";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import Success_Modalss from "../../CommonComponent/PopUp/Success_Modal/Success_Modalss";

function SetNewPassword({ email }) {

  const [modalShow, setModalShow] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfPassword, setShowConfPassword] = useState(false);

  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglessPasswordVisibility = () => {
    setShowConfPassword(!showconfPassword);
  };



  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    if (data.password === data.re_password) {

      data.email = email;
      const res = await resetPassWord(data);
      if (res?.success) {
        setModalShow(true);

        setTimeout(() => {
          setModalShow(false);
          navigate('/login');
        }, 3000);
      }
    } else {
      setError("password", { message: "Password does not match" });
    }
  }
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  return (
    <section className="login_section">
      <Container fluid>
        <Row className="">
          <Col xxl={6} xl={6} lg={6} md={12}>
            <div className="logincardiv">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assests/images/Login/cartimg.webp"
                }
                className="logincartimgdiv"
              />
            </div>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={12} className="mt-5">
            <div className="baggimg">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assests/images/Login/bagge.svg"
                }
                className="bagimg"
              />
            </div>
            <div className="">
              <h1 className="title">Welcome Back !</h1>
              <h5 className="sub-text">Login to your account.</h5>
            </div>
            <div className="LogIn">
              <div className="row bg-color me-0 ms-0">
                <div className="col-lg-7 p-0">
                  <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                    <div className="log_in_form">
                      <Form>
                        <Form.Label className="text">Password</Form.Label>
                        <Form.Group className="mb-3" controlId="">
                          <div className="password-field-holder">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter Password*"
                              name="password"
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must be at least 8 characters long",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                  message:
                                    "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                },
                              })}
                              onChange={(e) => setPassword(e.target.value)}
                            />

                            <div className="eye-icon-holder ">
                              <FontAwesomeIcon
                                icon={showPassword ? "eye" : "eye-slash"}
                                className="eye-icon"
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </div>
                          </div>
                          {errors?.password && (
                            <sup className="text-danger">
                              {errors?.password?.message}
                            </sup>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Label className="text">Confirm Password</Form.Label>
                          <div className="password-field-holder">
                            <Form.Control
                              type={showPasswordOne ? "text" : "password"}
                              placeholder="Re-Enter Password*"
                              name="re_password"
                              {...register("re_password", {
                                required: "Re-Password is required",
                              })}
                              onChange={(e) =>
                                setPasswordAgain(e.target.value)
                              }
                            />
                            <div className="eye-icon-holder ">
                              <FontAwesomeIcon
                                icon={showPasswordOne ? "eye-slash" : "eye"}
                                className="eye-icon"
                                onClick={() => setShowPasswordOne(!showPasswordOne)}
                              />
                            </div>
                          </div>
                          {errors?.re_password && (
                            <sup className="text-danger">
                              {errors?.re_password?.message}
                            </sup>
                          )}
                        </Form.Group>
                        {password && (
                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "match",
                            ]}
                            minLength={8}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => { }}
                            style={{
                              fontSize: "10px",
                              padding: "4px",
                              margin: "0",
                            }}
                          />
                        )}


                        <div className="btn-holder">
                          <ButtonCommon
                            onClick={handleSubmit(onSubmit)}
                            className="loginbtn"
                            text=" Reset Password"

                          />
                        </div>
                      </Form>

                      <div className="create_account">
                        {/* <div className="login_text">
                        <p>
                          Didn't receive the email?
                          <span className="login_text">Click to resend</span>
                        </p>
                      </div> */}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </Col>

        </Row>


      </Container>

      <Success_Modalss
        show={modalShow}
        onHide={() => setModalShow(false)}
        texts=" Your password has been reset successfully!"
      />
    </section>
  );
}

export default SetNewPassword;
