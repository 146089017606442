import React, { useState, useMemo, useContext, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import countryList from "react-select-country-list";
import "./Header.css";
import { Badge, Button, Col, Container, Form, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Cookies from "js-cookie";

import {
  faBars,
  faBell,
  faCartShopping,
  faChevronRight,
  faCloudArrowDown,
  faComments,
  faHeart,
  faHouse,
  faListCheck,
  faMagnifyingGlass,
  faPersonDress,
  faPowerOff,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import SearchBar from "../CommonComponent/SearchBar/SearchBar";
import { Category } from "../../utils/apis/Product/Product";
import { Context } from "../../utils/context";
import { GetAppSetup, GetWebHeader } from "../../utils/apis/master/Master";
import { Notifications } from "../../utils/apis/orderProcess/orderProcess";
import Login from "../CommonComponent/PopUp/Login/Login";

const languages = [
  {
    code: "en",
    name: "English",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/en.png",
  },
  {
    code: "bd",
    name: "Bangla",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/bd.png",
  },
  {
    code: "sa",
    name: "Arabic",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/sa.png",
  },
  {
    code: "fr",
    name: "French",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/fr.png",
  },
  {
    code: "in",
    name: "Hindi",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/in.png",
  },
  {
    code: "vn",
    name: "Vietnamese",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/vn.png",
  },
  {
    code: "be",
    name: "Belgium",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/be.png",
  },
  {
    code: "es",
    name: "Español",
    flag:
      "https://demo.activeitzone.com/ecommerce/public/assets/img/flags/es.png",
  },
];

const orders = [
  {
    orderNo: "20240602-16185586",
    date: "June 2 2024, 4:18 pm",
  },
  {
    orderNo: "20240602-16185586",
    date: "June 2 2024, 4:18 pm",
  },
  {
    orderNo: "20240602-16185586",
    date: "June 2 2024, 4:18 pm",
  },
  {
    orderNo: "20240602-16185586",
    date: "June 2 2024, 4:18 pm",
  },
];
const stateCurrencies = {
  "United Kingdom": "GBP",
  Yukon: "CAD",
  Washington: "USD",
  Italy: "EUR",
};

function Header() {
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const {
    IMG_URL,
    signin,
    setSignin,
    sellerId,
    setUsertype,
    cartCount,
    wishlistCount,
    cartLengthFromCookies,
    type,
  } = useContext(Context);
  console.log(wishlistCount, "wishhhh");
  console.log(cartCount, "countttt");
  const navigate = useNavigate();
  const changeHandler = (value) => {
    setValue(value);
  };
  const [selected, setSelected] = useState("");
  const [modalloginShow, setModalloginShow] = React.useState(false);
  const customLabels = {
    en: "🇺🇸 English",
    fr: "🇫🇷 French",
    de: "🇩🇪 German",
    it: "🇮🇹 Italian",
    es: "🇪🇸 Spanish",
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showqq, setShowqq] = useState(false);

  const handleMouseEnter = () => setShowqq(true);
  const handleMouseLeave = () => setShowqq(false);
  const topcat = [
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
    {
      name: "Party Dress",
    },
  ];

  // Submini-Header
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    closeDropdown();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const [selectedState, setSelectedState] = useState("");

  const handleStateChange = (state) => {
    setSelectedState(state);
  };
  const currencySymbols = {
    USD: "$",
    CAD: "C$",
    GBP: "£",
    EUR: "€",
  };

  const [isOpen, setIsOpen] = useState(false);

  const [categories, setCategory] = useState([]);

  const getCategories = async () => {
    const res = await Category(sellerId);
    if (res?.success) {
      setCategory(res?.data);
    }
  };

  const handleLogout = async () => {
    Cookies.remove("ecommerce2_security", { path: "/" });
    await setSignin(false);
    await setUsertype("");
    await navigate("/ecommerce2/login");
  };

  const [headers, setHeaders] = useState([]);
  const getHeader = async () => {
    const res = await GetWebHeader(sellerId);
    if (res?.success) {
      setHeaders(res?.data);
    }
  };

  const handlemodalshow = () => {
    setModalloginShow(true);
  };

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }
  };

  useEffect(() => {
    getCategories();
    getHeader();
    getAppSetup();
  }, []);

  // const { type } = useContext(Context);
  const [notification, setNotification] = useState([]);
  const getNotification = async () => {
    const res = await Notifications(sellerId, type);
    if (res?.success) {
      setNotification(res?.data);
      console.log(res?.data, "brasds");
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const handleChanges = (e) => {
    setSearchTerm(e.target.value);
    navigate(`/ecommerce2/searchresults/${searchTerm}`);
  };

  const handleSubmits = (e) => {
    e.preventDefault();
    navigate(`/ecommerce2/searchresults/${searchTerm}`);
  };

  return (
    <>
      <div className="Main-Header">
        {/* <li className="list-inline-item ml-3 mr-3 pr-3 pl-0 dropdown">
                    <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
                        <Dropdown.Toggle
                            className="dropdown-toggle no-arrow text-secondary fs-12"
                            id="notifications-dropdown"
                        >
                            <span className="">
                                <span className="position-relative d-inline-block" onClick={() => setIsOpen(!isOpen)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.668"
                                        height="16"
                                        viewBox="0 0 14.668 16"
                                    >
                                        <path
                                            id="_26._Notification"
                                            data-name="26. Notification"
                                            d="M8.333,16A3.34,3.34,0,0,0,11,14.667H5.666A3.34,3.34,0,0,0,8.333,16ZM15.06,9.78a2.457,2.457,0,0,1-.727-1.747V6a6,6,0,1,0-12,0V8.033A2.457,2.457,0,0,1,1.606,9.78,2.083,2.083,0,0,0,3.08,13.333H13.586A2.083,2.083,0,0,0,15.06,9.78Z"
                                            transform="translate(-0.999)"
                                            fill="#91919b"
                                        ></path>
                                    </svg>
                                </span>
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown-menu dropdown-menu-right dropdown-menu-lg py-0 rounded-0"
                            style={{display: isOpen ? "block" : "none"}}
                        >
                            <div className="p-3 bg-light border-bottom">
                                <h6 className="mb-0">Notifications</h6>
                            </div>
                            <div className="px-3 c-scrollbar-light overflow-auto" style={{maxHeight: "300px"}}>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="py-4 text-center fs-16">No notification found</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-center border-top">
                                <a
                                    href="https://demo.activeitzone.com/ecommerce/all-notifications"
                                    className="text-secondary fs-12 d-block py-2"
                                >
                                    View All Notifications
                                </a>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </li> */}
        <Container>
          <div className="search-header">
            <Row>
              <Col xxl={3} xl={3} lg={3}>
                <Link to="/">
                  <div className="logo">
                    <img src={IMG_URL + appSetup?.image} className="store" />
                  </div>
                </Link>
              </Col>
              <Col xxl={5} xl={5} lg={7}>
                <div className="search-bar">
                  <Form onSubmit={handleSubmits}>
                    <Form.Group
                      className=""
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        placeholder="I am shopping for.."
                        value={searchTerm} onChange={handleChanges}
                      />
                    </Form.Group>
                  </Form>
                  <div className="icon">
                    <FontAwesomeIcon
                      className="icon-menu"
                      icon={faMagnifyingGlass}
                      type='submit'
                    />
                  </div>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={2}>
                <div className="user-details">
                  <div className="wishlist">
                    <Link to={"/ecommerce2/wishlist"}>
                      <div className="bageicn_mian">
                        <FontAwesomeIcon icon={faHeart} className="icon" />
                        <div className="bageicn">{wishlistCount}</div>
                      </div>
                    </Link>
                    <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
                      <Dropdown.Toggle
                        className="dropdown-toggle-main no-arrow text-secondary fs-12"
                        id="notifications-dropdown"
                      >
                        <div
                          className="bageicn_mian"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <FontAwesomeIcon icon={faBell} className="icon" />
                          <div className="bageicn">{notification.length}</div>
                        </div>
                        {/* <FontAwesomeIcon
                          icon={faBell}
                          className="icon"
                          onClick={() => setIsOpen(!isOpen)}
                        /> */}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right dropdown-menu-lg py-0 rounded-0">
                        <div className="p-3 bg-light border-bottom">
                          <h6 className="mb-0">Notifications</h6>
                        </div>
                        <div
                          className="px-3 c-scrollbar-light overflow-auto"
                          style={{ maxHeight: "300px" }}
                        >
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <div className="py-4 text-center fs-16">
                                <ul>
                                  {notification.map((order, index) => (
                                    <li className="">
                                      <div className="orderdiv mb-2">
                                        <p className="sub-text mb-1">
                                          {order.message}
                                        </p>
                                        <p className="sub-text mb-0">
                                          {new Date(
                                            order.createdAt
                                          ).toLocaleString()}
                                        </p>
                                      </div>{" "}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="text-center border-top">
                          <Link
                            // href="https://demo.activeitzone.com/ecommerce/all-notifications"
                            to="/ecommerce2/all-notifications"
                            className="text-secondary fs-12 d-block py-2"
                            onClick={() => setIsOpen(false)}
                          >
                            View All Notifications
                          </Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {/* Before Login  */}
                  <div className="user-login">
                    <div className="main">
                      <Link to={"/ecommerce2/wishlist"}>
                        <div className="circle">
                          <FontAwesomeIcon icon={faUser} className="icon" />
                        </div>
                      </Link>
                      <div className="login">
                        {signin ? (
                          <>

                            <p className="sub-text" onClick={handlemodalshow}>
                              LogOut
                            </p>

                          </>
                        ) : (
                          <>
                            <Link to="/ecommerce2/login">
                              <p className="sub-text">Login</p>
                            </Link>

                            <Link to="/ecommerce2/register">
                              <p className="sub-text regis">Registration</p>
                            </Link>
                          </>
                        )}


                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* search-header-mobile-view  */}

        <div className="search-header-mobile-view">
          <Container>
            <div className="Mobile-search-main">
              <div className="logo-view">
                <div className="icon-toogle" onClick={handleShow}>
                  <FontAwesomeIcon icon={faBars} />
                </div>
                <div className="logo">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assests/images/logo/logop.png"
                    }
                    className="store"
                  />
                </div>
              </div>
              <div className="bar">
                <div className="search-icon">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <div className="canva">
                  {["top"].map((placement, idx) => (
                    <SearchBar
                      key={idx}
                      placement={placement}
                      name={placement}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* toggle button offcanvas */}
            <Offcanvas
              show={show}
              onHide={handleClose}
              className="Mobile-Offcanvas"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="user-details">
                  <div className="user-login">
                    <div className="main">
                      <div className="circle">
                        <FontAwesomeIcon icon={faUser} className="icon" />
                      </div>
                      <div className="login">
                        <Link to="/ecommerce2/login">
                          <p className="sub-text">Login</p>
                        </Link>

                        <div className="border-resign"></div>
                        <Link to="/ecommerce2/register">
                          <p className="sub-text regis">Registration</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <Nav className="justify-content-end   desktop-offcanvas">
                  <Link to="/">
                    <Nav.Link href="/" onClick={handleClose}>
                      Home
                    </Nav.Link>
                  </Link>

                  <Nav.Link
                    onClick={handleClose}
                    as={Link}
                    to="/ecommerce2/flash-sale"
                  >
                    Flash Sale
                  </Nav.Link>

                  <Nav.Link
                    onClick={handleClose}
                    href="/ecommerce2/blogs"
                    title="Blog"
                    id="basic-nav-dropdown"
                  >
                    Blogs
                  </Nav.Link>

                  <Nav.Link onClick={handleClose} href="/ecommerce2/allbrands">
                    All Brands
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    href="/ecommerce2/all-categories"
                  >
                    All Categories
                  </Nav.Link>
                  {/* <Nav.Link onClick={handleClose} href="/allsellers">All Seller</Nav.Link> */}
                  <Nav.Link onClick={handleClose} href="/ecommerce2/coupons">
                    Coupons
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    href="/ecommerce2/todays-deal"
                  >
                    Todays Deal
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleClose}
                    href="/ecommerce2/contactus"
                  >
                    Contact us
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </div>
        <Login
          handleLogout={handleLogout}
          show={modalloginShow}
          onHide={() => setModalloginShow(false)}
        />
        {/* search-header-mobile-view  */}
        <div className="Categories-header">
          <Navbar expand="lg" className=" background">
            <Container>
              <Navbar.Brand href="#home">
                <div className="main-categories">
                  <NavDropdown
                    title="Categories  (See All)"
                    id="basic-nav-dropdown"
                  >
                    {categories?.map((category, catIndex) => (
                      <NavDropdown.Item className="dpdwnmenu">
                        <div className="drop">
                          <div className="icon-dropdown">
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          <p className="sub-text mb-0">{category?.name}</p>
                          <div className="sub-dropdown-menu">
                            <div className="rowsect">
                              <Row>
                                {category?.s_category?.s_sub_categories?.map(
                                  (subCategory, subIndex) =>
                                    subCategory?.subcategory_of_seller !==
                                    null && (
                                      <Col xxl={4}>
                                        <h1 className="midtext mb-3">
                                          {
                                            subCategory?.subcategory_of_seller
                                              ?.name
                                          }
                                        </h1>
                                        {subCategory?.s_child_categories?.map(
                                          (childCategory, childIndex) => (
                                            <Link
                                              to={`/ecommerce2/categoryfilter/${childCategory?.id}`}
                                            >
                                              {" "}
                                              <p className="sub-text ms-3 mb-1">
                                                {childCategory?.name}
                                              </p>{" "}
                                            </Link>
                                          )
                                        )}
                                      </Col>
                                    )
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </NavDropdown.Item>
                    ))}

                    {/* </Link> */}
                  </NavDropdown>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/">
                    Home
                  </Nav.Link>

                  <Link to="/ecommerce2/flash-sale">
                    <Nav.Link as={Link} to="/ecommerce2/flash-sale">
                      Flash Sale
                    </Nav.Link>
                  </Link>
                  <Link to="/ecommerce2/blogs">
                    <Nav.Link as={Link} to="/ecommerce2/blogs">
                      Blogs
                    </Nav.Link>
                  </Link>
                  <Link to="/ecommerce2/allbrands">
                    <Nav.Link as={Link} to="/ecommerce2/allbrands">
                      All Brands
                    </Nav.Link>
                  </Link>
                  <Link to="/ecommerce2/all-categories">
                    <Nav.Link as={Link} to="/ecommerce2/all-categories">
                      All Categories
                    </Nav.Link>
                  </Link>

                  <Link to="/ecommerce2/coupons">
                    <Nav.Link as={Link} to="/ecommerce2/coupons">
                      Coupons
                    </Nav.Link>
                  </Link>
                  <Link to="/todays-deal">
                    <Nav.Link as={Link} to="/ecommerce2/todays-deal">
                      Todays Deal
                    </Nav.Link>
                  </Link>
                  <Link to="/todays-deal">
                    <Nav.Link as={Link} to="/ecommerce2/contactus">
                      Contact Us
                    </Nav.Link>
                  </Link>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Brand
                as={Link}
                to={"/ecommerce2/order-data"}
                href=""
                className="cart"
              >
                <div className="cart-shop">
                  <FontAwesomeIcon icon={faCartShopping} />
                  <div className="items my-auto">
                    <p className="sub-text">{signin ? cartCount : cartLengthFromCookies}</p>
                  </div>
                </div>
              </Navbar.Brand>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
}

export default Header;
