import React, { useContext, useEffect, useState } from 'react';
import './SearchResults.css';
import { Col, Container, Pagination, Row, Spinner } from 'react-bootstrap';
import AddToCard from '../CommonComponent/AddToCard/AddToCard';
import { Context } from '../../utils/context';
import { Products } from '../../utils/apis/Product/Product';
import { useParams } from 'react-router-dom';
import { logDOM } from '@testing-library/react';

function SearchResults() {
    const { IMG_URL, sellerId } = useContext(Context);
    const { name } = useParams();
    console.log(name.length, "nameeee");
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const resultPerPage = 12;

    const fetchProducts = async () => {
        setLoading(true);
        setError(null);
        try {
            const res = await Products("", "", "", sellerId, "", "", name);
            if (res?.success) {
                setProducts(res?.data?.data);
            } else {
                setError('Failed to fetch products');
            }
        } catch (err) {
            setError('An error occurred while fetching products');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
       
        if (name.length >= 3) {
            fetchProducts();
        }
    }, [name]);

    const indexOfLastresult = currentPage * resultPerPage;
    const indexOfFirstresult = indexOfLastresult - resultPerPage;
    const currentProducts = products.slice(indexOfFirstresult, indexOfLastresult);

    const totalPages = Math.ceil(products.length / resultPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className='searchresult_section py-4'>
            <Container>
                <div className='searchresult_main'>
                    <h4>Search Results for "{name}"</h4>
                    {loading ? (
                        <div className='text-center mt-3'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : error ? (
                        <p className='text-danger mt-3'>{error}</p>
                    ) : (
                        <Row>
                            {products.length > 0 ? (
                                currentProducts.map((item) => (
                                    <Col key={item.id} xxl={3} xl={3} lg={4} md={6} sm={6} className='mb-3'>
                                        <div className='resultdiv'>
                                            <AddToCard
                                                image={`${IMG_URL}${item.thumbnail}`}
                                                productId={item.id}
                                                title={item.product_title}
                                                price1={item.mrp}
                                                price2={item.selling_price}
                                            />
                                        </div>
                                    </Col>
                                ))
                            ) : (
                                <Col>
                                    <p>No results found for "{name}".</p>
                                </Col>
                            )}
                        </Row>
                    )}

                    <Pagination className='mt-3'>
                        <Pagination.Prev
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        />
                        {[...Array(totalPages).keys()].map((number) => (
                            <Pagination.Item
                                key={number + 1}
                                active={number + 1 === currentPage}
                                onClick={() => handlePageChange(number + 1)}
                            >
                                {number + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>
                </div>
            </Container>
        </section>
    );
}

export default SearchResults;
