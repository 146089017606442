import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ButtonCommon from "../../ButtonCommon/ButtonCommon";
import Select from "react-select";
import { RegxExpression } from "../../../../utils/common";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import {
  DeleteAddress,
  EditAddress,
  getAddress,
  postAddress,
} from "../../../../utils/apis/orderProcess/orderProcess";
import { Pincode, pincodeWiseAll } from "../../../../utils/apis/master/Master";
import { Context } from "../../../../utils/context";
import { Errors, Placeholders } from "../../../../utils/errors";
import Success_Modalss from "../Success_Modal/Success_Modalss";
import "./AddressPopUp.css";

function AddressPopUp({
  show,
  handleClose,
  handlePrevious,
  props,
  getValuesprop,
  values,
}) {
  const locationOptions = [
    { value: "1", label: "India" },
    { value: "2", label: "Australia" },
    { value: "3", label: "Pakistan" },
    { value: "4", label: "Canada" },
    { value: "1", label: "Afganistan" },
    { value: "2", label: "America" },
    { value: "3", label: "Sri Lanka" },
    { value: "4", label: "Bangladesh" },
    { value: "1", label: "China" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
    trigger,
  } = useForm({});
  const { Select2Data } = useContext(Context);
  const [isSaved, setIsSaved] = useState(false);
  const [cartsuccess, setCartsuccess] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [cities, setCities] = useState("");
  const [states, setStates] = useState("");
  const [countries, setCountries] = useState("");
  const [pincodes, setPincodes] = useState([]);
  const [showInnerPage, setShowInnerPage] = useState(false);
  const [address, setAddress] = useState([]);

  console.log("getValuessssss", getValuesprop?.data);
  console.log("valuesvalues", values);

  useEffect(() => {
    if (values) {
      reset(values);
      setAddressId(values.id);
      setCities(values.city?.name);
      setStates(values.state?.name);
      setCountries(values.country?.name);
      setValue("pincode_id", {
        value: values.pincode?.id,
        label: values.pincode?.name,
      });
    }
  }, [values, reset, setValue]);

  // const handlebackPrevious = () => {
  //   if (showInnerPage) {
  //     setValue("f_name", "");
  //     setValue("l_name", "");
  //     setValue("contact_no", "");
  //     setValue("address", "");
  //     setValue("landmark", "");
  //     setValue("pincode_id", "");
  //     setAddressId("");
  //     setCities("");
  //     setStates("");
  //     setCountries("");
  //     clearErrors();
  //     setIsSaved(true);
  //     setShowInnerPage(false);
  //   } else {
  //     handlePrevious();
  //   }
  // };

  console.log(getValues());

  const onSubmit = async (data) => {
    data.pincode_id = data?.pincode_id?.value;
    var res;
    if (addressId) {
      res = await EditAddress(data, addressId);
    } else {
      res = await postAddress(data);
    }

    if (res?.success) {
      AddressGet();
     
      reset();
      setAddressId("");
      setCities("");
      setStates("");
      setCountries("");
      handleClose();
      setTimeout(() => {
        setCartsuccess(true);
      }, 1000);
      setCartsuccess(true);
    }
  };

  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };

  const handlePincodeSelect = async (id) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      console.log(res);
      setCities(res.data.city.name);
      setStates(res.data.state.name);
      setCountries(res.data.country.name);
      setValue("city_id", res.data.city_id);
      setValue("state_id", res.data.state_id);
      setValue("country_id", res.data.country_id);
      setValue("pincode_id", {
        value: res.data.id,
        label: res.data.name,
      });
    }
  };

  const AddressGet = async () => {
    const res = await getAddress();
    if (res?.success) {
      setAddress(res?.data);
    }
  };

  const handleDelete = async (id) => {
    const res = await DeleteAddress(id);
    if (res?.success) {
      AddressGet();
    }
  };

  useEffect(() => {
    if (cartsuccess) {
      const timer = setTimeout(() => {
        setCartsuccess(false);
        window.location.reload();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [cartsuccess]);

  return (
    <>
      <section className="addreesmodl_section">
        <Modal
          size="lg"
          show={show}
          backdrop="static"
          onHide={handleClose}
          centered
          className="addresmaodl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title">New Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  First Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    {...register("f_name", {
                      required: "First name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.f_name,
                      "is-valid": getValues("f_name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Last Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    {...register("l_name", {
                      required: "Last name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.l_name,
                      "is-valid": getValues("l_name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Contact
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.contact_no,
                      "is-valid": getValues("contact_no"),
                    })}
                    type="text"
                    name="contact_no"
                    placeholder="Enter Mobile Number"
                    {...register("contact_no", {
                      required: "Field is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.phone.test(event.key)) {
                        if (event.key !== "Backspace") {
                          event.preventDefault();
                        }
                      }
                    }}
                    maxLength={10}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Address
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    name="address"
                    type="text"
                    rows={3}
                    placeholder="Enter your Address"
                    {...register("address", {
                      required: "Field is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.address,
                      "is-valid": getValues("address"),
                    })}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Landmark
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter Landmark"
                    {...register("landmark", {
                      required: "Field is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.landmark,
                      "is-valid": getValues("landmark"),
                    })}
                  />
                </Col>
              </Form.Group>

            

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Pincode
                </Form.Label>
                <Col sm="10">
                  <Controller
                    control={control}
                    name="pincode_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={pincodes}
                        onInputChange={handlePincodeChange}
                        onChange={(option) => {
                          handlePincodeSelect(option.value);
                          field.onChange(option);
                        }}
                        className={classNames("", {
                          "is-invalid": errors?.pincode_id,
                          "is-valid": getValues("pincode_id"),
                        })}
                      />
                    )}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  City
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="City"
                    value={cities}
                    disabled
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  State
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="State"
                    value={states}
                    disabled
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Country
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    value={countries}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Form> */}

<Form>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  First Name
                </Form.Label>
                <Col sm="10">
                  {/* <Form.Control as="textarea" type="text" placeholder="Paul k Jeneson" rows={3} /> */}
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    {...register("f_name", {
                      required: "First name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.f_name,
                      "is-valid": getValues("f_name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Last Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    {...register("l_name", {
                      required: "Last name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.l_name,
                      "is-valid": getValues("l_name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Contact
                </Form.Label>
                <Col sm="10">
                  {/* <Form.Control as="textarea" type="text" placeholder="Paul k Jeneson" rows={3} /> */}
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.contact_no,
                      "is-valid": getValues("contact_no"),
                    })}
                    type="text"
                    name="contact_no"
                    placeholder="Enter Mobile Number"
                    {...register("contact_no", {
                      required: "Field is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.phone.test(event.key)) {
                        if (event.key !== "Backspace") {
                          event.preventDefault();
                        }
                      }
                    }}
                    maxLength={10}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Address
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    name="address"
                    type="text"
                    rows={3}
                    placeholder="Enter Message"
                    {...register("address", {
                      required: "Message required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.address,
                    })}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Postal Code
                </Form.Label>
                <Col sm="10">
                  {/* <Form.Control type="password" placeholder="Your Postal Code " /> */}
                  <Controller
                    name="pincode_id"
                    {...register("pincode_id", {
                      required: Errors.pincode,
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.pincode_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={pincodes}
                        onInputChange={(selectedOption) => {
                          handlePincodeChange(selectedOption);
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          handlePincodeSelect(selectedOption.value);
                        }}
                      />
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Country
                </Form.Label>
                <Col sm="10">
                 
                  {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your Country" classNamePrefix="main" /> */}
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.country,
                    })}
                    type="text"
                    name="country_id"
                    readOnly
                    value={countries}
                    placeholder={Placeholders.country}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  State
                </Form.Label>
                <Col sm="10">
                 

                  {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your State" classNamePrefix="main" /> */}

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.state,
                    })}
                    type="text"
                    name="state_id"
                    value={states}
                    placeholder={Placeholders.state}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  City
                </Form.Label>
                <Col sm="10">
                 
                  {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your City" classNamePrefix="main" /> */}

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.city,
                    })}
                    type="text"
                    name="city_id"
                    readOnly
                    value={cities}
                    // placeholder={Placeholders.city}
                  />
                </Col>
              </Form.Group>
             

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  LandMark
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter LandMark"
                    {...register("land_mark", {
                      required: "LandMark required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.land_mark,
                      "is-valid": getValues("land_mark"),
                    })}
                  />
                </Col>
              </Form.Group>
              <Row>
                <Col sm="2">
                  <div className="addressHead">
                    <p className="sub-text">TYPE OF ADDRESS</p>
                  </div>
                </Col>

                <Col sm="10">
                  <div className="d-flex flex-wrap">
                    <Form.Group
                      className="addresscheck  me-3"
                      id="formGridCheckbox"
                    >
                      <Form.Check
                        type="radio"
                        name="addressType"
                        label="Home"
                        value="Home"
                        defaultChecked={getValues("address_type") === "Home"}
                        {...register("address_type", {
                          required: Errors.addressType,
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="addresscheck  me-3"
                      id="formGridCheckbox"
                    >
                      <Form.Check
                        type="radio"
                        name="addressType"
                        label="Work"
                        value="Work"
                        defaultChecked={getValues("address_type") === "Work"}
                        {...register("address_type", {
                          required: Errors.addressType,
                        })}
                      />
                    </Form.Group>
                    <Form.Group className="addresscheck" id="formGridCheckbox">
                      <Form.Check
                        type="radio"
                        name="addressType"
                        label="Office"
                        value="Office"
                        defaultChecked={getValues("address_type") === "Office"}
                        {...register("address_type", {
                          required: Errors.addressType,
                        })}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <div className="text-end">
                
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <ButtonCommon
              className="back"
              type="button"
              text={"Back"}
              onClick={handleClose}
            >
              Back
            </ButtonCommon>
            <ButtonCommon
              className="saveebtn"
              type="button"
              onClick={handleSubmit(onSubmit)}
              text={"Save"}
            >
              Save
            </ButtonCommon>
          </Modal.Footer>
        </Modal>
        <Success_Modalss show={cartsuccess} />
      </section>
    </>
  );
}

export default AddressPopUp;