import React, {useState} from "react";
import "./SideBarIcons.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChevronRight, faBoltLightning, faClock, faGavel, faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
const SideBarIcons = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleIcons = () => {
        setIsVisible(!isVisible);
    };
    return (
        <>
            <section className="SideBarIcons">
            <div className="desktop-view">
                <div className="icons-mains">
                    <a href="/ecommerce2/all-categories" class="button">
                        <span class="icon">
                            <FontAwesomeIcon icon={faBars} />
                        </span>
                        <span class="text">All Categories</span>
                        <div className="arrow">
                            <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                        </div>
                    </a>

                    <a href="/ecommerce2/flash-sale" class="button">
                        <span class="icon">
                            <FontAwesomeIcon icon={faBoltLightning} />
                        </span>
                        <span class="text">Flash Sale</span>
                        <div className="arrow">
                            <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                        </div>
                    </a>

                    <a href="/ecommerce2/todays-deal" class="button">
                        <span class="icon">
                            <FontAwesomeIcon icon={faClock} />
                        </span>
                        <span class="text">Todays Deal</span>
                        <div className="arrow">
                            <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                        </div>
                    </a>

                  
                </div>
                </div>
                <div className="mobile-view">
                    <div className="yellow-circle" onClick={toggleIcons}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} className="arrow"/>
                    </div>
                    <div className={`icons-mains ${isVisible ? "" : "hidden"}`}>
                        <a href="/ecommerce2/all-categories" className="button">
                            <span className="icon">
                                <FontAwesomeIcon icon={faBars} />
                            </span>
                            <span className="text">All Categories</span>
                            <div className="arrow">
                                <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                            </div>
                        </a>

                        <a href="/ecommerce2/flash-sale" className="button">
                            <span className="icon">
                                <FontAwesomeIcon icon={faBoltLightning} />
                            </span>
                            <span className="text">Flash Sale</span>
                            <div className="arrow">
                                <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                            </div>
                        </a>

                        <a href="/ecommerce2/todays-deal" className="button">
                            <span className="icon">
                                <FontAwesomeIcon icon={faClock} />
                            </span>
                            <span className="text">Todays Deal</span>
                            <div className="arrow">
                                <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                            </div>
                        </a>

                        {/* <a href="/ecommerce2/flash-sale" className="button">
                            <span className="icon">
                                <FontAwesomeIcon icon={faGavel} />
                            </span>
                            <span className="text">Auction</span>
                            <div className="arrow">
                                <FontAwesomeIcon icon={faChevronRight} className="right-arrow" />
                            </div>
                        </a> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default SideBarIcons;
