import React from "react";
import "./Product.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping, faHeart} from "@fortawesome/free-solid-svg-icons";
function Product() {
    return (
        <>
            <section className="product_section">
                <div className="product_main">
                    <div className="imgcont_main_div">
                        <div className="productimg_div">
                            <img
                                src={process.env.PUBLIC_URL + "/assests/images/Products/product2.webp"}
                                className="product_img"
                            />
                        </div>
                        <div className="fontosomian_div">
                            <div className="fontso_div">
                                <FontAwesomeIcon icon={faHeart} />
                            </div>
                            <div className="fontso_div">
                                <FontAwesomeIcon icon={faHeart} />
                            </div>
                        </div>
                        <div className="addcart_div">
                            <p className="text mb-0">Add To Cart</p>
                            {/* <FontAwesomeIcon icon={faCartShopping} /> */}
                        </div>
                    </div>
                    <div className="detailtext p-2">
                        <div className="podectnamed_div">
                            <p className="text mb-0">Baby Boys 2-piece Special Occasion Top and Pant Suspender Set</p>
                        </div>

                        <p className="midtext mt-2 mb-0">$15.00</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Product;
