import React, { useEffect, useState } from 'react'
import './OrderSuccessful.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import { singleOrderGet } from '../../utils/apis/myAccount/myAccount';
import { useParams } from 'react-router-dom';
// import Table from 'react-bootstrap/Table';

const OrderSuccessful = () => {
    const [orderdata, setOrderdata] = useState([]);
    const { id } = useParams();
    const getsingleorder = async () => {
        const res = await singleOrderGet(id);
        if (res?.success) {
            setOrderdata(res?.data);
            console.log(res?.data, "orderrr");
        }
    }
    useEffect(() => {
        getsingleorder();
    }, [id])
    return (
        <section className='order-success mt-5'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='text-center'>
                            <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                            <div>
                                <h3 className='title-success mt-3'>Thank You for Your Order!</h3>
                                <p className='text-successss'>A copy or your order summary has been sent to <span className='text'>customer@example.com</span></p>
                            </div>
                        </div>
                        <div className='order-summaryyyy'>
                            <h4 className='summary-title'>Order Summary</h4>
                            <div className='brown-border mb-4'></div>
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                  
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Name:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            {orderdata?.o_w_orders_details?.map((itemname, index) => (
                                                <>
                                                    <p className='sub-title'>{itemname?.s_product?.product_title} </p>
                                                </>
                                            ))}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Email:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='sub-title'>customer@example.com</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Shipping address:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='sub-title'>{orderdata?.o_w_users_address?.address}{", "}{orderdata?.o_w_users_address?.land_mark}{", "}{orderdata?.o_w_users_address?.city?.name}{", "}{orderdata?.o_w_users_address?.state?.name}
                                                {", "}{orderdata?.o_w_users_address?.country?.name}{", "}{orderdata?.o_w_users_address?.pincode_id}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Order date:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='sub-title'>{new Date(orderdata.createdAt).toLocaleDateString()}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Order status:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='sub-title'>{orderdata?.o_w_order_status?.name}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Total order amount:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='sub-title'>₹{orderdata?.amount}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='title-summaryyy'>Shipping:</p>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <p className='sub-title'>Flat shipping rate</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        {/* Order Code */}
                        <div className='order-summaryyyy'>
                            <div className='text-center'>
                                <h3 className='second-order-sum'>Order Code: <span className='red-fonttt'>{orderdata?.invoice_number}</span></h3>
                            </div>
                            <h4 className='summary-title'>Order Summary</h4>

                            <div className='table-responsive'>
                                <table className='table table-bordered' >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Variation</th>
                                            <th>Quantity</th>
                                            <th>Delivery Type</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                {orderdata?.o_w_orders_details?.map((itemname, index) => (
                                                    <>
                                                        <p className='sub-title'>{itemname?.s_product?.product_title} </p>
                                                    </>
                                                ))}
                                            </td>
                                            <td></td>
                                            <td>
                                                {orderdata?.o_w_orders_details?.map((itemname, index) => (
                                                    <>
                                                        <p className='sub-title'>{itemname?.quantity} </p>
                                                    </>
                                                ))}
                                            </td>
                                            <td>Carrier</td>
                                            <td>₹{orderdata?.amount}</td>
                                        </tr>



                                    </tbody>
                                </table>
                            </div>


                            <div className='text-end'>
                                <Row className='justify-content-end'>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='title-summaryyy'>Subtotal</p>
                                            </Col>
                                            <Col md={6} sm={6} xs={6}>
                                                <p>₹{orderdata?.amount}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='title-summaryyy'>Shipping</p>
                                            </Col>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='sub-title'>...</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='title-summaryyy'>Tax</p>
                                            </Col>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='sub-title'>...</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='title-summaryyy'>Coupon Discount</p>
                                            </Col>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='sub-title'>{orderdata?.discount}</p>
                                            </Col>
                                        </Row>
                                        <div className='brown-border'>
                                        </div>
                                        <Row>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='title-summaryyy'>Total</p>
                                            </Col>
                                            <Col md={6} sm={6} xs={6}>
                                                <p className='sub-title'>₹{orderdata?.amount}</p>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OrderSuccessful