import React, {useContext, useEffect, useState} from "react";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import {Container} from "react-bootstrap";
import "./PrivacyPolicy/PrivacyPolicy.css";
import Table from "react-bootstrap/Table";
import { Context } from "../../utils/context";
import { GetReturnPolicy } from "../../utils/apis/master/Master";
import parse from "html-react-parser";


const ReturnPolicy = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "return policy", link: "#"},
        ,
    ]);


    const [returns, setReturn] = useState(null);
    const { sellerId } = useContext(Context);

    const getReturn = async () => {
        try {
            const res = await GetReturnPolicy(sellerId);
            if (res?.success) {
                setReturn(res?.data);
                console.log(res?.data);
            } else {
               
            }
        } catch (error) {
            console.error("Error fetching Returns and Policy:", error);
        }
    };

    useEffect(() => {
        getReturn();
    }, [sellerId]); 
    return (
        <>
            <section className="PrivacyPolicy">
                <Container>
                    <div className="title-main">
                        <h1 className="title">Return Policy Page</h1>
                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                    </div>
                    {returns && returns.name && <p className="sub-text-text">{parse(returns.name)}</p>}
                    {/* <p className="text mt-4">
                        Returns is a scheme provided by respective sellers directly under this policy in terms of which
                        the option of exchange, replacement and/ or refund is offered by the respective sellers to you.
                        All products listed under a particular category may not have the same returns policy. For all
                        products, the returns/replacement policy provided on the product page shall prevail over the
                        general returns policy. Do refer the respective item's applicable return/replacement policy on
                        the product page for any exceptions to this returns policy and the table below
                    </p>
                    <p className="text mt-4">
                        The return policy is divided into three parts; Do read all sections carefully to understand the
                        conditions and cases under which returns will be accepted.
                    </p>
                    <Table responsive="sm" className="bordered">
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td className="category">
                                    <p className="text">Category</p>
                                </td>
                                <td className="category2"><p className="text">Returns Window, Actions Possible and Conditions (if any)</p></td>
                            </tr>
                            <tr>
                                <td className="category">
                                    <p className="text">Lifestyle: Jewellery, Footwear Accessories, Travel Accessories, Watch Accessories, etc..</p>
                                    <p className="text">Home: Pet Supplies & Rest of Home. (Except Home dcor, Furnishing, Home Improvement Tools, Household Items)</p>
                                </td>
                                <td className="category2">
                                    <p className="text">10 days</p>
                                    <p className="text">Refund or Replacement</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="category">
                                    <p className="text">Lifestyle: Watch, Winter Wear (Blazer, Sweatshirt, Scarf, Shawl, Jacket, Coat, Sweater, Thermal, Kids Thermal, Track Pant, Shrugs), etc...</p>
                                </td>
                                <td className="category2">
                                    <p className="text">10 days</p>
                                    <p className="text">Refund or Replacement</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="category">
                                    <p className="text">Lifestyle: T-Shirt, Footwear, Sari, Short, Dress, Kid’s (Capri, Shorts & Tops), Mens (Ethnic Wear, Shirt, Formals, Jeans, Clothing Accessory), Womens (Ethnic Wear, Fabric, Blouse, Jean, Skirt, Trousers, Bra), Bags, Raincoat, Sunglass, Belt, Frame, Backpack, Suitcase, Luggage, etc...</p>
                                </td>
                                <td className="category2">
                                    <p className="text">14 days</p>
                                    <p className="text">Refund or Replacement</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="category">
                                 <p className="text">Medicine (Allopathy & Homeopathy)</p>
                                </td>
                                <td className="category2">
                                    <p className="text">2 days</p>
                                    <p className="text">Refund </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="category">
                                    <p className="text">Home: Home Improvement Tools, Household Items, Home dcor, Furnishing</p>
                                </td>
                                <td className="category2">
                                    <p className="text">7 days</p>
                                    <p className="text">Refund or replacement</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="category">
                                    <p className="text">Books (All books)</p>
                                    <p className="text">Sports Equipments (Racquet, ball, support, gloves, bags etc.)</p>
                                    <p className="text">Exercise & Fitness Equipments (Home Gym combos, dumbbell etc.)</p>
                                    <p className="text">Auto Accessories - Car and Bike accessories (helmets, car kit, media players etc.)</p>
                                </td>
                                <td className="category2">
                                    <p className="text">7 days Replacement only</p>
                                    <p className="text">Free replacement will be provided within 7 days if the product is delivered in defective/damaged condition or different from the ordered item.</p>
                                    <p className="text">Please keep the product intact, with original accessories, user manual and warranty cards in the original packaging at the time of returning the product.</p>
                                </td>
                            </tr>
                        </tbody>
                    </Table> */}
                </Container>
            </section>
        </>
    );
};

export default ReturnPolicy;
