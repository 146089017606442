import React, { useContext, useEffect, useState } from "react";
import "./Blogs.css";
import { Col, Container, Form, Offcanvas, Row } from "react-bootstrap";
import { faArrowRight, faFilter, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Recent_post from "../CommonComponent/Recent_Post/Recent_post";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { blogPost } from "../../../src/utils/apis/common/Common";
import parse from "html-react-parser";

function Blogs() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const items = [
        { label: "Family" },
        { label: "Home" },
        { label: "Tech" },
        { label: "Style" },
        { label: "On Trend" },
        { label: "Marketing and Promotion" },
        { label: "Online Sell" },
        { label: "Fashion" },
        { label: "Life Hacks" },
        { label: "Man Fashion" },
        { label: "new" },
    ];

    // const recentpost = [
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },

    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent2.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent3.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent4.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    // ];

    // const blogcard = [
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         content: "In this article, We’ll also provide you with all the tips and information.",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         content:
    //             "In this article, well take all the guesswork out of starting your own business. We’ll also provide you with all the tips and information.",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         content:
    //             "In this article, well take all the guesswork out of starting your own business. We’ll also provide you with all the tips and information.",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         content:
    //             "In this article, well take all the guesswork out of starting your own business. We’ll also provide you with all the tips and information.",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    //     {
    //         image: process.env.PUBLIC_URL + "/assests/images/blogs/recent1.webp",
    //         title: "T-Shirts Every Man Needs in His Wardrobe",
    //         content:
    //             "In this article, well take all the guesswork out of starting your own business. We’ll also provide you with all the tips and information.",
    //         time: "10 jan 2024",
    //         place: "Life hack",
    //     },
    // ];

    const { IMG_URL, sellerId } = useContext(Context);
    const [blogdata, setBlogdata] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const date = new Date();
    const getBlogData = async (page) => {
        const res = await blogPost(sellerId, page);
        if (res?.success) {
            setBlogdata(res?.data?.data);
            console.log(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
        } else {
        }
    };

    useEffect(() => {
        getBlogData(activePage);
    }, [activePage]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        const filtered = blogdata.filter((blogdata) => blogdata.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredItems(filtered);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Search query:", searchQuery);
        const filtered = blogdata.filter((blogdata) => blogdata.name.toLowerCase().includes(searchQuery.toLowerCase()));
        setFilteredItems(filtered);
    };

    return (
        <>
            <section className="blogmain_section  mb-4">
                <Container>
                    <div className="blogmain_div">
                        <Row>
                            <Col xxl={3} xl={3} lg={4}>
                                <div className="checktablet">
                                    <div className="resentmain_div ">
                                        <div className="searchdiv">
                                            <Form>
                                                <Form.Group
                                                    className="serchingdiv mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                    value={searchQuery}
                                                    onChange={handleInputChange}
                                                >
                                                    <Form.Control type="email" placeholder="Search" />
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} className="serchicn" />
                                                </Form.Group>
                                            </Form>
                                        </div>

                                        {/* <div className="categorylist_div">
                                            <h6 className=" text mb-4">Categories</h6>
                                            <Form>
                                                {items.map((item, index) => (
                                                    <div className="chekinig mb-3 ">
                                                        <Form.Check
                                                            className="text"
                                                            type="checkbox"
                                                            id={`check-${index}`}
                                                            label={item.label}
                                                        />
                                                    </div>
                                                ))}
                                            </Form>
                                        </div> */}
                                    </div>

                                    <div className="recentblogs_divsss">
                                        <h6 className=" text mb-3">Recent Blogs</h6>
                                        <div className="recent">
                                            {/* <Row>
                                            {recentpost.map((item, index) => (
                                                <Col xxl={12} className="mb-3">
                                                    <Recent_post item={item} />
                                                </Col>
                                            ))}
                                        </Row> */}

                                        {searchQuery === "" ? (
                                            blogdata?.map((post) => (
                                                
                                                <div key={post.id} className="recent_mainss">
                                                    <div>
                                                        <div className="recentpost_img_div">
                                                            <img
                                                                src={IMG_URL + post?.image}
                                                                alt="logo"
                                                                className="recent_img"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Link to={`/ecommerce2/blogs/innerblogs/${post?.id}`}>
                                                    <div className="recent_text">
                                                        <p className="titleblg mb-2">{post.name}</p>
                                                        <p className="timeblg mb-0">{parse(post?.description)}</p>
                                                        <p className="placeblg mb-0">{post.place}</p>
                                                    </div>
                                                    </Link>
                                                </div>
                                            ))
                                        ) : filteredItems.length > 0 ? (
                                            filteredItems.map((post) => (
                                                <div key={post.id} className="recent_mainss">
                                                    <div>
                                                        <div className="recentpost_img_div">
                                                            <img
                                                                src={IMG_URL + post?.image}
                                                                alt="logo"
                                                                className="recent_img"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Link to={`/ecommerce2/blogs/innerblogs/${post?.id}`}>
                                                    <div className="recent_text">
                                                        <p className="titleblg mb-2">{post.name}</p>
                                                        <p className="timeblg mb-0">{parse(post?.description)}</p>
                                                        <p className="placeblg mb-0">{post.place}</p>
                                                    </div>
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No matching items found</p>
                                        )}
                                    </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={9} xl={9} lg={8}>
                                <div className="bolg_div">
                                    <div className="blogtitle_div mb-3">
                                        <h4 className="title">BLOGS</h4>
                                        {/* <FontAwesomeIcon icon={faFilter} className="filtericn" onClick={handleShow} /> */}
                                    </div>
                                    <Row>
                                        {blogdata?.map((item) => (
                                            <Col xxl={4} xl={4} lg={6} md={6} sm={6} className="mb-3" key={item.id}>
                                                <div className="blogcardmain_div">
                                                    <div className="div_rec mb-3">
                                                        <img
                                                            src={IMG_URL + item?.image}
                                                            alt="logo"
                                                            className="recent_img"
                                                        />
                                                    </div>
                                                    <div className="content-blog">
                                                        <h6 className="midtext">{item?.name}</h6>
                                                        <p className=" sub-text contenttext">
                                                            {parse(item?.description)}
                                                        </p>
                                                        {/* <p className=" sub-text mb-0">{item.time}</p> */}
                                                        <p className="sub-text placecol">{item.createdAt}</p>
                                                    </div>

                                                    <Link to={`/ecommerce2/blogs/innerblogs/${item?.id}`}>
                                                        <p className="text readfull">
                                                            Read Full Blog
                                                            <FontAwesomeIcon icon={faArrowRight} className="faicn" />
                                                        </p>
                                                    </Link>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                        {/* <div className=" recentblogs_divsss tabletview  mt-4">
                            <h6 className="text mb-3">Recent Blogs</h6>
                            <Row>
                                {recentpost.map((item, index) => (
                                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} className=" mb-3">
                                        <Recent_post item={item} />
                                    </Col>
                                ))}
                            </Row>
                        </div> */}
                    </div>
                </Container>

                <div>
                    {/* <Offcanvas show={show} onHide={handleClose} placement="end">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Filter</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                         
                            <div className="resentmain_div ">
                                <div className="searchdiv">
                                    <Form>
                                        <Form.Group className="serchingdiv mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Search" />
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className="serchicn" />
                                        </Form.Group>
                                    </Form>
                                </div>

                                <div className="categorylist_div">
                                    <h6 className=" text mb-4">Categories</h6>
                                    <Form>
                                        {items.map((item, index) => (
                                            <div className="chekinig mb-3 ">
                                                <Form.Check
                                                    className="text"
                                                    type="checkbox"
                                                    id={`check-${index}`}
                                                    label={item.label}
                                                />
                                            </div>
                                        ))}
                                    </Form>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas> */}
                </div>
            </section>
        </>
    );
}

export default Blogs;
