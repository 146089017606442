import React from "react";
import "./CommonBannerSwiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "react-bootstrap";
import { Navigation } from "swiper/modules";
import 'swiper/css/navigation';
function CommonBannerSwiper() {
    const childCategory =[
        {

        },
        {},
        {}
    ]
  return (
    <>
      <section className="innerban_seection mt-3 mb-3">
        <Container>
          <Swiper
            navigation={true} modules={[Navigation]}
           
            className="mySwiper"
            //   onSwiper={(swiper) =>
            //     setSwiperInstance(swiper)
            //   }
          >
            {childCategory?.map((value, index) => (
              <SwiperSlide>
                <div className="innerban_main">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assests/images/AllSellers/innersellerbanner.webp"
                    }
                    className="innerbanimg"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    </>
  );
}

export default CommonBannerSwiper;
