import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './MainFlashSale.css'
import "../CommonComponent/FlashSales/FlashSalesMain.css";
import { GetAllFlashSale } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "moment-timezone";
import "react-moment";

const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;
const daySeconds = hourSeconds * 24;

const MainFlashSale = () => {
    const { sellerId, type, IMG_URL } = useContext(Context);

    const timerProps = {
        isPlaying: true,
        size: 90,
        strokeWidth: 6,
    };

    const renderTime = (time) => (
        <div className="time-wrapper">
            <div className="title time">{time}</div>
        </div>
    );

    const getTimeSeconds = (time) => Math.ceil(time % minuteSeconds);
    const getTimeMinutes = (time) => Math.floor((time % hourSeconds) / minuteSeconds);
    const getTimeHours = (time) => Math.floor((time % daySeconds) / hourSeconds);
    const getTimeDays = (time) => Math.floor(time / daySeconds);

    const [allflash, setAllFlash] = useState([]);

    const getAllFlash = async () => {
        const res = await GetAllFlashSale(sellerId, type);
        if (res?.success) {
            setAllFlash(res.data);
            console.log(res.data, "response data");
        }
    };


    useEffect(() => {
        getAllFlash();
    }, []);

    return (
        <>
            <section className="MainFlashSale">
                <Container>
                    <div className="Flash-sale">
                        <img src={process.env.PUBLIC_URL + "/assests/images/Banner/flashsale.webp"} className="sale" />
                    </div>
                    <Row className="mt-3">
                        {allflash?.map((item, index) => {
                            const endTime = new Date(item.end_time).getTime() / 1000;
                            const currentTime = Date.now() / 1000;
                            const remainingTime = endTime - currentTime;
                            const daysDuration = Math.ceil(remainingTime / daySeconds) * daySeconds;

                            return (
                                <Col xxl={4} xl={4} lg={4} md={6} key={index} className="mt-3">
                                    <div className="Sale-Banner">
                                        <div className="Flash-sale">
                                        <img src={IMG_URL + item?.image} className="sale" alt="" />

                                        </div>
                                        <div className="Sale-Timer">
                                            <div className="all-times-bar">
                                                <div className="timebox">
                                                    <div className="day  main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF"]]}
                                                            isLinearGradient={true}
                                                            duration={daysDuration}
                                                            initialRemainingTime={remainingTime}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) => renderTime(getTimeDays(daysDuration - elapsedTime))}
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">Days</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="hours  main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={daySeconds}
                                                            initialRemainingTime={remainingTime % daySeconds}
                                                            onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > hourSeconds]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) => renderTime(getTimeHours(daySeconds - elapsedTime))}
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">HRS</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="minutes  main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={hourSeconds}
                                                            initialRemainingTime={remainingTime % hourSeconds}
                                                            onComplete={(totalElapsedTime) => [
                                                                remainingTime - totalElapsedTime > minuteSeconds,
                                                            ]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) => renderTime(getTimeMinutes(hourSeconds - elapsedTime))}
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">MIN</h3>
                                                    </div>
                                                </div>
                                                <div className="timebox">
                                                    <div className="seconds  main-day mr-12">
                                                        <CountdownCircleTimer
                                                            {...timerProps}
                                                            colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                            isLinearGradient={true}
                                                            duration={minuteSeconds}
                                                            initialRemainingTime={remainingTime % minuteSeconds}
                                                            onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > 0]}
                                                            trailColor="#e7e5e5"
                                                        >
                                                            {({ elapsedTime }) => renderTime(getTimeSeconds(elapsedTime))}
                                                        </CountdownCircleTimer>
                                                        <h3 className="title">SEC</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default MainFlashSale;
