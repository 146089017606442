import React, { useContext, useState } from "react";
import "./Login.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faApple, faFacebookF, faGoogle, faTwitter } from "@fortawesome/free-brands-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import { useForm } from "react-hook-form";
import { userLogin } from "../../../utils/apis/login/Login";
import Cookies from "js-cookie";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import Register from "../Register/Register";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

function Login() {
    const [showEmail, setShowEmail] = useState(true);
    const { signin, setSignin } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [iconOne, setIconOne] = useState(faEye);
    const [phoneValue, setPhoneValue] = useState("");
    const [showForgetPassword, setShowForgetPassword] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const navigate = useNavigate();

    const toggleInput = () => {
        setShowEmail(!showEmail);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        setIconOne(showPassword ? faEyeSlash : faEye);
    };

    const handleCreateAccountClick = () => {
        setShowRegisterForm(true);
    };

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const onSubmit = async (data) => {
        const loginData = {
            ...data,
            email_or_contact: showEmail ? data.email_or_contact : phoneValue,
        };

        const res = await userLogin(loginData);
        if (res?.success) {
            await Cookies.set("ecommerce2_security", res?.data?.token);
            await setSignin(true);
            navigate('/');
            reset();
        } else {
            if (res?.message?.errors) {
                res.message.errors.forEach(error => {
                    setError(error.path, { message: error.msg });
                });
            }
        }
    };

    return (
        <>
            <section className="login_section">
                <Container fluid>
                    {!showForgetPassword && !showRegisterForm && (
                        <div>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={12}>
                                    <div className="logincardiv">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assests/images/Login/cartimg.webp"
                                            }
                                            className="logincartimgdiv"
                                        />
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} >
                                    <div className="">
                                        <Row>
                                            <Col xxl={6} xl={9} lg={10} className="p-4 p-lg-4">
                                                <div>
                                                    <div className="baggimg">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assests/images/Login/bagge.svg"
                                                            }
                                                            className="bagimg"
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <h1 className="title">Welcome Back !</h1>
                                                        <h5 className="sub-text">Login to your account.</h5>
                                                    </div>

                                                    <div>
                                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                                            <div>
                                                                {showEmail ? (
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                        <Form.Label className="text">Email</Form.Label>
                                                                        <Form.Control type="text" placeholder="name@example.com"
                                                                            {...register("email_or_contact", {
                                                                                required: "Email or phone number is required",
                                                                            })}
                                                                            className={classNames("", {
                                                                                "is-invalid": errors?.email_or_contact,
                                                                                "is-valid": getValues("email_or_contact"),
                                                                            })} />
                                                                    </Form.Group>
                                                                ) : (
                                                                    <>
                                                                        <Form.Label className="text">Phone</Form.Label>
                                                                        <PhoneInput
                                                                            className={classNames("mb-3", {
                                                                                "is-invalid": errors?.email_or_contact,
                                                                            })}
                                                                            country={"in"}
                                                                            value={phoneValue}
                                                                            onChange={setPhoneValue}
                                                                        />
                                                                    </>
                                                                )}
                                                                <p onClick={toggleInput} style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }} className="text-end mb-0">
                                                                    using {showEmail ? 'phone number' : 'email'}
                                                                </p>
                                                            </div>
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="exampleForm.ControlTextarea1"
                                                            >
                                                                <Form.Label className="text">Password</Form.Label>
                                                                <div className="fcont_div">
                                                                    <Form.Control
                                                                        type={showPassword ? "text" : "password"}
                                                                        placeholder="password"
                                                                        {...register("password", {
                                                                            required: "Password is required",
                                                                            minLength: {
                                                                                value: 6,
                                                                                message: "Password must be at least 6 characters",
                                                                            },
                                                                        })}
                                                                        className={classNames("", {
                                                                            "is-invalid": errors?.password,
                                                                            "is-valid": getValues("password"),
                                                                        })} />
                                                                    <FontAwesomeIcon
                                                                        icon={iconOne}
                                                                        className="eyicn"
                                                                        onClick={togglePasswordVisibility}
                                                                    />
                                                                </div>
                                                                {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                                            </Form.Group>
                                                            <div className="d-flex justify-content-between">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="custom-switch"
                                                                    label="Remember me"
                                                                    className="sub-text"
                                                                    {...register("remember_me")}
                                                                />
                                                                <p className="sub-text" onClick={() => setShowForgetPassword(true)}>Forgot Password</p>
                                                            </div>
                                                            <ButtonCommon buttontype="submit" text="Login" className="loginbtn" />

                                                            <div>
                                                                <p className="sub-text text-center">Or Login With</p>
                                                                <div className="loginicnndiv">
                                                                    <Link ><div className="fonicndv fonicndv1">
                                                                        <FontAwesomeIcon icon={faFacebookF} className="ficnn" />
                                                                    </div></Link>
                                                                    <Link ><div className="fonicndv fonicndv2">
                                                                        <FontAwesomeIcon icon={faGoogle} className="ficnn" />
                                                                    </div></Link>
                                                                    <Link ><div className="fonicndv fonicndv3">
                                                                        <FontAwesomeIcon icon={faTwitter} className="ficnn" />
                                                                    </div></Link>
                                                                    <Link ><div className="fonicndv fonicndv4">
                                                                        <FontAwesomeIcon icon={faApple} className="ficnn" />
                                                                    </div></Link>
                                                                </div>
                                                                <p className="mt-3 mb-2 sub-text " >Dont have an account?
                                                                    <span className="midtext colrrr" onClick={handleCreateAccountClick}>Register Now</span> </p>
                                                                <Link to="/"><p className="midtext colrrr"> <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back to Previous Page</p></Link>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    {showForgetPassword && <ForgotPassword setShowForgetPassword={setShowForgetPassword} />}
                    {showRegisterForm && <Register />}
                </Container>
            </section>
        </>
    );
}

export default Login;
