import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Form, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './OrderSummary.css';
import { Context } from '../../utils/context';
import { getCoupansdata } from '../../utils/apis/Product/Product';

const OrderSummary = ({ setTotalpr, setDiscountPrice, setDiscountTotal, setDiscountAmount, setDiscountinfo, setCoupanId, totalPrice }) => {
  const { cartCount, signin } = useContext(Context);

  const [couponDetail, setCouponDetail] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [message, setMessage] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(totalPrice);
  console.log(discountedPrice, "pricessss");

  const location = useLocation();
  const navigate = useNavigate();

  const applyClick = async () => {
    const res = await getCoupansdata(couponCode, totalPrice);
    if (res?.success) {
      const coupon = res.data;
      setCouponDetail(coupon);
      setCoupanId(coupon?.id);
      setDiscountinfo(coupon?.discount);

      let newDiscountedPrice = totalPrice;
      let newDiscountAmount = 0;

      if (coupon.type === 'percentage') {
        newDiscountAmount = totalPrice * parseFloat(coupon.discount) / 100;
      } else {
        newDiscountAmount = parseFloat(coupon.total_amount);
      }

      newDiscountedPrice = totalPrice - newDiscountAmount;

      setDiscountedPrice(newDiscountedPrice);
      setDiscountTotal(newDiscountedPrice);
      setDiscountAmount(newDiscountAmount);
      setDiscountPrice(newDiscountedPrice)
      console.log(newDiscountedPrice, "prrrrr");

    } else {
      setMessage('Invalid coupon code');
      setDiscountedPrice(totalPrice);
      setDiscountPrice(totalPrice)
      console.log(totalPrice, "prrrrr");
      setDiscountAmount(0);
    }
  };

  useEffect(() => {
    if (totalPrice <= 1000) {
      setMessage('The total price is less than or equal to 1000');
    } else {
      setMessage('');
    }
  }, [totalPrice]);

  useEffect(() => {
    setDiscountedPrice(totalPrice);
  }, [totalPrice]);

  return (
    <section className='order-summary'>
      <Container>
        <div className='white-bottle'>
          <p className='order-title'>Order Summary</p>

          <Row>
            <Col md={6}>
              <div className='red-bg d-flex justify-content-between'>
                <p className='order-txttt'>Total Products</p>
                <p className='order-txttt'>{cartCount}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className='yellow-bg d-flex justify-content-between'>
                <span className='order-txttt'>Total Products</span>
                <span className='order-txttt'>00</span>
              </div>
            </Col>
            <Col md={12}>
              <div className='d-flex justify-content-between mt-3'>
                <p className='order-black-txttt'>Subtotal ({cartCount} Products)</p>
                <p className='order-black-txttt'>Rs {totalPrice}</p>
              </div>
            </Col>
            <Col md={12}>
              <div className='d-flex justify-content-between'>
                <p className='order-black-txttt'>{couponDetail ? (couponDetail.type === 'percentage' ? "Discount" : "Fixed Amount") : "No Discount"}</p>
                <p className='order-black-txttt'>{couponDetail ? (couponDetail.type === 'percentage' ? `${couponDetail.discount}%` : `Rs ${couponDetail.total_amount}`) : "Rs 0.00"}</p>
              </div>
            </Col>
            <Col md={12}>
              <div className='d-flex justify-content-between'>
                <p className='order-black-txttt'>Tax</p>
                <p className='order-black-txttt'>Rs0.00</p>
              </div>
            </Col>
            <Col md={12}>
              <div className='d-flex justify-content-between'>
                <p className='order-black-txttt'>TOTAL</p>
                <p className='order-black-txttt'>Rs {discountedPrice?.toFixed(2)}</p>
              </div>
            </Col>
            <Col md={12}>
              {location.pathname === "/ecommerce2/order-data" ? "" : (
                <InputGroup className="mb-4 mt-3">
                  <Form.Control
                    placeholder="Have coupon code? Apply here"
                    aria-label="Coupon Code"
                    aria-describedby="basic-addon2"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2" onClick={applyClick}>Apply</InputGroup.Text>
                </InputGroup>
              )}

              {message && (
                location.pathname === "/ecommerce2/order-data" ? (
                  ""
                ) : (
                  <p className='error-message'>{message}</p>
                )
              )}
            </Col>

            <Col md={12}>
              {signin && (
                location.pathname === "/ecommerce2/order-data" ? (
                  <button className='proceed-btn' onClick={() => navigate("/ecommerce2/shop-info")}>
                    Proceed to Address
                  </button>
                ) : (
                  ""
                )
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default OrderSummary;
