import { faArrowLeft, faCircleCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./ShoppingInfo.css";
import { Button, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Select from "react-select";

import { Link, useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import AddressPopUp from "../../CommonComponent/PopUp/AddressPopUp/AddressPopUp";
import OrderSummary from "../../OrderSummmary/OrderSummary";
import { Context } from "../../../utils/context";
import { getCart } from "../../../utils/apis/addToCart/addToCart";
import { Checkout, getAddress } from "../../../utils/apis/orderProcess/orderProcess";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const ShoppingInfo = ({ handlePrevious }) => {
    const [paymentMethod, setPaymentMethod] = useState("online");
    const { IMG_URL, signin, sellerId, setCartCount, triggercart, carttotalPrice } = useContext(Context);
    const [addressSelect, setAddressSelect] = useState("");
    const [activeKey, setActiveKey] = useState("0");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [coupanId, setCoupanId] = useState(null);
    const [discountinfo, setDiscountinfo] = useState(null);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(carttotalPrice);

    console.log(discountPrice, "disamounteddssssssss");

    const { control, register, handleSubmit, getValues, setError, clearErrors, setValue, formState: { errors }, reset, trigger } = useForm({
        defaultValues: {
            address_type: "Home",
        },
    });

    const [deliverycart, setDeliverycart] = useState([]);
    const cartGet = async () => {
        const res = await getCart(sellerId);
        if (res?.success) {
            setDeliverycart(res?.data);
            var total = 0;
            res?.data?.map((value) => {
                total += value?.selling_price * value.quantity;
            });
            setTotalPrice(total);
        }
    };

    useEffect(() => {
        if (signin && sellerId) {
            cartGet();
        }
    }, [sellerId, signin]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [address, setAddress] = useState([]);
    const AddressGet = async () => {
        const res = await getAddress();
        if (res?.success) {
            setAddress(res?.data);
        }
    };

    useEffect(() => {
        AddressGet();
    }, []);

    const handleMethod = async (value) => {
        await setPaymentMethod(value);
    };

    const navigate = useNavigate();
    const [Razorpay, openRazorpay] = useRazorpay();
    const [razorid, setRazorId] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState(0);
    const [checkoutid, setCheckoutid] = useState([]);
    const handleRazorpay = async (storedValue) => {
        console.log(storedValue, "ghjgfjh");
        const options = {
            key: "rzp_test_I05KCFb1v5JJRc",
            amount: Math.round(storedValue) * 100,
            currency: "INR",
            name: "Ecommerce",
            description: "Test Transaction",
            image: "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",
            handler: async (res) => {
                await setRazorId(res.razorpay_payment_id);
                finalFunction(res.razorpay_payment_id);
                console.log(res, "ttttttttttttttttttttttttttttt");
            },
            prefill: {
                name: "Saurav Khambayate",
                email: "youremail@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#052c65",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    const finalFunction = async (razor = null) => {
        const data = {
            address_id: addressSelect,
            amount: totalPrice,
            payment_mode: razor ? "online" : "cod",
            seller_id: sellerId,
            coupon_id: coupanId,
            discount: discountAmount
        };

        const res = await Checkout(data);
        if (res?.success) {
            navigate(`/ecommerce2/order-success/${res?.data?.order_id}`);
        }

        triggercart();
    };

    const handleAccordionSelect = (key) => {
        if (key === "1" && !addressSelect) {
            toast.error("Please select an address first.");
        } else {
            setActiveKey(key);
        }
    };

    useEffect(() => {
        cartGet();
    }, []);

    return (
        <>
            <Container>
                <section className="ShoppingInfo">
                    <Row>
                        <Col xxl={8} xl={8} lg={12} md={12}>
                            <div className="formouter_div mb-3 mt-3">
                                <h5 className="midtext mb-4">Address</h5>
                            </div>
                            <Accordion
                                activeKey={activeKey}
                                onSelect={(key) => setActiveKey(key)}
                                defaultActiveKey="0"
                                className="mt-4"
                            >
                                <Accordion.Item eventKey="0" onSelect={handleAccordionSelect}>
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                        <h1 className="title">Shopping Info</h1>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="manageprof_section">
                                            {address && address.length > 0 ? (
                                                <div className="address-main">
                                                    <div className="row">
                                                        {address.map((value, index) => (
                                                            <div className="col-lg-6" key={index}>
                                                                <div className="ro_divvv mb-3">
                                                                    <div className="addres_div d-flex">
                                                                        <Form.Check
                                                                            type="radio"
                                                                            name="addressType"
                                                                            className="me-3"
                                                                            onClick={() => {
                                                                                setAddressSelect(value?.id);
                                                                                setActiveKey("1");
                                                                            }}
                                                                        />
                                                                        <Row>
                                                                            <Col xxl={5} xl={2} lg={3} md={4}>
                                                                                <div className="lists">
                                                                                    <p className="text mb-1">Name:</p>
                                                                                    <p className="text mb-1">
                                                                                        Address:
                                                                                    </p>
                                                                                    <p className="text mb-1">
                                                                                        Postal Code:
                                                                                    </p>
                                                                                    <p className="text mb-1">City:</p>
                                                                                    <p className="text mb-1">State:</p>
                                                                                    <p className="text mb-1">
                                                                                        LandMark:
                                                                                    </p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xxl={7} xl={10} lg={9} md={8}>
                                                                                <div className="lists">
                                                                                    <p className="addtext text mb-1">
                                                                                        {value?.f_name} {value?.l_name}
                                                                                    </p>
                                                                                    <p className="addtext text mb-1">
                                                                                        {value?.address}
                                                                                    </p>
                                                                                    <p className="addtext text mb-1">
                                                                                        {value?.pincode?.name}
                                                                                    </p>
                                                                                    <p className="addtext text mb-1">
                                                                                        {value?.city?.name}
                                                                                    </p>
                                                                                    <p className="addtext text mb-1">
                                                                                        {value?.state?.name}
                                                                                    </p>
                                                                                    <p className="addtext text mb-1">
                                                                                        {value?.land_mark}
                                                                                    </p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="New-address mt-4" onClick={handleShow}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p className="text">Add New Address</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="mt-4">
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                        <h1 className="title">Delivery Info</h1>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className="midtext">In-house Products (01)</p>
                                        <div className="products">
                                            <Row>
                                                <Col xxl={6}>
                                                    {deliverycart.map((delivery, index) => (
                                                        <>
                                                            <div className="text-main mb-4">
                                                                <div className="image">
                                                                    <img
                                                                        src={IMG_URL + delivery?.thumbnail}
                                                                        className="store"
                                                                    />
                                                                </div>
                                                                <div className="main">
                                                                    <p className="sub-text">
                                                                        {delivery?.product_title}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="text-end">
                                            <Button
                                                type="button"
                                                className="paymentbtn"
                                                onClick={() => setActiveKey("2")}
                                            >
                                                Proceed to Payment
                                            </Button>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header onClick={() => handleAccordionSelect("1")}>
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                        <h1 className="title">Payment Info</h1>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="left_div mt-3">

                                            <Form className="Payment mt-3">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="d-flex justify-content-start align-items-center mb-2">
                                                            <input
                                                                type="radio"
                                                                value="cod"
                                                                name="gender"
                                                                className="me-3"
                                                                onClick={(e) => handleMethod(e.target.value)}
                                                            />
                                                            <p className="text">Cash On Delivery</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="d-flex justify-content-start align-items-center mb-2">
                                                            <input
                                                                type="radio"
                                                                value="online"
                                                                name="gender"
                                                                className="me-3"
                                                                defaultChecked
                                                                onClick={(e) => handleMethod(e.target.value)}
                                                            />
                                                            <p className="text">Online Payment</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>

                                            <div className="payment-method text-end mt-4">
                                                <ButtonCommon
                                                    text="Place Order"
                                                    className="order"
                                                    onClick={() => {
                                                        if (addressSelect) {
                                                            if (paymentMethod === "online") {
                                                                handleRazorpay(discountPrice);
                                                            } else {
                                                                finalFunction();
                                                            }
                                                        } else {
                                                            toast.error("Please select an address");
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col xxl={4} xl={4} lg={12} md={12}>
                            <OrderSummary
                                setTotalpr={setTotalPrice}
                                setDiscountPrice={setDiscountPrice}
                                setDiscountTotal={setDiscountTotal}
                                setDiscountAmount={setDiscountAmount}
                                setDiscountinfo={setDiscountinfo}
                                setCoupanId={setCoupanId}
                                totalPrice={totalPrice}
                            />
                        </Col>
                    </Row>
                </section>


            </Container>
            <AddressPopUp show={show} handleClose={handleClose} />
        </>
    );
};

export default ShoppingInfo;
