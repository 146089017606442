import React, { useState } from "react";
import "./AddToCard.css";
import { faCartShopping, faHeart, faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Addtocartmodal from "../PopUp/Addtocartmodal/Addtocartmodal";
import Heart_Component from "../Heart_Component/Heart_Component";

const AddToCard = ({ updateWishlist, image, text, title, price1, price2, productId }) => {
    const [modalShow, setModalShow] = useState(false);

    const [heartactive, setheartActive] = useState(false);
    const handleClickes = ({ data }) => {
        setheartActive(!heartactive);
    };
    return (
        <>
            <div className="Main-Cart">
                <button class="btn draw-border">
                    <div className="Card-product">
                        <div className="sec-image">
                            <Link to={`/ecommerce2/product-details/${productId}`}>
                                <div className="image-product">
                                    <img src={image} className="store" />
                                </div>
                            </Link>
                            <div className="add-cart-btn">
                                <Link to={`/ecommerce2/product-details/${productId}`}>
                                    <div className="cart">
                                        <p className="sub-text cart-text">Add To Cart</p>
                                        <p className="sub-text cart-icon">
                                            <FontAwesomeIcon icon={faCartShopping} className="icon" />
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="buttons-card">
                            {/* <Link to={`ecommerce2/wishlist/${productId}`}>
                                <div className="wishlist">
                                    <FontAwesomeIcon
                                        icon={faHeart}
                                        onClick={handleClickes}
                                        className={heartactive ? "gobiheartss" : "gobiheart"}
                                    />
                                </div>
                            </Link> */}

                            <Heart_Component productId={productId} updateWishlist={updateWishlist} />
                        </div>
                        <h1 className="sub-text">{title}</h1>

                        <div className="price">
                            <p className="text main1">₹ {price1}</p>
                            <p className="text main2">₹ {price2}</p>
                        </div>
                    </div>
                </button>
            </div>
            {/* <Addtocartmodal show={modalShow} onHide={() => setModalShow(false)} /> */}
        </>
    );
};

export default AddToCard;
