import React, { useContext, useEffect, useState } from "react";
import "./AllNotifications.css";
import { Container, Pagination } from "react-bootstrap";
import { Context } from "../../utils/context";
import { Notifications } from "../../utils/apis/orderProcess/orderProcess";

function AllNotifications() {
    const { sellerId, type } = useContext(Context);
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 15;

    useEffect(() => {
        const getNotification = async () => {
            const res = await Notifications(sellerId, type);
            if (res?.success) {
                setNotifications(res?.data);
                console.log(res?.data, "Notifications fetched");
            }
        };
        getNotification();
    }, [sellerId, type]);

    // Calculate the current notifications for the current page
    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);

    const totalPages = Math.ceil(notifications.length / notificationsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className="allnotification_section">
            <Container>
                <div className="allnotification_div">
                    <h3 className="midtext mb-4">Notifications</h3>
                    {currentNotifications.map((notification, index) => (
                        <div className="orderdiv" key={index}>
                            <p className="text mb-1">
                                {notification?.message}
                            </p>
                            <p className="datetext mb-0">{new Date(notification.createdAt).toLocaleString()}</p>
                        </div>
                    ))}
                   
                    <div className="pagi_div">
                        <Pagination>
                            <Pagination.Prev 
                                onClick={() => handlePageChange(currentPage - 1)} 
                                disabled={currentPage === 1}
                            />
                            {[...Array(totalPages).keys()].map((number) => (
                                <Pagination.Item 
                                    key={number + 1} 
                                    active={number + 1 === currentPage} 
                                    onClick={() => handlePageChange(number + 1)}
                                >
                                    {number + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next 
                                onClick={() => handlePageChange(currentPage + 1)} 
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default AllNotifications;
