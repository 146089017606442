import React from 'react';
import './CommonCoupon.css';
import { Link } from 'react-router-dom';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CommonCoupon({ coupondata }) {
  const copyCouponCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <section className='commoncopoun_section'>
      <div className='commoncoup_main ' style={{ background: coupondata.bgcol }}>
        <div className='toptext'>
          <p className='storename text mb-0'>
            {coupondata?.name}{' '}
            <Link to='/ecommerce2/sellersinner' className='ms-2 visitlink'>
              <span className='visitlk'>Visit Store</span>
            </Link>
          </p>
          <p className='offprice text'>₹{coupondata?.discount}</p>
        </div>
        <div className='bottomtext'>
          <p className='btmmmtext1 text pt-3'>{coupondata?.coupon_message}</p>
          <p className=' couponcopytxt midtext mb-0'>
            Code: {coupondata?.code} 
            <FontAwesomeIcon
              icon={faCopy}
              className='ms-2'
              onClick={() => copyCouponCode(coupondata?.code)}
              style={{ cursor: 'pointer' }}
            />
          </p>
        </div>
      </div>
    </section>
  );
}

export default CommonCoupon;
