import React, { useContext } from "react";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import './FlashSalesMain.css'
import Clock from "react-live-clock";
import "moment-timezone";
import "react-moment";
import { Context } from "../../../utils/context";

const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;
const daySeconds = hourSeconds * 24;

const FlashSalesMain = ({image}) => {
    const { IMG_URL } = useContext(Context);
    const timerProps = {
        isPlaying: true,
        size: 90,
        strokeWidth: 6,
    };

    const renderTime = (time) => (
        <div className="time-wrapper">
            <div className="title time">{time}</div>
        </div>
    );

    const getTimeSeconds = (time) => Math.ceil(time % minuteSeconds);
    const getTimeMinutes = (time) => Math.floor((time % hourSeconds) / minuteSeconds);
    const getTimeHours = (time) => Math.floor((time % daySeconds) / hourSeconds);
    const getTimeDays = (time) => Math.floor(time / daySeconds);

    const startTime = Date.now() / 1000;
    const endTime = startTime + 143201;
    const remainingTime = endTime - startTime;
    const daysDuration = Math.ceil(remainingTime / daySeconds) * daySeconds;
    return (
        <>
            <div className="Sale-Banner">
                <div className="Flash-sale">
                    <img src={IMG_URL + image} className="sale"/>
                </div>
                <div className="Sale-Timer">
                    <div className="all-times-bar">
                        <div className="timebox">
                            <div className="day  main-day mr-12">
                                <CountdownCircleTimer
                                    {...timerProps}
                                    colors={[["#d42d2a"], ["#0066FF"]]}
                                    isLinearGradient={true}
                                    duration={daysDuration}
                                    initialRemainingTime={remainingTime}
                                    trailColor="#e7e5e5"
                                >
                                    {({elapsedTime}) => renderTime(getTimeDays(daysDuration - elapsedTime))}
                                </CountdownCircleTimer>

                                <h3 className="title">Days</h3>
                            </div>
                        </div>
                        <div className="timebox">
                            <div className="hours  main-day mr-12">
                                <CountdownCircleTimer
                                    {...timerProps}
                                    colors={[["#d42d2a"], ["#0066FF", 1]]}
                                    isLinearGradient={true}
                                    duration={daySeconds}
                                    initialRemainingTime={remainingTime % daySeconds}
                                    onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > hourSeconds]}
                                    trailColor="#e7e5e5"
                                >
                                    {({elapsedTime}) => renderTime(getTimeHours(daySeconds - elapsedTime))}
                                </CountdownCircleTimer>
                                <h3 className="title">HRS</h3>
                            </div>
                        </div>
                        <div className="timebox">
                            <div className="minutes  main-day mr-12">
                                <CountdownCircleTimer
                                    {...timerProps}
                                    colors={[["#d42d2a"], ["#0066FF", 1]]}
                                    isLinearGradient={true}
                                    duration={hourSeconds}
                                    initialRemainingTime={remainingTime % hourSeconds}
                                    onComplete={(totalElapsedTime) => [
                                        remainingTime - totalElapsedTime > minuteSeconds,
                                    ]}
                                    trailColor="#e7e5e5"
                                >
                                    {({elapsedTime}) => renderTime(getTimeMinutes(hourSeconds - elapsedTime))}
                                </CountdownCircleTimer>
                                <h3 className="title">MIN</h3>
                            </div>
                        </div>
                        <div className="timebox">
                            <div className="seconds  main-day mr-12">
                                <CountdownCircleTimer
                                    {...timerProps}
                                    colors={[["#d42d2a"], ["#0066FF", 1]]}
                                    isLinearGradient={true}
                                    duration={minuteSeconds}
                                    initialRemainingTime={remainingTime % minuteSeconds}
                                    onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > 0]}
                                    trailColor="#e7e5e5"
                                >
                                    {({elapsedTime}) => renderTime(getTimeSeconds(elapsedTime))}
                                </CountdownCircleTimer>
                                <h3 className="title">SEC</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlashSalesMain;
