import React, { useState } from 'react'
import './TrackOrder.css'
import { Col, Container, Form, Row } from 'react-bootstrap'
// import Button from '../CommonComponent/Button/Button'
import ButtonCommon from '../CommonComponent/ButtonCommon/ButtonCommon'
import Breadcrumbs from '../CommonComponent/Breadcrumbs/Breadcrumbs';
function TrackOrder() {

    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Track Order", link: "#"},
        ,
    ]);
  return (
    <>
    <section className='track_section'>
        <Container>
           
                <div>
                    <div className='d-flex justify-content-between '>
                    <h2 className='title mb-4'>Track Order</h2>
                    <div>
                                <Breadcrumbs items={breadcrumbItems} />
                            </div>
                    </div>
                    
                    <Row>
                        <Col xxl={6} xl={7} lg={8} md={10} className="mx-auto">
                        <div>
                            <div className='checkstate text-center'>
                            <h6 className='midtext mb-0'>Check Your Order Status</h6>
                            </div>
                            <div className='formdiva text-end'>
                                <Form>
                                <Form.Control type="text" placeholder="Order Code"  className='mb-3'/>
                                </Form>
                                <ButtonCommon text="Track order" className="trackbtn"/>
                            </div>
                

                </div>

                        </Col> 
                    </Row>
                </div>
            
        </Container>
    </section>
    </>
  )
}

export default TrackOrder