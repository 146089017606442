import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import "./WishlistDashBoard.css";
import {
    faCartShopping,
    faHeart,
    faArrowRightArrowLeft,
    faTrash,
    faHouse,
    faClipboard,
    faDownload,
    faCodeCompare,
    faDollarSign,
    faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import DashBoardTab from "../DashBoardTab/DashBoardTab";
import LatestViews from "../../CommonComponent/LatestViews/LatestViews";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import PurchaseHistory from "../PurchaseHistory/PurchaseHistory";
import RefundRequest from "../RefundRequest/RefundRequest";
import WishlistTab from "../WishlistTab/WishlistTab";
import ManageProfile from "../ManageProfile/ManageProfile";
import Compare from "../../Compare/Compare";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClassifiedProduct from "../ClassifiedProduct/ClassifiedProduct";
import MyWallet from "../MyWallet/MyWallet";
import { UserGet, orderGet } from "../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";

const Wishlist = () => {
    const [show, setShow] = useState(false);
    // const [activeKey, setActiveKey] = useState("first");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { IMG_URL, sellerId, signin, setSignin, setUsertype, setActiveTab, activeTab } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        defaultValues: {
            address_type: "Home",
        },
    });

    const [orders, setOrder] = useState([]);

    const getOrders = async () => {
        const res = await orderGet(sellerId);
        if (res?.success) {
            setOrder(res?.data);
            console.log(res?.data, "orderdata");
        }
    };
    const navigate = useNavigate();

    useEffect(() => {
        getOrders();
    }, []);

    const handleLogout = async () => {
        Cookies.remove("ecommerce2_security", { path: "/" });
        await setSignin(false);
        await setUsertype("");
        await navigate("/ecommerce2/login");
    };

    const [userData, setUserData] = useState();
    const getUser = async () => {
        const res = await UserGet();
        if (res?.success) {
            setUserData(res.data);
            console.log(res?.data, "userdata");
            reset(res?.data);
        }
    };





    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            {signin ? (
                <>
                    <section className="Wishlist">
                        <Container>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                                <Row>
                                    <Col xxl={3} xl={3} lg={12}>
                                        <div className="main-dashboard">
                                            <div className="profile">
                                                <div className="main-image">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/assests/images/Wishlist/men.webp"
                                                        }
                                                        className="login-image"
                                                    />
                                                </div>
                                                <p className="text">
                                                    {userData?.first_name} {userData?.last_name}
                                                </p>
                                                <p className="sub-text">{userData?.contact_no}</p>
                                            </div>

                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">
                                                        <div className="main-icon">
                                                            <FontAwesomeIcon icon={faHouse} className="dash-icon" />
                                                            <p className="sub-text">Dashboard</p>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">
                                                        <div className="main-icon">
                                                            <FontAwesomeIcon icon={faClipboard} className="dash-icon" />
                                                            <p className="sub-text">Purchase History</p>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>
                                            <Nav.Link eventKey="third">
                                                <div className="main-icon">
                                                    <FontAwesomeIcon icon={faDownload} className="dash-icon" />
                                                    <p className="sub-text">Downloads</p>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item> */}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">
                                                        <div className="main-icon">
                                                            <FontAwesomeIcon
                                                                icon={faDollarSign}
                                                                className="dash-icon"
                                                            />
                                                            <p className="sub-text">Refund Request</p>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth">
                                                        <div className="main-icon">
                                                            <FontAwesomeIcon icon={faHeart} className="dash-icon" />
                                                            <p className="sub-text">Wishlist</p>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>

                                                {/* <Nav.Item>
                                                    <Nav.Link eventKey="six">
                                                        <div className="main-icon">
                                                            <FontAwesomeIcon
                                                                icon={faProductHunt}
                                                                className="dash-icon"
                                                            />
                                                            <p className="sub-text">My Wallet</p>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item> */}
                                                {/* <Nav.Item>
                                            <Nav.Link eventKey="seven">
                                                <div className="main-icon">
                                                    <FontAwesomeIcon icon={faProductHunt} className="dash-icon" />
                                                    <p className="sub-text">Earing Points</p>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item> */}

                                                <Nav.Item>
                                                    <Nav.Link eventKey="eight">
                                                        <div className="main-icon">
                                                            <FontAwesomeIcon
                                                                icon={faProductHunt}
                                                                className="dash-icon"
                                                            />
                                                            <p className="sub-text">Manage Profile</p>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item onClick={handleShow}>
                                            <Nav.Link eventKey="nine">
                                                <div className="main-icon">
                                                    <FontAwesomeIcon icon={faProductHunt} className="dash-icon" />
                                                    <p className="sub-text">Delete My Account</p>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item> */}
                                            </Nav>
                                            <div className="sign-out">
                                                <ButtonCommon
                                                    className="sign"
                                                    text={signin ? "Sign Out" : "Sign In"}
                                                    onClick={handleLogout}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={9} xl={9} lg={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <DashBoardTab orders={orders} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <PurchaseHistory getOrders={getOrders} orders={orders} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third"></Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <RefundRequest />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={activeTab}>
                                                <WishlistTab />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="six">
                                                <MyWallet />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="seven">
                                                {/* <EarningPoints /> */}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="eight">
                                                <ManageProfile />
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="nine"></Tab.Pane> */}
                                            {/* <Tab.Pane eventKey="fifteen">
                                        <ManageProfile />
                                    </Tab.Pane> */}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>

                            <Col xxl={9}></Col>
                        </Container>
                    </section>
                </>
            ) : (
                <>
                    <section className="Login-First">
                        <Container>
                            <h3 className="title">You need to login first</h3>
                            <Link to={"/ecommerce2/login"}>
                                <div className="Sign-In">
                                    <ButtonCommon className="sign" text="Sign In" />
                                </div>
                            </Link>
                        </Container>
                    </section>
                </>
            )}
            {/* <LatestViews /> */}
            <div>
                <Modal size="lg" show={show} onHide={handleClose} centered className="deletemaodl">
                    <Modal.Header>
                        <div className="mod_heads mb-3">
                            <div className="deleteimg_div mx-auto">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/images/Wishlist/placeholder.webp"}
                                    className="deleteimg"
                                />
                            </div>
                            <h2 className="title">Delete Your Account</h2>
                            <h4 className="midtext">Warning: You cannot undo this action</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bodymian">
                            <div className="mod_bodys mb-2">
                                <p className="text donttxt">
                                    Note: Don't Click to any button or don't do any action during account Deletion, it
                                    may takes some times.
                                </p>
                                <p className="text dlttext">Deleting Account Means:</p>
                                <div className="warnig_div mb-2">
                                    <div className="warndivimg">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/images/Wishlist/warning.webp"}
                                            className="warnimg"
                                        />
                                    </div>
                                    <div className="texttdiv">
                                        <p className="text mb-0">
                                            If you create any classified ptoducts, after deleting your account, those
                                            products will no longer in our system
                                        </p>
                                    </div>
                                </div>
                                <div className="warnig_div mb-2">
                                    <div className="warndivimg">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/images/Wishlist/warning.webp"}
                                            className="warnimg"
                                        />
                                    </div>
                                    <div className="texttdiv">
                                        <p className="text mb-0">
                                            If you create any classified ptoducts, after deleting your account, those
                                            products will no longer in our system
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footerbtnsdiv">
                            <ButtonCommon text="Cancel" className="footerbtn btncancel me-2" />
                            <ButtonCommon text="Delete Account" className="footerbtn accbtn" />
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default Wishlist;
