import React, { useState } from "react";
import "./Cancle_Reason_Modal.css";
import { Button, Form, Modal } from "react-bootstrap";
import { OrderCancel } from "../../../utils/apis/orderProcess/orderProcess";
import { useNavigate } from "react-router-dom";

function Cancle_Reason_Modal(props) {
  const navigate = useNavigate();
  const handleReason = async (d) => {
    await props.setReason(d);
    await props.setError('');
    props.onHide();
  }
  const [reason, setReason] = useState('');
  const [remark, setRemark] = useState('');
  const [error, setError] = useState('');
  const handleSubmit = async () => {
    if (reason) {
      const data = {
        order_id: props.data?.id,
      }

      data.reason = reason;

      if (remark) {
        data.remark = remark;
      }
      const res = await OrderCancel(data);
      if (res?.success) {
        // setModalShow(true);
        props.getOrders();
        setTimeout(() => {
          // setModalShow(false);
          props.onHide();
          navigate("/my-account");
        }, 3000);
      }
    } else {
      setError('Select Reason for cancellation');
    }

  }
  return (
    <>
      <div className="Reason_Modal">
        <Modal
          className=" Cancel_Reason_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            {" "}
            Select a reason for cancellation
          </Modal.Header>
          <Modal.Body>
            <div className="select_reason">
              <Form>
                <Form.Check
                  label="Product price has reduced"
                  name="name"
                  type="radio" value="Product price has reduced"
                  onClick={() => handleReason("Product price has reduced")}
                  defaultChecked={props.reason === "Product price has reduced"}
                />
                <Form.Check
                  label="Wrong contact number entered"
                  name="name"
                  type="radio" value="Wrong contact number entered"
                  onClick={() => handleReason("Wrong contact number entered")}
                  defaultChecked={props.reason === "Wrong contact number entered"}
                />
                <Form.Check label="Ordered by mistake" name="name" type="radio" value="Ordered by mistake"
                  onClick={() => handleReason("Ordered by mistake")}
                  defaultChecked={props.reason === "Ordered by mistake"}
                />
                <Form.Check label="Incorrect product size/colour/type ordered" name="name" type="radio" value="Incorrect product size/colour/type ordered"
                  onClick={() => handleReason("Incorrect product size/colour/type ordered")}
                  defaultChecked={props.reason === "Incorrect product size/colour/type ordered"} />
                <Form.Check label="Purchased product from somewhere else" name="name" type="radio" value="Purchased product from somewhere else"
                  onClick={() => handleReason("Purchased product from somewhere else")}
                  defaultChecked={props.reason === "Purchased product from somewhere else"} />
                <Form.Check label="Wrong address selected" name="name" type="radio" value="Wrong address selected"
                  onClick={() => handleReason("Wrong address selected")}
                  defaultChecked={props.reason === "Wrong address selected"} />
                <Form.Check label="Product not required anymore" name="name" type="radio" value="Product not required anymore"
                  onClick={() => handleReason("Product not required anymore")}
                  defaultChecked={props.reason === "Product not required anymore"} />
                <Form.Check label="Incorrect payment method selected" name="name" type="radio" value="Incorrect payment method selected"
                  onClick={() => handleReason("Incorrect payment method selected")}
                  defaultChecked={props.reason === "Incorrect payment method selected"} />
                <Form.Check label="Expected delivery time is too long" name="name" type="radio" value="Expected delivery time is too long"
                  onClick={() => handleReason("Expected delivery time is too long")}
                  defaultChecked={props.reason === "Expected delivery time is too long"} />
                {/* <Form.Check label="Other" name="name" type="radio"  value="Ordered by mistake"
                onClick={() => handleReason("Ordered by mistake")}/> */}


                <Button className="submitbtn" type="button" onClick={handleSubmit}>Submit</Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Cancle_Reason_Modal;
