import React from 'react'
import WishlistDashBoard from "./WishlistDashBoard/WishlistDashBoard"
import './WishlistMain.css'
const WishlistMain = () => {
  return (
    <>
      <WishlistDashBoard/>
    </>
  )
}

export default WishlistMain
