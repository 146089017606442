import React, { useContext, useEffect, useState } from "react";
import "./Coupons.css";
import { Col, Container, Row } from "react-bootstrap";
import CommonCoupon from "../CommonComponent/CommonCoupon/CommonCoupon";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Context } from "../../utils/context";
import { CouponsProduct } from "../../utils/apis/master/Master";
function Coupons() {
    const coupondata = [
        {
            name: "Filon Asset Store ",
            off: "$50.000 OFF",
            text: "Min Spend $150.000 from Filon Asset Store to get 20% OFF on total orders",
            code: " MAS150 ",
            bgcol: "linear-gradient(to right, #7cc4c3 0%, #479493 100%)",
        },
        {
            name: "Filon Asset Store ",
            off: "$50.000 OFF",
            text: "Min Spend $150.000 from Filon Asset Store to get 20% OFF on total orders",
            code: " MAS150 ",
            bgcol: "linear-gradient(to right, #7cc4c3 0%, #479493 100%)",
        },
        {
            name: "Filon Asset Store ",
            off: "$50.000 OFF",
            text: "Min Spend $150.000 from Filon  OFF on total orders",
            code: " MAS150 ",
            bgcol: "linear-gradient(to right, #e2583e 0%, #bf1931 100%)",
        },
        {
            name: "Filonddddd Asset Store ",
            off: "$50.000 OFF",
            text: "Min Spend $150.000 from Filon Asset Store to get 20% OFF on total orders",
            code: " MAS150 ",
            bgcol: "linear-gradient(to right, #e2583e 0%, #bf1931 100%)",
        },
        {
            name: "Filon Asset Store ",
            off: "$50.000 OFF",
            text: "Min Spend $150.000 from Filon Asset Store to get 20% OFF on total orders",
            code: " MAS150 ",
            bgcol: "linear-gradient(to right, #7cc4c3 0%, #479493 100%)",
        },
    ];
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "All Coupons", link: "#" },
        ,
    ]);


    const [coupenproduct, setCoupenProduct] = useState([]);

    const { sellerId, IMG_URL } = useContext(Context);

    const getCoupenProduct = async () => {
        const res = await CouponsProduct(sellerId);
        if (res?.success) {
            setCoupenProduct(res?.data);
        }
    };
    useEffect(() => {
        getCoupenProduct();
    }, []);
    return (
        <section className="coupoons_section ">
            <Container>
                <div className="coupons_main">
                    <div className="main-block">
                        <h1 className="title">All Coupons</h1>

                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                    </div>
                    <Row>
                        {coupenproduct.map((coupon, index) => (
                            <Col xxl={4} xl={4} lg={6} md={6} className="mb-3">
                                <div>
                                    <CommonCoupon coupondata={coupon} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default Coupons;
