import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import "./Ordercanceldata.css";
import ButtonCommon from '../CommonComponent/ButtonCommon/ButtonCommon';
import { Link, useNavigate } from 'react-router-dom';
import { OrderCancel } from '../../utils/apis/orderProcess/orderProcess';
import { Context } from '../../utils/context';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cancle_Reason_Modal from '../CommonComponent/Cancle_Reason_Modal/Cancle_Reason_Modal';
import Success_Modalss from '../CommonComponent/PopUp/Success_Modal/Success_Modalss';
const Ordercanceldata = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);
    const navigate = useNavigate();
    const { IMG_URL } = useContext(Context);

    const [reason, setReason] = useState('');
    const [remark, setRemark] = useState('');
    const [error, setError] = useState('');
    const handleSubmit = async () => {
        if (reason) {
            const data = {
                order_id: props.data?.id,
            }

            data.reason = reason;

            if (remark) {
                data.remark = remark;
            }
            const res = await OrderCancel(data);
            if (res?.success) {
                setModalShow(true);
                props.getOrders();
                setTimeout(() => {
                    setModalShow(false);
                    props.onHide();
                    navigate("/");
                }, 3000);
            }
        } else {
            setError('Select Reason for cancellation');
        }

    }
    return (
        <>

            <div className="Order_Cancle my-account">
                <div className="container">
                    <div className="product-section-div">
                        <div className="product-div">
                            <div className="purchase-product">
                                {props.data?.o_w_orders_details?.map((detail, detailIndex) => (
                                    <div className="">
                                        <div className="product">
                                            <img
                                                className="img-set"
                                                src={
                                                    IMG_URL +
                                                    detail?.s_product_variant_attribute_detail?.thumbnail
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="text-holder">
                            <p className='midtext'>Order ID <span className='sub-text'>{props.data?.invoice_number}</span></p>
                            <h4 className='text'>Purchased Items {props.data?.o_w_orders_details.length} </h4>
                            {/* <span>(Approx. 600 -1000 gm)</span> */}
                            <h4 className='price'>Total Price ₹ {props.data?.amount}</h4>
                        </div>
                    </div>

                    <div
                        className="Cancellation"
                        onClick={() => setModalCanleReasonShow(true)}
                    >
                        <p className='sub-text'>
                           <span className='select'>Select</span>  a reason for cancellation
                            <FontAwesomeIcon
                                className="ms-2"
                                icon="fa-solid fa-angle-right"
                            />
                        </p>
                    </div>

                    <div className="Cancellation  Cancellation-reason mt-4">
                        <p className='midtext'>
                            Could you tell us a reason for cancellation?
                            <span>(optional)</span>
                        </p>

                        <Form>
                            <Form.Control
                                as="textarea"
                                role="1"
                                placeholder="Type a reason"
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Form>

                        <div className="btn-holder">
                            <Link>
                                <ButtonCommon text="Cancel" className="cancel-btn" onClick={() => props.setOrdercancel(true)}></ButtonCommon>
                            </Link>

                            <ButtonCommon
                                onClick={handleSubmit}
                                className="submit-btn ms-3"
                                text="Submit"
                            >

                            </ButtonCommon>
                        </div>
                        <div className="btn-holder">
                            <sub className="text text-danger">{error}</sub>
                        </div>
                    </div>
                </div>
            </div>
            <Cancle_Reason_Modal
                reason={reason}
                setReason={setReason}
                show={modalCanleReasonShow}
                setError={setError}
                onHide={() => setModalCanleReasonShow(false)}
            />

            <Success_Modalss texts=" Your order has been canceled successfully "
                show={modalShow}
                onHide={() => setModalShow(false)} />
        </>
    )
}

export default Ordercanceldata