import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
import './PrivacyPolicy.css'
import { Context } from "../../../utils/context";
import { GetPrivacyPolicy } from "../../../utils/apis/master/Master";
import parse from "html-react-parser";

const PrivacyPolicy = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "privacy policy", link: "#"},
        ,
    ]);


    const [policy, setPolicy] = useState(null);
    const { sellerId } = useContext(Context);

    const getPolicy = async () => {
        try {
            const res = await GetPrivacyPolicy(sellerId);
            if (res?.success) {
              setPolicy(res?.data);
                console.log(res?.data);
            } else {
               
            }
        } catch (error) {
            console.error("Error fetching privacy and policy:", error);
        }
    };

    useEffect(() => {
      getPolicy();
    }, [sellerId]); 

  return (
    <>
      <section className="PrivacyPolicy">
        <Container>
          <div className="title-main">
            <h1 className="title">Privacy Policy Page</h1>
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
          
          </div>
          {policy && policy.name && <p className="sub-text-text">{parse(policy.name)}</p>}
          {/* <h1 className="title">Privacy Policy</h1>
          <p className="text">Our Privacy Policy was last updated on [DATE].</p>
          <p className="text">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
          <p className="text">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy was generated by TermsFeed Ecommerce Privacy Policy Generator.</p>

        <h1 className="title">Interpretation and Definitions</h1>
        <p className="text">Interpretation</p>
        <p className="text">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <h1 className="title">Definitions</h1>
        <p className="text">For the purposes of this Privacy Policy:</p> */}
        </Container>
      </section>
    </>
  );
};

export default PrivacyPolicy;
