import React, { useContext, useState } from "react";
import "./Register.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { faArrowLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import "react-phone-input-2/lib/style.css";
import {
  faApple,
  faFacebookF,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import { Context } from "../../../utils/context";
import { useForm } from "react-hook-form";
import { userRegister } from "../../../utils/apis/login/Login";
import Cookies from "js-cookie";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";

function Register() {
  const [showEmail, setShowEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const navigate = useNavigate();
  const toggleInput = () => {
    setShowEmail(!showEmail);
  };

  const { signin, setSignin, sellerId } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    if (data.password === data.re_password) {
      data.seller_id = sellerId;
      data.contact_no = phoneValue;
      const res = await userRegister(data);
      if (res?.success) {
        await Cookies.set("ecommerce2_security", res?.data?.token);
        setSignin(true);
        reset();
        navigate("/");
      } else {
        if (res?.message?.both) {
          setError("email", { message: res?.message?.both });
          setError("contact_no", { message: "" });
        }
        if (res?.message?.email) {
          setError("email", { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
      }
    } else {
      setError("password", { message: "Password does not match" });
    }
  };

  return (
    <section className="login_section">
      <Container fluid>
        <div>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <div className="logincardiv">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/images/Login/cartimg.webp"
                  }
                  className="logincartimgdiv"
                />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <div className="baggingmain">
                <Row>
                  <Col xxl={6} xl={9} lg={10} className="p-4 p-lg-4">
                    <div>
                      <div className="baggimg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/images/Login/bagge.svg"
                          }
                          className="bagimg"
                        />
                      </div>
                      <div className="">
                        <h1 className="title">CREATE AN ACCOUNT.</h1>
                      </div>

                      <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="text">Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Full Name"
                              {...register("first_name", {
                                required: "First name required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.first_name,
                                "is-valid": getValues("first_name"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                          <div>
                          
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="text">Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="name@example.com"
                                  {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value: RegxExpression.email,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.email,
                                    "is-valid": getValues("email"),
                                  })}
                                />
                              </Form.Group>
                           
                                <Form.Label className="text">Phone</Form.Label>
                                <PhoneInput
                                  className="mb-3"
                                  country={"in"}
                                  value={phoneValue}
                                  onChange={setPhoneValue}
                                  inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true,
                                  }}
                                />
                              
                           
                          </div>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="text">Password</Form.Label>
                            <div className="fcont_div">
                              <Form.Control
                                type="password"
                                placeholder="password"
                                {...register("password", {
                                  required: "Password is required",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must be at least 8 characters long",
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                    message:
                                      "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                  },
                                })}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <FontAwesomeIcon icon={faEye} className="eyicn" />
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="text">
                              Confirm Password
                            </Form.Label>
                            <div className="fcont_div">
                              <Form.Control
                                type="password"
                                placeholder="password"
                                {...register("re_password", {
                                  required: "Re-Password is required",
                                })}
                                onChange={(e) =>
                                  setPasswordAgain(e.target.value)
                                }
                              />
                              <FontAwesomeIcon icon={faEye} className="eyicn" />
                            </div>
                          </Form.Group>

                          {password && (
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "match",
                              ]}
                              minLength={8}
                              value={password}
                              valueAgain={passwordAgain}
                              onChange={(isValid) => { }}
                              style={{
                                fontSize: "10px",
                                padding: "4px",
                                margin: "0",
                              }}
                            />
                          )}

                          <div className="d-flex checkformcc mt-2">
                            <Form.Check
                              type="checkbox"
                              id="custom-switch"
                              label="By signing up you agree to our "
                              className="sub-text"
                            />
                            <Link to="/forgotpassword">
                              <p className="sub-text ms-1">
                                terms and conditions
                              </p>
                            </Link>
                          </div>
                          <ButtonCommon
                            buttontype="submit"
                            text="Create Account"
                            className="loginbtn"
                          />

                          <div className="mt-3">
                            <p className="sub-text text-center mb-1">
                              Or Login With
                            </p>
                            <div className="loginicnndiv">
                              <Link>
                                <div className="fonicndv fonicndv1">
                                  <FontAwesomeIcon
                                    icon={faFacebookF}
                                    className="ficnn"
                                  />
                                </div>
                              </Link>
                              <Link>
                                <div className="fonicndv fonicndv2">
                                  <FontAwesomeIcon
                                    icon={faGoogle}
                                    className="ficnn"
                                  />
                                </div>
                              </Link>
                              <Link>
                                <div className="fonicndv fonicndv3">
                                  <FontAwesomeIcon
                                    icon={faTwitter}
                                    className="ficnn"
                                  />
                                </div>
                              </Link>
                              <Link>
                                <div className="fonicndv fonicndv4">
                                  <FontAwesomeIcon
                                    icon={faApple}
                                    className="ficnn"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Register;
