import { Table } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
import "./PurchaseHistory.css";
import ButtonCommon from "../../CommonComponent/ButtonCommon/ButtonCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-bootstrap/Pagination";
import { Container } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { orderGet } from "../../../utils/apis/myAccount/myAccount";
import { useNavigate } from "react-router-dom";
import Cancle_Reason_Modal from "../../CommonComponent/Cancle_Reason_Modal/Cancle_Reason_Modal";
import Ordercanceldata from "../../Ordercanceldata/Ordercanceldata";

const PurchaseHistory = ({ getOrders, orders }) => {
    const [modalShow, setModalShow] = useState(false);
    const [ordercancel, setOrdercancel] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [data, setData] = useState();
    const handleCLickdelete = (value) => {
        setData(value)
        setOrdercancel(false)
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(orders.length / itemsPerPage);

    return (
        <>
            <Container>
                <section className="PurchaseHistory">
                    <p className="title">Purchase History</p>
                    <div className="Main-purchase">

                        {ordercancel ? (
                            <>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="sub-text-text">Code</th>
                                            <th className="sub-text-text">Date</th>
                                            <th className="sub-text-text">Amount</th>
                                            <th className="sub-text-text">Delivery Status</th>
                                            <th className="sub-text-text">Payment Status</th>
                                            <th className="sub-text-text options">Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems?.map((orders, index) => (
                                            <>
                                                <tr>
                                                    <td className="code">{orders?.invoice_number}</td>
                                                    <td className="date">{new Date(orders?.createdAt).toLocaleDateString('en-US', {
                                                        day: 'numeric', month: 'short', year: 'numeric'
                                                    })}</td>
                                                    <td className="amount">₹{orders?.amount}</td>
                                                    <td className="amount">{orders?.o_w_order_status?.name}</td>
                                                    <td>
                                                        <ButtonCommon className="unpaid" text={orders?.o_w_payment_status?.status === 1 ? "Paid" : "Unpaid"} />
                                                    </td>
                                                    <td>
                                                        <div className="main">

                                                            <div className="buttons">
                                                                <div className="delete">
                                                                    <FontAwesomeIcon icon={faTrash} className="delete-icon" onClick={() => { handleCLickdelete(orders) }}></FontAwesomeIcon>
                                                                </div>
                                                               
                                                                <div className="download">
                                                                    <FontAwesomeIcon icon={faDownload} className="download-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </>
                                        ))}



                                    </tbody>
                                </table>

                                <Pagination>
                                    <Pagination.Prev
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    />
                                    {[...Array(totalPages)].map((_, pageIndex) => (
                                        <Pagination.Item
                                            key={pageIndex + 1}
                                            active={pageIndex + 1 === currentPage}
                                            onClick={() => handlePageChange(pageIndex + 1)}
                                        >
                                            {pageIndex + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    />
                                </Pagination>

                            </>
                        ) : (
                            <>

                                <Ordercanceldata onHide={() => setModalShow(false)} data={data} getOrders={getOrders} setOrdercancel={setOrdercancel} />

                            </>
                        )}


                    </div>
                </section>
            </Container>
        </>
    );
};

export default PurchaseHistory;
