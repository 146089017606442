import React, {useEffect, useState, useContext} from "react";
import {Container} from "react-bootstrap";
import "./Deal.css";
import {Swiper, SwiperSlide} from "swiper/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "swiper/css";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Autoplay} from "swiper/modules";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { GetTodayDeal } from "../../../utils/apis/master/Master";

const Deal = () => {
    const { sellerId, IMG_URL } = useContext(Context);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const Deal = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d1.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d5.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d7.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d1.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            saleprice: "₹3450.00",
            text: "₹5000.0",
        },
    ];


    const [todaydeal, setTodayDeal] = useState([]);
    const gettodaydeal = async () => {
        const res = await GetTodayDeal(sellerId);
        if (res?.success) {
            setTodayDeal(res?.data);
        } else {

        }
    }
    
    useEffect(() => {
        gettodaydeal();
    }, []);



    return (
        <>
            <section className="Deal">
                <Container>
                    <div className="deal-image">
                        <img
                            src={process.env.PUBLIC_URL + "/assests/images/Home/Deal/DealBanner.png"}
                            className="deal"
                        />
                    </div>
                    <div className="Deal-sale">
                    <Link to="/ecommerce2/todays-deal">
                        <p className="sub-text">View All</p>
                        </Link>
                        <Swiper
                            className="mySwiper"
                            pagination={true}
                            modules={[Autoplay]}
                            // autoplay={{
                            //     delay: 2000,
                            //     disableOnInteraction: false,
                            // }}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                380: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                485: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                575: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                992: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 7,
                                    spaceBetween: 30,
                                },
                                1440: {
                                    slidesPerView: 7,
                                    spaceBetween: 30,
                                },
                                2000: {
                                    slidesPerView: 7,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            {todaydeal.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="main">
                                        <div className="product-deal">
                                            <div className="product">
                                                <img src={IMG_URL + item?.thumbnail} className="deal-product" />
                                            </div>
                                        </div>
                                    </div>

                                    <p className="text main1">₹{item?.mrp}</p>
                                    <p className="text main2">₹{item?.selling_price}</p>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="silder-btn">
                            <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                            </div>
                            <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Deal;
