import React, { useContext, useEffect, useState } from "react";
import "./OrderData.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import OrderSummary from "../../OrderSummmary/OrderSummary";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { RemoveToCart, getCart } from "../../../utils/apis/addToCart/addToCart";
import IncreamentDecreament from "../../CommonComponent/IncreamentDecreament/IncreamentDecreament";
import Cookies from 'js-cookie';

const OrderData = () => {
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartsignin, setCartsignin] = useState([]);
    const navigate = useNavigate();
    const { IMG_URL, signin, sellerId, triggercart } = useContext(Context);
    const [cart, setCart] = useState([]);

    const cartGet = async () => {
        if (signin) {
            const res = await getCart(sellerId);
            if (res?.success) {
                setCartsignin(res?.data);
            }
        } else {
            if (Cookies.get('ecommerce2_cart')) {
                setCart(JSON.parse(Cookies.get('ecommerce2_cart')));
            }
        }
    };

    useEffect(() => {
        cartGet();
    }, [signin, sellerId, triggercart]);

    useEffect(() => {
        const calculateTotalPrice = () => {
            let price = 0;
            if (signin) {
                cartsignin.forEach(product => {
                    price += product.selling_price * product.quantity;
                });
            } else {
                cart.forEach(product => {
                    price += product.price * product.quantity;
                });
            }
            setTotalPrice(price);
        };

        calculateTotalPrice();
    }, [cartsignin, cart, signin]);

    const removeFromCart = async (id, index) => {
        console.log(id, "prodcid");
        try {
            if (signin) {
                const res = await RemoveToCart(id);
                if (res?.success) {
                    cartGet();
                    triggercart(); // Ensure this is correctly updating the cart context/state
                } else {
                    console.error('Failed to remove item from cart:', res);
                }
            } else {
                let updatedCart = [...cart];
                updatedCart.splice(index, 1);
                setCart(updatedCart);
                Cookies.set('ecommerce2_cart', JSON.stringify(updatedCart));
                triggercart();
            }
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    };

    return (
        <Container>
            {cart.length === 0 && cartsignin.length === 0 ? (
                <div className="heading_holder">
                    <h5 className="title">Your cart is Empty</h5>
                    <p className="sub-text">You have no items in your shopping cart. Let's go buy something</p>
                    <div className="main-btn-div">
                        <Link to={"/"}>
                            <div className="Shop">
                                <button className="back-btn" type="button">
                                    Shop Now
                                </button>
                            </div>
                        </Link>
                    </div>
                </div>
            ) : (
                <section className="order-data">
                    <Row>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12}>
                            {signin ? (
                                cartsignin?.map((product, index) => (
                                    <Row className="cartdesign mt-2 mb-4" key={product.id}>
                                        <Col xxl={11}>
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6}>
                                                    <div className="product-data">
                                                        <div className="cart-image">
                                                            <img
                                                                src={IMG_URL + product.thumbnail}
                                                                className="pro-imggggg"
                                                                alt={product.product_title}
                                                            />
                                                        </div>
                                                        <div className="text">
                                                            <p className="sub-text">{product.product_title}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={6} xl={6} lg={6} md={6}>
                                                    <div className="product">
                                                        <div className="ms-2">
                                                            <p className="sub-text">Price</p>
                                                            <span className="text">₹{product.selling_price}</span>
                                                        </div>
                                                        <IncreamentDecreament
                                                            quantity={product.quantity}
                                                            id={product.cart_id}
                                                            cartGet={cartGet}
                                                            removeFromCart={() => removeFromCart(product.cart_id, index)}
                                                        />
                                                        <div className="price-total">
                                                            <p className="red-color-price me-3">
                                                                ₹{product.selling_price * product.quantity}
                                                            </p>
                                                        </div>
                                                        <FontAwesomeIcon
                                                            icon={faTrashCan}
                                                            className="delete-icon"
                                                            onClick={() => removeFromCart(product?.cart_id, index)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))
                            ) : (
                                cart?.map((product, index) => (
                                    <Row className="cartdesign mt-2 mb-4" key={product.id}>
                                        <Col xxl={11}>
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6}>
                                                    <div className="product-data">
                                                        <div className="cart-image">
                                                            <img
                                                                src={IMG_URL + product.image}
                                                                className="pro-imggggg"
                                                                alt={product.name}
                                                            />
                                                        </div>
                                                        <div className="text">
                                                            <p className="sub-text">{product.name}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={6} xl={6} lg={6} md={6}>
                                                    <div className="product">
                                                        <div className="ms-2">
                                                            <p className="sub-text">Price</p>
                                                            <span className="text">₹{product.price}</span>
                                                        </div>
                                                        <IncreamentDecreament
                                                            quantity={product.quantity}
                                                            id={product.product_id}
                                                            cartGet={cartGet}
                                                            removeFromCart={() => removeFromCart(product.id, index)}
                                                        />
                                                        <div className="price-total">
                                                            <p className="red-color-price me-3">
                                                                ₹{product.price * product.quantity}
                                                            </p>
                                                        </div>
                                                        <FontAwesomeIcon
                                                            icon={faTrashCan}
                                                            className="delete-icon"
                                                            onClick={() => removeFromCart(product.id, index)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))
                            )}
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={12}>
                            <OrderSummary  cartsignin={cartsignin} cart={cart} totalPrice={totalPrice} />
                        </Col>
                    </Row>
                </section>
            )}
        </Container>
    );
};

export default OrderData;