import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Blogs from "../Blogs/Blogs";
import AllBrands from "../AllBrands/AllBrands";
// import AllSellers from "../AllSellers/AllSellers";
// import SellerInner from "../AllSellers/SellerInner/SellerInner";
import Coupons from "../Coupons/Coupons";
import InnerCoupons from "../Coupons/InnerCoupons/InnerCoupons";
import InnerBlogs from "../Blogs/InnerBlogs/InnerBlogs";
import AddToCard from "../CommonComponent/AddToCard/AddToCard";
import Login from "../LoginPages/Login/Login";
import Register from "../LoginPages/Register/Register";
import ForgotPassword from "../LoginPages/ForgotPassword/ForgotPassword";
import AllCategories from "../AllCategories/AllCategories";
import TodaysDeal from "../TodaysDeal/TodaysDeal";
// import IncreamentDecreament from "../CommonComponent/IncreamentDecreament/IncreamentDecreament"
import MainFlashSale from "../MainFlashSale/MainFlashSale";
import FlashSalesMain from "../CommonComponent/FlashSales/FlashSalesMain";
import ProductDetails from "../ProductDetails/ProductDetails";
import PrivacyPolicy from "../Policys/PrivacyPolicy/PrivacyPolicy";
import SupportPolicy from "../Policys/SupportPolicy";
import ReturnPolicy from "../Policys/ReturnPolicy";
// import AfflatePartner from "../AfflatePartener/AfflatePartner";
import TrackOrder from "../TrackOrder/TrackOrder";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import WishlistMain from "../WishlistMain/WishlistMain";
import AboutUs from "../AboutUs/AboutUs";
import LatestViews from "../CommonComponent/LatestViews/LatestViews";
import Compare from "../Compare/Compare";
import ClassifiedProduct from "../WishlistMain/ClassifiedProduct/ClassifiedProduct";
import DashBoardTab from "../WishlistMain/DashBoardTab/DashBoardTab";
import ManageProfile from "../WishlistMain/ManageProfile/ManageProfile";
import MyWallet from "../WishlistMain/MyWallet/MyWallet";
import PurchaseHistory from "../WishlistMain/PurchaseHistory/PurchaseHistory";
import RefundRequest from "../WishlistMain/RefundRequest/RefundRequest";
// import OrderSummary from "../CommonComponent/OrderSummmary/OrderSummary";
import CategoryFilter from "../CategoryFIlter/CategoryFilter";
import OrderSuccessful from "../OrderSuccessful/OrderSuccessful";
import AllNotifications from "../AllNotifications/AllNotifications";
import OrderData from "../OrderDetails/OrderData/OrderData";
import ShoppingInfo from "../OrderDetails/ShoppingInfo/ShoppingInfo";
import ProductSale from "../ProductSale/ProductSale";
import WishlistTab from "../WishlistMain/WishlistTab/WishlistTab";
import TermsCondition from "../Policys/TermsCondition";
import SearchResults from "../SearchResults/SearchResults";
import ContactUs from "../ContactUs/ContactUs";

const EcommerceRoutesMain = () => {
    return (
        <>
            <Routes>
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/innerblogs/:id" element={<InnerBlogs />} />
                <Route path="/allbrands" element={<AllBrands />} />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/innercoupons" element={<InnerCoupons />} />
                <Route path="/add-card" element={<AddToCard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot" element={<ForgotPassword />} />
                <Route path="/all-categories" element={<AllCategories />} />
                <Route path="/todays-deal" element={<TodaysDeal />} />
                <Route path="/flash-sale-main" element={<FlashSalesMain />} />
                <Route path="/flash-sale" element={<MainFlashSale />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/trackorder" element={<TrackOrder />} />
                <Route path="/product-details/:id" element={<ProductDetails />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/support-policy" element={<SupportPolicy />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path="/terms&conditions" element={<TermsCondition />} />
                <Route path="/wishlist" element={<WishlistMain />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/views" element={<LatestViews />} />
                <Route path="/compare" element={<Compare />} />
                <Route path="/order-data" element={<OrderData />} />
                <Route path="/shop-info" element={<ShoppingInfo />} />
                <Route path="/order-success/:id" element={<OrderSuccessful />} />
                <Route path="/all-notifications" element={<AllNotifications />} />
                <Route path="/product-sale/:id" element={<ProductSale />} />
                <Route path="/wishlist-main" element={<WishlistTab />} />
                <Route path="/contactus" element={<ContactUs />} />
                {/* Dashboard routes */}

                <Route path="/classified-product" element={<ClassifiedProduct />} />
                <Route path="/dashboard" element={<DashBoardTab />} />
                <Route path="/manage-profile" element={<ManageProfile />} />
                <Route path="/my-wallet" element={<MyWallet />} />
                <Route path="/purchase-history" element={<PurchaseHistory />} />
                <Route path="/refund-request" element={<RefundRequest />} />
                <Route path="/categoryfilter/:id" element={<CategoryFilter />} />
                <Route path="/searchresults/:name" element={<SearchResults />} />

            </Routes >
        </>
    );
};

export default EcommerceRoutesMain;
