import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ButtonCommon from "../../CommonComponents/ButtonCommon/ButtonCommon";
import { faCodeCompare, faHeart, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import IncreamentDecreament from '../../IncreamentDecreament/IncreamentDecreament';
import "./Addtocartmodal.css";
import Cartsuccessfully from '../Cartsuccessfully/Cartsuccessfully';

const Default = {
    initialZoom: 3,
    minZoom: 1.25,
    maxZoom: 4,
    zoomSpeed: 0.01,
};


class Zoomable {
    constructor(element, config) {
        this.element = element;
        this.config = this._mergeConfig(config);

        const { initialZoom, minZoom, maxZoom } = this.config;

        this.zoomed = false;
        this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
        this.zoom = this.initialZoom;

        this.img = element.querySelector(".zoomable__img");
        this.img.draggable = false;
        this.element.style.setProperty("--zoom", this.initialZoom);

        this._addEventListeners();
    }

    static get Default() {
        return Default;
    }

    _addEventListeners() {
        this.element.addEventListener("mouseenter", () => this._handleMouseover());
        this.element.addEventListener("mousemove", (evt) => this._handleMousemove(evt));
        this.element.addEventListener("mouseleave", () => this._handleMouseout());
        this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
    }

    _handleMouseover() {
        if (this.zoomed) {
            return;
        }

        this.element.classList.add("zoomable--zoomed");
        this.zoomed = true;
    }

    _handleMousemove(evt) {
        if (!this.zoomed) {
            return;
        }

        const elPos = this.element.getBoundingClientRect();
        const percentageX = `${((evt.clientX - elPos.left) * 100) / elPos.width}%`;
        const percentageY = `${((evt.clientY - elPos.top) * 100) / elPos.height}%`;

        this.element.style.setProperty("--zoom-pos-x", percentageX);
        this.element.style.setProperty("--zoom-pos-y", percentageY);
    }

    _handleMouseout() {
        if (!this.zoomed) {
            return;
        }

        this.element.style.setProperty("--zoom", this.initialZoom);
        this.element.classList.remove("zoomable--zoomed");
        this.zoomed = false;
    }

    _handleWheel(evt) {
        if (!this.zoomed) {
            return;
        }

        evt.preventDefault();

        const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
        const { minZoom, maxZoom } = this.config;

        this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
        this.element.style.setProperty("--zoom", this.zoom);
    }

    _mergeConfig(config) {
        return {
            ...this.constructor.Default,
            ...(typeof config === "object" ? config : {}),
        };
    }
}
const Addtocartmodal = (props) => {
    const [cartsuccess, setCartsuccess] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const colorsd = ["#000", "#33FF57", "#3357FF", "#F333FF"];

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const zoomableRef = useRef(null);

    useEffect(() => {
        const element = zoomableRef.current;
        if (!element) return;

        const zoomable = new Zoomable(element);

        return () => {
            // Clean up event listeners when component unmounts
            element.removeEventListener("mouseenter", zoomable._handleMouseover);
            element.removeEventListener("mousemove", zoomable._handleMousemove);
            element.removeEventListener("mouseleave", zoomable._handleMouseout);
            element.removeEventListener("wheel", zoomable._handleWheel);
        };
    }, []);

    const slidesData = [
        {
            image: "/assests/images/Home/Deal/d1.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/images/Home/Deal/d1.png"),
        },
        {
            image: "/assests/images/Home/Deal/d2.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png"),
        },
        {
            image: "/assests/images/Home/Deal/d3.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png"),
        },
        {
            image: "/assests/images/Home/Deal/d4.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png"),
        },
        {
            image: "/assests/images/Home/Deal/d5.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/images/Home/Deal/d5.png"),
        },
        {
            image: "/assests/images/Home/Deal/d6.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png"),
        },
    ];

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='ProductInnermodal'
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <section >
                        <Container>
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={12}>
                                    <div className="row camera-sec">
                                        <Zoom>
                                            <div className="card-sec ">
                                                <div className="product-detail text-center">
                                                    {selectedImage ? (
                                                        <div ref={zoomableRef}>
                                                            <img src={selectedImage} className="zoomable__img" alt="Selected" />
                                                        </div>
                                                    ) : (
                                                        <div className="specs" ref={zoomableRef}>
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL + "/assests/images/Home/Deal/d1.png"
                                                                }
                                                                className="zoomable__img"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Zoom>

                                        <div className="camera-slider p-3">
                                            <div className="row p-0 m-0">
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <Swiper
                                                        slidesPerView={"auto"}
                                                        modules={[Autoplay]}
                                                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                        breakpoints={{
                                                            0: {
                                                                slidesPerView: 3,
                                                                spaceBetween: 10,
                                                            },
                                                            370: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 10,
                                                            },
                                                            485: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 10,
                                                            },
                                                            575: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 30,
                                                            },

                                                            768: {
                                                                slidesPerView: 6,
                                                                spaceBetween: 20,
                                                            },
                                                            992: {
                                                                slidesPerView: 6,
                                                                spaceBetween: 30,
                                                            },
                                                            1024: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 30,
                                                            },
                                                            1200: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 30,
                                                            },
                                                            1440: {
                                                                slidesPerView: 3,
                                                                spaceBetween: 30,
                                                            },
                                                            2000: {
                                                                slidesPerView: 3,
                                                                spaceBetween: 20,
                                                            },
                                                        }}
                                                        navigation
                                                        pagination={{ clickable: true }}
                                                        className="mySwiper"
                                                    >
                                                        {slidesData.map((slide, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div className="Main-div">
                                                                    <div className="camera-imgs" onClick={slide.onClick}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + slide.image}
                                                                            className="inner-img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                    {/* <div className="silder-btn">
                                                <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                                  
                                                </div>
                                                <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                                    
                                                </div>
                                              
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12}>
                                    <div className="content-main">
                                        <div className="upper-content">
                                            <h1 className="midtext">
                                                Like Dreams Large Sherpa Tote Bag, Inner Pocket Vegan Leather, Large Tote Hand
                                                bags for Women
                                            </h1>


                                        </div>
                                        <div className="price-section">
                                            <Row className="mt-2">
                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className="sub-text">Price</p>
                                                </Col>
                                                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                                                    <p className="price">₹500</p>
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className="sub-text">Quantity</p>
                                                </Col>
                                                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                                                    <IncreamentDecreament />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className="sub-text">Total Price</p>
                                                </Col>
                                                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                                                    <p className="midtext">₹6000</p>
                                                </Col>
                                            </Row>

                                            <Row className="mt-">
                                                <div className="buttonsectionmain">
                                                    <Button type='button' className="addtocartbtn" onClick={() => {setCartsuccess(true); props.onHide()}}>Add to cart</Button>

                                                </div>
                                            </Row>


                                        </div>
                                    </div>


                                </Col>
                            </Row>
                        </Container>

                    </section>
                </Modal.Body>

            </Modal>

            <Cartsuccessfully show={cartsuccess}
                onHide={() => setCartsuccess(false)} />
        </>
    )
}

export default Addtocartmodal