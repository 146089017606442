import React from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
// import AppContext from "./utils/context";
import EcommerceRoutes from "../src/components/EcommerceRoutes/EcommerceRoutes";
import Home from "./components/home/Home";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Header from "./components/header/Header";
import SideBarIcons from "./components/CommonComponent/SideBarIcons/SideBarIcons";
import GreatDeals from "./components/CommonComponent/GraetDeals/GreatDeals";
import HomeFooter from "./components/CommonComponent/HomeFooter/HomeFooter";
import Footer from "./components/footer/Footer";
import LatestViews from "./components/CommonComponent/LatestViews/LatestViews";
import AppContext from "./utils/context"

function App() {
  const { pathname } = useLocation();

  const ecommerce2 = pathname.includes("/ecommerce2");
  const location = useLocation();
  const hideHeaderFooter = [
    "/ecommerce2/login",
    "/ecommerce2/register",
    "/ecommerce2/forgot",
  ].includes(location.pathname);
  return (
    <>

      <AppContext>
        <ScrollToTop />

        {!hideHeaderFooter && <Header />}
        {!hideHeaderFooter && <SideBarIcons />}
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>

        {ecommerce2 && <EcommerceRoutes />}
        {!hideHeaderFooter && <LatestViews />}
        {!hideHeaderFooter && <GreatDeals />}

        {!hideHeaderFooter && <HomeFooter />}
        {!hideHeaderFooter && <Footer />}

      </AppContext>



    </>
  );
}

export default App;
