import React, { useContext, useEffect, useState } from "react";
import "./Kidsbanner.css";
import {Container} from "react-bootstrap";
import {Context} from "../../../utils/context";
import { GetAllHomeBanner } from "../../../utils/apis/master/Master";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
const KidsBanner = () => {

    const [banners, setBanner] = useState([]);

    const {sellerId, IMG_URL} = useContext(Context);

    const getBanner = async () => {
        const res = await GetAllHomeBanner(sellerId);
        if (res?.success) {
            setBanner(res?.data);
        }
    };
    useEffect(() => {
        getBanner();
    }, []);
    return (
        <>
            <section className="Kids-Banner">
              
                <Container>
                <Swiper
                    className="mySwiper"
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {banners?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="banner">
                                <img src={IMG_URL + item?.image} className="kids" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </Container>
            </section>
        </>
    );
};

export default KidsBanner;
