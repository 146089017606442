import React, {useState} from "react";
import {Col, Container, Modal, Nav, Row, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faProductHunt} from "@fortawesome/free-brands-svg-icons";
import "./HomeFooter.css";
import {
    faBell,
    faCartShopping,
    faClipboard,
    faCodeCompare,
    faDollarSign,
    faDownload,
    faGrip,
    faHeart,
    faHouse,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import ButtonCommon from "../ButtonCommon/ButtonCommon";
import DashBoardTab from "../../WishlistMain/DashBoardTab/DashBoardTab";
import PurchaseHistory from "../../WishlistMain/PurchaseHistory/PurchaseHistory";
import RefundRequest from "../../WishlistMain/RefundRequest/RefundRequest";
import WishlistTab from "../../WishlistMain/WishlistTab/WishlistTab";
import MyWallet from "../../WishlistMain/MyWallet/MyWallet";
import ManageProfile from "../../WishlistMain/ManageProfile/ManageProfile";

const HomeFooter = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className="Home-Footer">
                <Container>
                    <div className="home-main">
                        <Row className="main">
                            <Col xxl={6} xl={6} lg={6} md={8} className="p-0">
                                <div className="all-categories">
                                    <Row className="align-items-center gutters-5">
                                        <Col>
                                            <Link to="/">
                                                <div className="home">
                                                    <FontAwesomeIcon icon={faHouse} className="footer-icon" />
                                                    <div className="text">
                                                        <p className="sub-text">Home</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link to="ecommerce2/all-categories">
                                                <div className="home">
                                                    <FontAwesomeIcon icon={faGrip} className="footer-icon" />
                                                    <div className="text">
                                                        <p className="sub-text">Categories</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col>
                                        <Link  to="ecommerce2/order-data">
                                            <div className="home">
                                                <FontAwesomeIcon icon={faCartShopping} className="footer-icon" />
                                                <div className="text">
                                                    <p className="sub-text">Cart</p>
                                                </div>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col>
                                        <Link to="ecommerce2/all-notifications">
                                            <div className="home">
                                                <FontAwesomeIcon icon={faBell} className="footer-icon" />
                                                <div className="text">
                                                    <p className="sub-text">Notification</p>
                                                </div>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <div className="home" onClick={handleShow}>
                                                <FontAwesomeIcon icon={faUser} className="footer-icon" />
                                                <div className="text">
                                                    <p className="sub-text">My account</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Offcanvas show={show} onHide={handleClose} className="Dashboard-mobile-view">
                        <Offcanvas.Header closeButton>
                            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="main-history">
                                <div className="profile">
                                    <div className="main-image">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/images/Wishlist/men.webp"}
                                            className="login-image"
                                        />
                                    </div>
                                    <p className="text">Paul K. Jensen</p>
                                    <p className="sub-text">208-295-8053</p>
                                </div>
                            </div>

                            <Nav variant="pills" className="flex-column">
                                <Link to="/ecommerce2/dashboard">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" onClick={handleClose} as={Link} to="/ecommerce2/dashboard">
                                            <div className="main-icon">
                                                <FontAwesomeIcon icon={faHouse} className="dash-icon" />
                                                <p className="sub-text">Dashboard</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Link>
                                <Link to="/ecommerce2/purchase-history">
                                <Nav.Item>
                                    <Nav.Link eventKey="second" onClick={handleClose}  as={Link} to="/ecommerce2/purchase-history">
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faClipboard} className="dash-icon" />
                                            <p className="sub-text">Purchase History</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                </Link>
                                <Link to="/ecommerce2/purchase-history">
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="third" onClick={handleClose} as={Link} to="/ecommerce2/purchase-history">
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faDownload} className="dash-icon" />
                                            <p className="sub-text">Downloads</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item> */}
                                </Link>
                                <Link to="/ecommerce2/refund-request/">
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth" onClick={handleClose} as={Link} to="/ecommerce2/refund-request/">
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faDollarSign} className="dash-icon" />
                                            <p className="sub-text">Refund Request</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                </Link>
                                {/* <Link to="/ecommerce2/wishlist">
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth" onClick={handleClose}  as={Link} to="/ecommerce2/wishlist">
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faHeart} className="dash-icon" />
                                            <p className="sub-text">Wishlist</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                
                                </Link> */}
                                <Link to="/ecommerce2/wishlist-main">
                                <Nav.Item>
                                            <Nav.Link eventKey="fifth">
                                                <div className="main-icon">
                                                    <FontAwesomeIcon icon={faHeart} className="dash-icon" />
                                                    <p className="sub-text">Wishlist</p>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        </Link>
                                <Link to="/ecommerce2/compare">
                                {/* <Nav.Link as={Link} to={"/ecommerce2/compare"} onClick={handleClose}>
                                    <div className="main-icon">
                                        <FontAwesomeIcon icon={faCodeCompare} className="dash-icon" />
                                        <p className="sub-text">Compare</p>
                                    </div>
                                </Nav.Link> */}
                                </Link>
                                <Link to="/ecommerce2/my-wallet">
                                <Nav.Item>
                                    <Nav.Link eventKey="six" onClick={handleClose}  as={Link} to={"/ecommerce2/my-wallet"}>
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faProductHunt} className="dash-icon" />
                                            <p className="sub-text">My Wallet</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                </Link>
                                <Link to="">
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="seven">
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faProductHunt} className="dash-icon" />
                                            <p className="sub-text">Earing Points</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item> */}
                                </Link>
                                <Nav.Item>
                                    <Nav.Link eventKey="eight">
                                        <div className="main-icon">
                                            <FontAwesomeIcon icon={faProductHunt} className="dash-icon" />
                                            <p className="sub-text">Manage Profile</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item onClick={handleShow}>
                                            <Nav.Link eventKey="nine">
                                                <div className="main-icon">
                                                    <FontAwesomeIcon icon={faProductHunt} className="dash-icon" />
                                                    <p className="sub-text">Delete My Account</p>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item> */}
                            </Nav>
                            <div className="sign-out">
                                <ButtonCommon className="sign" text="Sign Out" />
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Container>
            </section>
        </>
    );
};

export default HomeFooter;
