import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./TodayDeals.css";
import AddToCard from "../CommonComponent/AddToCard/AddToCard";
import { Context } from "../../utils/context";
import { getDealToday } from "../../utils/apis/Wishlist/Wishlist";
const TodaysDeal = () => {

    const { sellerId, IMG_URL } = useContext(Context);

    const [dealToday, setDealToday] = useState([]);

    const getTodays = async () => {
        const res = await getDealToday(sellerId);
        if (res?.success) {
            setDealToday(res?.data);
        }
    }

    useEffect(() => {
        getTodays();
    }, []);


    return (
        <>
            <section className="TodaysDeal">
                <Container>
                    <div className="deal-image">
                        <img
                            src={process.env.PUBLIC_URL + "/assests/images/Home/Deal/DealBanner.png"}
                            className="deal"
                        />
                    </div>
                    <div className="main-deal">
                        <Row className="row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-2 gutters-16 border-top border-left">
                            {dealToday.map((item, index) => (
                                <Col key={index} className="p-0">
                                    <AddToCard
                                        image={IMG_URL + item?.thumbnail}
                                        title={item?.product_title}
                                        price1={item?.selling_price}
                                        price2={item?.mrp}
                                        productId={item?.id}

                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>

                </Container>
            </section>
        </>
    );
};

export default TodaysDeal;
