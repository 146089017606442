import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./Login.css";
const Login = (props) => {
    return (
        <>
            <section className="Login">
                <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="Login">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4></h4>
                        <p className="log">LogOut SuccessFully!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="Login">
                            <Button onClick={props.handleLogout} className="logout">
                                LogOut
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </section>
        </>
    );
};

export default Login;
