import React from "react";
import { Routes, Route } from "react-router-dom";
import EcommerceRoutesMain from "../EcommerceRoutesMain/EcommerceRoutesMain";
const EcommerceRoutes = () => {
  return (
    <>
      <Routes>
      
        <Route path={`/ecommerce2/*`} element={<EcommerceRoutesMain />} />
      </Routes>
    </>
  );
};

export default EcommerceRoutes;
