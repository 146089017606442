import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Autoplay } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddToCard from "../../CommonComponent/AddToCard/AddToCard";
import './FeaturedProducts.css'
import { Context } from "../../../utils/context";
import { PopularProductdetails } from "../../../utils/apis/Product/Product";
const FeaturedProducts = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const FeaturedProducts = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag,",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d5.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d6.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d7.png",
            title: "Like Dreams Large Sherpa Tote Bag,Sherpa Tote Bag, ",
            price1: "₹3450.00",
            price2: "₹5000.0",
        },



    ];


    const { sellerId, IMG_URL } = useContext(Context);

    const [popularProducts, setPopularProduct] = useState([]);

    const getPopular = async () => {
        const res = await PopularProductdetails(sellerId);
        if (res?.success) {
            setPopularProduct(res?.data);
        }
    }

    useEffect(() => {
        getPopular();
    }, []);
    return (
        <>
            <section className="FeaturedProducts">
                <Container>
                    <div className="main">
                        <h1 className="title">Popular Products</h1>
                        <div className="silder-btn">
                            <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                            </div>
                            <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                            </div>
                        </div>
                    </div>
                    <Swiper
                        className="mySwiper"
                        pagination={true}
                        modules={[Autoplay]}
                        // autoplay={{
                        //     delay: 2000,
                        //     disableOnInteraction: false,
                        // }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,

                            },
                            380: {
                                slidesPerView: 2,

                            },
                            485: {
                                slidesPerView: 2,

                            },
                            575: {
                                slidesPerView: 2,

                            },

                            768: {
                                slidesPerView: 3,

                            },
                            992: {
                                slidesPerView: 3,

                            },
                            1024: {
                                slidesPerView: 4,

                            },
                            1200: {
                                slidesPerView: 4,

                            },
                            1440: {
                                slidesPerView: 5,

                            },
                            2000: {
                                slidesPerView: 5,

                            },
                        }}
                    >
                        {popularProducts.map((item, index) => (
                            <SwiperSlide key={index}>
                                <AddToCard
                                    image={IMG_URL + item?.thumbnail}
                                    productId={item?.id}
                                    title={item?.product_title}
                                    price1={item?.mrp}
                                    price2={item?.selling_price}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </Container>
            </section>



        </>
    );
};

export default FeaturedProducts;
