import React, {useState} from "react";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import {Container} from "react-bootstrap";
import './PrivacyPolicy/PrivacyPolicy.css'
const SupportPolicy = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "support policy", link: "#"},
        ,
    ]);
    return (
        <>
         <section className="PrivacyPolicy">
            <Container>
                <div className="title-main">
                    <h1 className="title">Support Policy Page</h1>
                    <div>
                        <Breadcrumbs items={breadcrumbItems} />
                    </div>
                </div>
                <h1>Sample Support Policy Template</h1>
                <p className="text mt-5">Support Policy</p>
                <p className="text mt-5">Our Return and Refund Policy was last updated [DATE]</p>
                <p className="text mt-5">Thank you for shopping at [WEBSITE_NAME].</p>
                <p className="text mt-5">If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy was generated by TermsFeed Return and Refund Policy Generator.</p>
                <p className="text mt-5">The following terms are applicable for any products that You purchased with Us.</p>
                <p className="text mt-5">Interpretation and Definitions</p>
                <p className="text mt-5">Interpretation</p>
                <p className="text mt-5">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.Definitions</p>

                <h1 className="title">For the purposes of this Return and Refund Policy:</h1>
                <p className="text mt-5">Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to [COMPANY_INFORMATION]</p>
                <p className="text mt-5">Goods refers to the items offered for sale on the Service.</p>
                <p className="text mt-5">Orders means a request by You to purchase Goods from Us.</p>
                <p className="text mt-5">“Service refers to the Website.</p>
                <p className="text mt-5">Website refers to [WEBSITE_NAME], accessible from [WEBSITE_URL]</p>
                <p className="text mt-5">You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                 Your Order Cancellation Rights</p>
                 <p className="text mt-5">You are entitled to cancel Your Order within 14 days without giving any reason for doing so.</p>
                 <p className="text mt-5">The deadline for cancelling an Order is 14 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.

</p>
            </Container></section>
        </>
    );
};

export default SupportPolicy;
