import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { GetAppSetup, GetSocialLink } from "../../utils/apis/master/Master";

const Footer = () => {
    const { cartCount, signin, setSignin, sellerId, IMG_URL } = useContext(Context);

    const [appSetup, setAppSetup] = useState();
    const getAppSetup = async () => {
        const res = await GetAppSetup(sellerId);
        if (res?.success) {
            setAppSetup(res?.data);
        }
    }

    const [sociallink, setSocialLink] = useState();
    const getSocialLink = async () => {
        const res = await GetSocialLink(sellerId);
        if (res?.success) {
            setSocialLink(res?.data);
        }
    }

    useEffect(() => {
        getAppSetup();
        getSocialLink();
    }, []);
    return (
        <>
            <div className="Footer-Main-Section" style={{
                background: appSetup?.footer_color
            }} >
                <section className="Footer" style={{
                    background: appSetup?.footer_color
                }}>
                    <Container>
                        <div className="Content-Started">
                            <div className="logo">
                                {/* <img
                                    src={process.env.PUBLIC_URL + "/assests/images/logo/logop.png"}
                                    className="store"
                                /> */}
                                <img
                                    src={
                                        IMG_URL +
                                        appSetup?.image
                                    }
                                    // className="store"
                                    alt="..."
                                />
                            </div>
                            <Row>
                                <Col xxl={8} xl={8} lg={8}>
                                    <p className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Complete system for your eCommerce business</p>
                                    <p className="text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>
                                        Subscribe to our newsletter for regular updates about Offers, Coupons & more
                                    </p>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} sm={8} xs={8}>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="email" placeholder="Your Email Address" />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} sm={4} xs={4}>
                                            <div className="subscribe">
                                                <Button variant="primary">Subscribe</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <div class="col d-none d-lg-block"></div>
                                <Col xxl={3} xl={3} lg={3}>
                                    <div className="social-icon">
                                        <h1 className="text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>FOLLOW US</h1>
                                        <div className="icon-main">
                                            {sociallink?.map((icon, index) => (
                                                <div className="facebook">
                                                    <Link to={icon?.link}>
                                                        {/* <FontAwesomeIcon icon={faFacebook} className="icon" /> */}
                                                        <img className="icon" src={IMG_URL + icon?.image} alt={icon?.name} />
                                                    </Link>
                                                </div>
                                            ))}
                                            {/* <div className="twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="icon" />
                                            </div>
                                            <div className="instagram">
                                                <FontAwesomeIcon icon={faInstagram} className="icon" />
                                            </div>
                                            <div className="youtube">
                                                <FontAwesomeIcon icon={faYoutube} className="icon" />
                                            </div>
                                            <div className="link">
                                                <FontAwesomeIcon icon={faLinkedin} className="icon" />
                                            </div> */}
                                        </div>
                                        <div className="store-main">
                                            <h1 className="text" style={{
                                                color: appSetup?.footer_font_color
                                            }}>MOBILE APPS</h1>
                                            <div className="store">
                                                <div className="app-store">
                                                    <div className="store-image">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/assests/images/Footer/google.png"
                                                            }
                                                            className="store"
                                                        />
                                                    </div>
                                                    <div className="store-image">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assests/images/Footer/app.png"}
                                                            className="store"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <section className="Quick-Links" style={{
                    background: appSetup?.footer_color
                }}>
                    <Container>
                        <Row>
                            <Col xxl={4} xl={4} lg={4}>
                                <h1 className="text" style={{
                                    color: appSetup?.footer_font_color
                                }}>QUICK LINKS</h1>
                                <ul>
                                    <Link to={"/ecommerce2/supportpage"} >
                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>Support Policy Page</li>
                                    </Link>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Return Policy Page</li>
                                    <Link to="/ecommerce2/about">
                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>About Us</li>
                                    </Link>
                                    <Link to="/ecommerce2/privacy-policy">
                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>Privacy Policy Page</li>
                                    </Link>
                                    {/* <li className="sub-text">Seller Policy</li> */}
                                    <Link to="/ecommerce2/privacy-policy">
                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>Term Conditions Page</li>
                                    </Link>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={4} lg={4}>
                                <h1 className="text" style={{
                                    color: appSetup?.footer_font_color
                                }}>CONTACTS</h1>
                                <ul>
                                    <li className="sub-text add" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Address</li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Demo Address</li>
                                    <li className="sub-text add" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Phone</li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>123456789</li>
                                    <li className="sub-text add" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Email</li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>demo.example@gmail.com</li>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={4} lg={4}>
                                <h1 className="text" style={{
                                    color: appSetup?.footer_font_color
                                }}>MY ACCOUNT</h1>
                                <ul>

                                    {signin ? (

                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}> LogOut
                                        </li>


                                    ) : (
                                        <Link to={"/ecommerce2/login"}>
                                            <li className="sub-text" style={{
                                                color: appSetup?.footer_font_color
                                            }}>LogIn
                                            </li>
                                        </Link>

                                    )}





                                    <Link to="/ecommerce2/wishlist" eventKey="second">
                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>Order History </li>
                                    </Link>
                                    <Link to="/ecommerce2/wishlist" eventKey="second">
                                        <li className="sub-text" style={{
                                            color: appSetup?.footer_font_color
                                        }}>My Wishlist</li>
                                    </Link>

                                    <Link to="/ecommerce2/trackorder"> <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Track Order</li></Link>

                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Be an affiliate partner</li>
                                </ul>
                            </Col>
                            {/* <Col xxl={3} xl={3} lg={3}>
                                <h1 className="text" style={{
                                    color: appSetup?.footer_font_color
                                }}>SELLER ZONE</h1>
                                <ul>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Become A Seller </li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Login to Seller Panel</li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>My Wishlist</li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Download Seller App</li>
                                </ul>
                                <h1 className="text" style={{
                                    color: appSetup?.footer_font_color
                                }}>DELIVERY BOY</h1>
                                <ul>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Login to Delivery Boy Panel</li>
                                    <li className="sub-text" style={{
                                        color: appSetup?.footer_font_color
                                    }}>Download Delivery Boy App</li>
                                </ul>
                            </Col> */}
                        </Row>
                    </Container>
                </section>
                {/* mobile view accordian started */}
                <section className="Mobile-View" >
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="container">QUICK LINKS</Accordion.Header>
                            <Accordion.Body className="container">
                                <ul>
                                    <li className="sub-text">Support Policy Page</li>
                                    <li className="sub-text">Return Policy Page</li>
                                    <li className="sub-text">About Us</li>
                                    <li className="sub-text">Privacy Policy Page</li>
                                    <li className="sub-text">Seller Policy</li>
                                    <li className="sub-text">Term Conditions Page</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="container">CONTACTS</Accordion.Header>
                            <Accordion.Body className="container">
                                <ul>
                                    <li className="sub-text add">Address</li>
                                    <li className="sub-text">Demo Address</li>
                                    <li className="sub-text add">Phone</li>
                                    <li className="sub-text">123456789</li>
                                    <li className="sub-text add">Email</li>
                                    <li className="sub-text">demo.example@gmail.com</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="container">MY ACCOUNT</Accordion.Header>
                            <Accordion.Body className="container">
                                <ul>
                                    <li className="sub-text">Login</li>
                                    <li className="sub-text">Order History </li>
                                    <li className="sub-text">My Wishlist</li>
                                    <li className="sub-text">Track Order</li>
                                    <li className="sub-text">Be an affiliate partner</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="3">
                            <Accordion.Header className="container">SELLER ZONE</Accordion.Header>
                            <Accordion.Body className="container">
                                <ul>
                                    <li className="sub-text">Become A Seller </li>
                                    <li className="sub-text">Login to Seller Panel</li>
                                    <li className="sub-text">My Wishlist</li>
                                    <li className="sub-text">Download Seller App</li>
                                </ul>
                                <h1 className="text">DELIVERY BOY</h1>
                                <ul>
                                    <li className="sub-text">Login to Delivery Boy Panel</li>
                                    <li className="sub-text">Download Delivery Boy App</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item> */}
                    </Accordion>
                </section>
                {/* mobile view accordian end */}
                <section className="payment" style={{
                    background: appSetup?.footer_color
                }}>
                    <Container>
                        <div className="cash">
                            <Row>
                                <Col xxl={6}>
                                    <h1 className="text">Active eCommerce CMS 2023</h1>
                                </Col>
                                <Col xxl={6}>
                                    <div className="main">
                                        <div className="image-pays">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assests/images/Footer/payment.webp"}
                                                className="store"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>

            </div >


        </>
    );
};

export default Footer;
