import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AddToCard from "../CommonComponent/AddToCard/AddToCard";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./ProductSale.css";
import { GetSingleFlashSale } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";

const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;
const daySeconds = hourSeconds * 24;

const ProductSale = () => {
    const { id } = useParams();
    const { sellerId, IMG_URL } = useContext(Context);

    const timerProps = {
        isPlaying: true,
        size: 90,
        strokeWidth: 6,
    };

    const renderTime = (time) => (
        <div className="time-wrapper">
            <div className="title time">{time}</div>
        </div>
    );

    const getTimeSeconds = (time) => Math.ceil(time % minuteSeconds);
    const getTimeMinutes = (time) => Math.floor((time % hourSeconds) / minuteSeconds);
    const getTimeHours = (time) => Math.floor((time % daySeconds) / hourSeconds);
    const getTimeDays = (time) => Math.floor(time / daySeconds);

    const [singleflash, setSingleFlash] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [daysDuration, setDaysDuration] = useState(0);

    const getSingleFlash = async () => {
        const res = await GetSingleFlashSale(sellerId, id);
        if (res?.success) {
            setSingleFlash(res.data);
            const currentTime = Date.now() / 1000;
            const startTime = new Date(res.data.flash_sale.start_time).getTime() / 1000;
            const endTime = new Date(res.data.flash_sale.end_time).getTime() / 1000;
            const remaining = endTime - currentTime;
            setRemainingTime(remaining);
            setDaysDuration(Math.ceil(remaining / daySeconds) * daySeconds);
        }
    };

    useEffect(() => {
        getSingleFlash();
    }, []);

    return (
        <>
            <section className="ProductSale">
                <Container>
                    <Row>
                        <Col xxl={4} xl={4} lg={4} md={12}>
                            {singleflash && (
                                <div className="Sale-Banner">
                                    <div className="Flash-sale">
                                        <img src={IMG_URL + singleflash.flash_sale.image} className="sale" />
                                    </div>
                                    <div className="Sale-Timer">
                                        <div className="all-times-bar">
                                            <div className="timebox">
                                                <div className="day  main-day mr-12">
                                                    <CountdownCircleTimer
                                                        {...timerProps}
                                                        colors={[["#d42d2a"], ["#0066FF"]]}
                                                        isLinearGradient={true}
                                                        duration={daysDuration}
                                                        initialRemainingTime={remainingTime}
                                                        trailColor="#e7e5e5"
                                                    >
                                                        {({ elapsedTime }) => renderTime(getTimeDays(daysDuration - elapsedTime))}
                                                    </CountdownCircleTimer>
                                                    <h3 className="title">Days</h3>
                                                </div>
                                            </div>
                                            <div className="timebox">
                                                <div className="hours  main-day mr-12">
                                                    <CountdownCircleTimer
                                                        {...timerProps}
                                                        colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                        isLinearGradient={true}
                                                        duration={daySeconds}
                                                        initialRemainingTime={remainingTime % daySeconds}
                                                        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > hourSeconds]}
                                                        trailColor="#e7e5e5"
                                                    >
                                                        {({ elapsedTime }) => renderTime(getTimeHours(daySeconds - elapsedTime))}
                                                    </CountdownCircleTimer>
                                                    <h3 className="title">HRS</h3>
                                                </div>
                                            </div>
                                            <div className="timebox">
                                                <div className="minutes  main-day mr-12">
                                                    <CountdownCircleTimer
                                                        {...timerProps}
                                                        colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                        isLinearGradient={true}
                                                        duration={hourSeconds}
                                                        initialRemainingTime={remainingTime % hourSeconds}
                                                        onComplete={(totalElapsedTime) => [
                                                            remainingTime - totalElapsedTime > minuteSeconds,
                                                        ]}
                                                        trailColor="#e7e5e5"
                                                    >
                                                        {({ elapsedTime }) => renderTime(getTimeMinutes(hourSeconds - elapsedTime))}
                                                    </CountdownCircleTimer>
                                                    <h3 className="title">MIN</h3>
                                                </div>
                                            </div>
                                            <div className="timebox">
                                                <div className="seconds  main-day mr-12">
                                                    <CountdownCircleTimer
                                                        {...timerProps}
                                                        colors={[["#d42d2a"], ["#0066FF", 1]]}
                                                        isLinearGradient={true}
                                                        duration={minuteSeconds}
                                                        initialRemainingTime={remainingTime % minuteSeconds}
                                                        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > 0]}
                                                        trailColor="#e7e5e5"
                                                    >
                                                        {({ elapsedTime }) => renderTime(getTimeSeconds(elapsedTime))}
                                                    </CountdownCircleTimer>
                                                    <h3 className="title">SEC</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12}>
                            <div className="sale-product">
                                <Row>
                                    {singleflash &&
                                        singleflash.product.map((item) => (
                                            <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} key={item.id} className="p-0">
                                                <Link to={`/ecommerce2/product-details/${item?.id}`} >
                                                    <AddToCard
                                                        image={IMG_URL + item.thumbnail}
                                                        title={item.product_title}
                                                        price1={item.mrp}
                                                        price2={item.selling_price}
                                                        productId={item?.id}
                                                    />
                                                </Link>
                                            </Col>
                                        ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductSale;

